
/*
* ぽちボタンクリック後の処理
*/ 

class afrContactInfo {
  constructor(that) {
    this.that = that
    this.formName = "連絡先情報"
    this.that.$('.search_form_wrap').show()
  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func
    const progressYesNo = $('input[name="progress_yesNo"]:checked').val() ? $('input[name="progress_yesNo"]:checked').val() : ""

    // 値取得：連絡先情報
      store.commit('setValue', ['contactInfo', 'inputContactComName', func.textEscape($('#contactComName').val()), 'contact_com_name'])
      store.commit('setValue', ['contactInfo', 'inputContactCEOName', $("#contactCEOName").val(), 'contact_ceo_name'])
      store.commit('setValue', ['contactInfo', 'inputContactName', func.textEscape($('#contactName').val()), 'contact_name'])
      store.commit('setValue', ['contactInfo', 'inputContactMail', func.textEscape($('#contactMail').val()), 'contact_mail'])
      store.commit('setValue', ['contactInfo', 'inputContactTelNum', func.textEscape($('#contactTelNum').val()), 'contact_tel_num'])
      store.commit('setValue', ['contactInfo', 'inputCanOnlineMtg', $('[name="canOnlineMtg_yesNo"]:checked').val(), ''])
      store.commit('setValue', ['contactInfo', 'inputContactProgressYesNo', progressYesNo, 'progress_yesNo'])

  }

  setVal (balanceInfoMsg) {
    const store = this.that.store
    const ProgressYesNoAddmessage = store.state.formData.contactInfo.inputContactProgressYesNo == "可能" ? "(進行スタート依頼の投稿は不要)" : ""
      const sendVal = 
`

◎連絡先情報
社名：${store.state.formData.contactInfo.inputContactComName}
代表取締役者名：${store.state.formData.contactInfo.inputContactCEOName}
担当者名：${store.state.formData.contactInfo.inputContactName}
メールアドレス：${store.state.formData.contactInfo.inputContactMail}
電話番号：${store.state.formData.contactInfo.inputContactTelNum}
WEB打ち合わせ可否：${store.state.formData.contactInfo.inputCanOnlineMtg}
進行可否：${store.state.formData.contactInfo.inputContactProgressYesNo}
${ProgressYesNoAddmessage}
`

      store.commit('setTalknoteMessage', ['contactInfo', sendVal])
  }
}

export default {
  afrContactInfo 
}