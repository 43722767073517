import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "p-complete" }
const _hoisted_2 = ["textContent"]
const _hoisted_3 = ["textContent"]
const _hoisted_4 = { class: "p-complete-link" }
const _hoisted_5 = {
  key: 0,
  class: "p-complete-link__item"
}
const _hoisted_6 = ["href"]
const _hoisted_7 = ["textContent"]
const _hoisted_8 = {
  key: 1,
  class: "p-complete-link__item"
}
const _hoisted_9 = ["href"]
const _hoisted_10 = {
  key: 2,
  class: "p-complete-link__item"
}
const _hoisted_11 = ["href"]
const _hoisted_12 = ["textContent"]
const _hoisted_13 = {
  key: 3,
  class: "p-complete-link__item"
}
const _hoisted_14 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      textContent: _toDisplayString(_ctx.completeTitle)
    }, null, 8, _hoisted_2),
    (!_ctx.message)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "p-complete-txt",
          textContent: _toDisplayString(_ctx.completeDesc)
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_4, [
      (_ctx.groupURL)
        ? (_openBlock(), _createElementBlock("li", _hoisted_5, [
            _createElementVNode("a", {
              href: `${_ctx.groupURL}`,
              target: "_blank"
            }, [
              _createElementVNode("span", {
                class: "p-complete-link--ttl",
                textContent: _toDisplayString(_ctx.groupTitle)
              }, null, 8, _hoisted_7)
            ], 8, _hoisted_6)
          ]))
        : _createCommentVNode("", true),
      (_ctx.searchURL)
        ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
            _createElementVNode("a", {
              href: `${_ctx.searchURL}`,
              target: "_blank"
            }, _cache[0] || (_cache[0] = [
              _createElementVNode("span", { class: "p-complete-link--url" }, "slack投稿URL", -1)
            ]), 8, _hoisted_9)
          ]))
        : _createCommentVNode("", true),
      (_ctx.calURL)
        ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
            _createElementVNode("a", {
              href: `${_ctx.calURL}`,
              target: "_blank"
            }, [
              _createElementVNode("span", {
                class: "p-complete-link--url",
                textContent: _toDisplayString(_ctx.calendarMessage)
              }, null, 8, _hoisted_12)
            ], 8, _hoisted_11)
          ]))
        : _createCommentVNode("", true),
      (_ctx.message)
        ? (_openBlock(), _createElementBlock("li", _hoisted_13, [
            _createElementVNode("span", {
              class: "p-complete-link--text",
              textContent: _toDisplayString(_ctx.message)
            }, null, 8, _hoisted_14)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Button, { onClick: _ctx.viewGroupList }, {
      default: _withCtx(() => _cache[1] || (_cache[1] = [
        _createTextVNode("チャンネルリストへ")
      ])),
      _: 1
    }, 8, ["onClick"])
  ]))
}