class reScheduleCancel  {
  constructor(vueObj, $, func, moment) {

    try {
      // Vueインスタンスを取得
      this.vueObj = vueObj
      
      this.store = vueObj.store

      this.isProd = this.vueObj.isProd

      // jQueryインスタンスを取得
      this.$ = $

      // フォーム関連関数を取得
      this.func = func

      // momentインスタンスを取得
      this.moment = moment

      // propsを取得
      //this.props = this.vueObj.getProps()
      this.thisFormParams = vueObj.thisFormParams

      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]

      // 外部営業のslackチャンネルID
      if (this.store.state.userData.office_id == 7) {
        this.slackChannel.id = this.store.state.apis.outsideSalesRescanSlackChannelID
      }

      // 案件ID検索とか理由カテゴリ取得するAPI
      this.getDataApiURL = this.store.state.apis.getDataApiURL

      // talknote投稿用RL
      //this.talknotePOSTURL = this.store.state.apis.talknotePOSTURL

       // 業種カテゴリをCallinから取得するAPI
      this.jobsCategoryJSONDataURL = this.store.state.apis.jobsCategoryJSONDataURL   

      // DBを操作するAPI
      this.dbApiURL = this.store.state.apis.dbApiURL
      
      // slack チャンネル URL
      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id 

      // talknoteのグループURLからグループIDを取得
      //this.groupID = this.groupURL.split('feed/')[1].split('/')[0]

      // フォームタイトル
      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"")

      // talknoteの投稿先アドレス
      this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"")

      // カレンダー未反映通知グループアドレス
      // this.talknoteFailureApplyGroupAddress = this.store.state.apis.calendarFailureApplyURL

      // カレンダー未反映通知slackチャンネルID
      this.calendarFailureApplySlackChannelID = this.isProd? this.store.state.apis.calendarFailureApplySlackChannelID: this.store.state.apis.demoForwardChannelID

      // カレンダー未反映通知メールアドレス
      this.talknoteFailureApplyMailAddress = this.store.state.apis.calendarFailureApplyMail


      // 変数定義
      this.inputSubjectId = '',
      this.inputPostId = '',
      this.inputApType = '',
      this.inputApName = '',
      this.inputComName = '',
      this.inputCalName = '',
      this.inputMtgGDate = '',
      this.inputMtgGDay = '',
      this.inputMtgGStart = '',
      this.inputMtgGEnd = '',
      this.inputStatus ='',
      this.inputChangedPoints = '',
      this.inputChangeDate = '',
      this.inputChangeDay = '',
      this.inputChangeStart = '',
      this.inputChangeEnd = '',
      this.inputDuration = '',
      this.inputMtgAdd = '',
      this.inputMtgType = '',
      this.inputMtgAddReasons = '',
      this.inputReasons = '',
      this.inputRecordURL = '',
      this.inputRemarks = '',
      this.inputVisitArea = '',
      this.post_mtgAddd = '',
      this.resCalId = '',
      this.resEventId = '',
      this.resMtgType = '',
      this.inputDevice = '',
      this.resMtgAdd = '',
      this.resHook = '',
      this.resSuggestion = '',
      this.inputApTypeName = '',
      this.resApType = '',
      this.resVisitArea = '',
      this.resConfirmAp = '',
      this.del_flag = '',
      this.result_del = '',
      this.del_desc = '',
      this.del_cal_id = '',
      this.del_evnet_id = '',
      this.inputSaihou = "",
      this.formClearFlag = 0,
      this.not_yet = 0,
      this.nashi = 0,
      this.checked_color = "-active",
      this.inputStatusId = ''

      this.dataIDs = {
        subject_id: '',
        user_sg_id: '',
        status: '',
        changed_points: '',
        sub_cate_reasons: ''
      }

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$;

    $('input[name="q"]').css({ 
      'border': 'none',
      'width': '230px',
      'height': '20px',
      'font-size': '12px',
      'line-height': '26px',
      'padding-left': '5px',
      'color': '#444'
    })

    $('.autoG,.gotAP').css('display', 'none')
    

    this.getReasonCategory()

    this.actionInit()
  }

  actionInit() {
    const that = this
    const $ = this.$
    const func = this.func
    const moment = this.moment
    const officeData = new OfficeDatas()

    /****************************************
     * アクション定義
    *****************************************/
    /* 
      ▼案件IDフォーマット
      AP[グループID]_[投稿ミリ秒]
      TW[グループID]_[投稿ミリ秒]
      OT[グループID]_[投稿ミリ秒]
      SL[グループID][T or O]_[投稿ミリ秒]
    　  └ 投稿者の所属エリアにより、T(Tokyo)orO(Osaka)で決まる
    */

    // 案件ID検索
    $('#SubjectId').off('input.search').on('input.search', function(e) {
      let _this = $(this)
      
      const subject_id = _this.val()
      let textCount = 22

      if(subject_id.indexOf('_') == 13) {
        textCount = 26
      }

      let before = _this.data('subject_id')

      if(subject_id.indexOf('sungrove') == 0) {
        textCount = 11
      } else {
        $('#wrap2, .autoG, .gotAP').slideUp()
      }

      if(subject_id.length < textCount || (before && before != subject_id)|| e.originalEvent.inputType.indexOf("delete") == 0) {
        $('#status_comment').text('')
        _this.data('subject_id', '')
        before = ""

        if($('.autoG').css('display') == 'block') {
          $('.auto').prop('readonly', true);
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)
        }

        return false
      }

      const result = subject_id.match(/(^[A-Z]{2}[0-9A-Z]{11}?_[0-9]{13}$|^[A-Z]{2}[0-9]{6}[A-Z]?_[0-9]{13}$|^sungrove[123])/)
      
      if(!result) {
        if(subject_id.indexOf('sungrove') > -1) {
          _this.val('sungrove')
        } 

        //alert('案件IDを正しく入力してください。')

        //_this.val('')

        return false
      }

      // 入力されたら
      if($('#status_comment').text().indexOf('検索中') == -1) {
        $('.events').remove()

        if(subject_id.startsWith('sungrove') == false) {      

          const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/

          const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g

          $('#SubjectId').attr('readonly', true)

          if(reg1.test(subject_id)) {
            subject_id = subject_id.replace(reg2, "")
          }

          $('#btn').addClass('disabled').removeClass('pulse')
          $('.auto').val('')
          $('#status_comment').text('検索中...🔎 少々お待ちください😣').css('color', '#757575')
          $('#loading_form').css('display', 'block')

          // 入力した案件IDが既に商談済みかどうかチェック
          that.checkNegotiated(subject_id)

          // 案件ID検索APIに投げる
          console.log(new Date())

          // 案件ID検索APIに投げるGAS API URL
          //let searchApiURL = that.getDataApiURL + '?type=searchSubjectId&SubjectId=' + subject_id

          let searchApiURL = that.store.state.apis.searchSubjectsIDApiURL + subject_id

          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)

          that.vueObj.viewLoader('案件検索中')

          // 案件ID検索開始
          fetch(searchApiURL)
          .then(response => response.json())
          .then(response => {
            let responsePrevious = response.filter(item => item.description && item.description.indexOf("掘り起こし元ID：" + subject_id) !== -1)
            let newID = ""
            if (responsePrevious.length > 0) {
              newID = responsePrevious[0].description.split("案件ID：")[1].split("\n")[0]
              newID = "掘り起こし後のID候補：" + newID
              alert("掘り起こし済みIDです！ IDを確認してください！")
              response = []
            } else {
              response = response.filter(item => item.description && item.description.indexOf("案件ID：" + subject_id) !== -1)
            }
            console.log(response)

            $('#SubjectId').attr('readonly', false)
            $('#loading_form').css('display', 'none')
            $('#status_comment').text('')
            $('.form_wrap').slideDown()

            let result_CalID = func.checkCalID(response)

            if(result_CalID !== 99) {
              that.del_flag = 1
              that.result_del = response.splice(result_CalID, 1)
              that.del_cal_id = that.result_del[0].cal_id
              that.del_evnet_id = that.result_del[0].event_id
              that.del_desc = that.result_del[0].description
            }

            if(response.length == 1) {
              that.vueObj.viewLoader()
              $('select').css('display', 'block')
              $('.autoG,#wrap2').slideDown()
              $('#status_comment').text('対象の案件を見つけました👀')

              let resTitle = response[0].title
              let exchangeEle = {}
              let fullname = that.store.state.userData.displayName

              that.inputCalName = response[0].cal_name
              that.resCalId = response[0].cal_id
              that.resEventId = response[0].event_id
              that.resMtgAdd = response[0].location

              const regSaihou = /\[.*再.*\]/
              const errorText = func.titlePattern(resTitle, regSaihou)
              if (errorText) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }

              const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
              const isIncludeSelfSalesCal = officeData.checkIncludeSelfSalesCal(that.resCalId)
              const isRescanCal = officeData.checkRescanCal(that.resCalId)

              if(resTitle.indexOf('///*') !== -1 || resTitle.indexOf('*///') !== -1) {
                exchangeEle = func.v2Title(response[0], moment)
              } else if(isIncludeSaihouCal || (isRescanCal && regSaihou.test(resTitle))) { 
                exchangeEle = func.saihou(response[0], moment)
                that.inputSaihou = '(再訪)'
              } else if(resTitle.indexOf('///') !== -1) {
                exchangeEle = func.v1Title(response[0], moment)
              }
              
              if(isIncludeSelfSalesCal) { //自アポ
                $('.filed_proxy').css('display', 'none')
              }
              
              if(fullname.indexOf('(') !== -1 || fullname.indexOf('（') !== -1) {
                fullname = fullname.split(/\(|（/)[0]
              }
          
              if(exchangeEle.ap_type !== '自' && exchangeEle.ap_type !== '再訪') {
                if(fullname.indexOf(exchangeEle.ap_name) == -1) {
                  that.inputApName = func.delEmoji(exchangeEle.ap_name)
                }
              }

              const check_fixed = document.getElementById("fixed_flag")

              if(resTitle.indexOf('担当固定') !== -1 || resTitle.indexOf('/固]') !== -1) {
                check_fixed.checked = true
              }

              that.inputComName = exchangeEle.com_name
              that.inputApTypeName = exchangeEle.ap_type_name
              that.inputMtgGDate = exchangeEle.mtg_date
              that.resMtgType = exchangeEle.mtg_type
              that.resHook = exchangeEle.hook
              that.resSuggestion = exchangeEle.suggestion
              that.resApType = exchangeEle.ap_type
              that.resVisitArea = exchangeEle.visit_area
              that.resConfirmAp = exchangeEle.confirm_ap

              $('#ComName').val(that.inputComName)
              $('#ApType').val(that.inputApTypeName)
              $('#ApName').val(that.inputApName)
              $('#MtgDate').val(that.inputMtgGDate)
              $('#CalName').val(that.inputCalName)

              $('select#Status option').removeAttr('disabled')

              if(
                that.inputCalName.indexOf('リスキャン') !== -1 ||
                (
                  that.inputCalName.indexOf('リスキャン') == -1 &&
                  that.inputMtgGDate.split('）')[1].split('〜')[0] == '00:00'
                )
              ) {
                $('select#Status option[value=キャンセル], select#Status option[value=リスケ], select#Status option[value=前不], select#Status option[value=前確OK], select#Status option[value=前確不要]').remove().hide()
              } else if(that.resConfirmAp == '＊' && that.inputCalName.indexOf('リスキャン') !== -1) {
                $('select#Status option[value=キャンセル], select#Status option[value=前不], select#Status option[value=前確OK], select#Status option[value=前確不要]').remove().hide()
              } else if(that.resConfirmAp == '＊' && that.inputCalName.indexOf('リスキャン') == -1) { //前不案件 × リスキャンカレンダー以外
                $('select#Status option[value=前不]').remove().hide()
              } else {
                $('select#Status option[value=復活]').remove().hide()
              }

              if(that.resConfirmAp == '☆' || that.resConfirmAp == '◇') {
                $('select#Status option[value=前不], select#Status option[value=前確OK], select#Status option[value=前確不要]').remove().hide()
              } 

              $('select#MtgType option[data-type=' + that.resMtgType + ']').remove().hide()

            } else if(response.length >= 1) {
              $('#status_comment').text('複数のカレンダーが見つかりました。該当カレンダーのタイトルを選択してください。').css('color', '#29b6f6')
              that.vueObj.viewLoader()
              const errorText = func.createSubjectList(response)
              if (errorText != []) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }
            } else {
              $('.auto').attr('placeholder', '')
              $('#status_comment').html('もう一度IDを確認して検索してください。<br>' + newID).css('color', '#e91e63')
              that.vueObj.viewLoader()
            }
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })

          return true;
        } else if(subject_id.match(/^sungrove[0-9]{1,}$/)) {
          $('.auto').prop('readonly', false)
          $('#wrap2, .autoG, .gotAP, .form_wrap').slideDown()
          $('select').css('display', 'block')
          $('#status_comment').text('案件IDがない案件の為、手動入力モードに切り替えました📝').css('color', '#e91e63')
        }
      }
    })

    // [案件IDクリア]
    $('#SubjectId').off('input.clear').on('input.clear', function() {
      const subject_id = $('#SubjectId').val()

      if(subject_id == "" || that.formClearFlag == 1) {
        that.formClearFlag = 0
        $('#wrap2, .autoG, .gotAP, #filed_ap_name, .filed_changed, .filed_MtgDate, .filed_MtgType, .filed_VisitArea, .filed_Device, .filed_MtgAdd, .field_mtgAddReason, .form_wrap').slideUp(100) //連動項目の非表示・表示（ 代理投稿（担当AP）・変更点・商談日時・商談形式・来社エリア・使用デバイス・商談住所）

        $('.events').remove()
        $('#status_comment').text('案件IDを入力すると検索開始します').css('color', '#757575')

        func.clearInput($('input[type="text"], input[type="Number"], input[type="url"], #Remarks'))
        
        func.checkOut($('.form_check, .required'))
        
        func.clearSelect($('.form_select'))

        const allOpsStatus = [
          {value: 'リスケ', key: 1},
          {value: 'キャンセル', key: 2},
          {value: '前不', key: 3},
          {value: '復活', key: 4}
        ]
        const allOpsMtgType = {'オンライン':'オンライン', '来社':'来社', 'カフェ':'対面', '訪問':'対面'}

        func.addOption($('.select_status'), allOpsStatus)
        
        func.addDataOption($('[name="MtgType"]'), allOpsMtgType)
        
        $('#checkMtgAdd').prop({'disabled': false, 'checked': false})
        $('.slideUpEle').css('display', 'none')

        $('.chip').removeClass('-active -semi-active')
        $('input[id*="sonota_"]').hide()
        $('div[id*="neck_"]').addClass('up').hide()
        $('#Remark').val('')
        $('.required, .required + *').removeAttr('style')
      }
    })

    // カレンダー
    $('#ChangeDate').off('change.ChangeDate').on('change.ChangeDate', function() {
      let _this = $(this)
      let date = _this.val()
      let current = date && date.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)? date: moment().toDate()
/*
      _this.datepicker("dialog", current, function(date) {
        _this.val(date)
      }, {
        dateFormat : 'yy/mm/dd',
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      })*/
    })

    let ap_start_time = document.getElementById("ChangeStart")

    $(ap_start_time).timepicker({
      'timeFormat': 'H:i',
      'step': '15',
      'minTime': '8:00',
      'maxTime': '20:00'
    })

    ap_start_time.addEventListener("change", function() {
      let timepicker_start = document.getElementById("ChangeStart").value
      let hour = timepicker_start.slice(0, 2)
      let minitus = timepicker_start.slice(-2)

      if(hour.slice(0, 1) != '0') { //hourの１桁目に0が入っていない場合
        hour = Number(hour) + 1
      } else {//hourの１桁目に0が入っている場合
        let hour2 = hour.slice(-1)
        hour = Number(hour2) + 1
      }

      if (timepicker_start.indexOf(':') === -1) {
        timepicker_start = ""
        document.getElementById("ChangeStart").value = timepicker_start
      }

      timepicker_start = hour + ':' + minitus
      $('#ChangeEnd').timepicker({
        'timeFormat': 'H:i',
        'step': '15',
        'minTime': timepicker_start,
        'maxTime': '20:00'
      })
    })

    let ap_end_time = document.getElementById("ChangeEnd")

    ap_end_time.addEventListener("change", function() {
      let timepicker_end = document.getElementById("ChangeEnd").value
      let hour = timepicker_end.slice(0, 2)
      let minitus = timepicker_end.slice(-2)

      if(hour.slice(0, 1) != '0') { //hourの１桁目に0が入っていない場合
        hour = Number(hour) - 1
      } else {//hourの１桁目に0が入っている場合(例：09)今のところなさそうやけど一応
        let hour2 = hour.slice(-1)
        hour = Number(hour2) - 1
      }

      if (timepicker_end.indexOf(':') === -1) {
        timepicker_end = ""
        document.getElementById("ChangeEnd").value = timepicker_end
      }

      timepicker_end = hour + ':' + minitus
      $('#ChangeStart').timepicker({
        'timeFormat': 'H:i',
        'step': '15',
        'minTime': '8:00',
        //'maxTime':timepicker_end
      })
    })

    // [代理投稿]
    $('#proxy_flag').off('click.proxy_flag').on('click.proxy_flag', function() {
      if($('#proxy_flag').prop('checked')) {
        $('#filed_ap_name').slideDown(400)
        $('#ApName').addClass('required')
      } else {
        $('#filed_ap_name').slideUp(400)
        $('#ApName').removeClass('required')
        $('#ApName').val('')
      }
    })

    //[アポ確保時間:自動計算]
    $('.filed_MtgDate').off('change.filed_MtgDate click.filed_MtgDate input.filed_MtgDate mousemove.filed_MtgDate').on('change.filed_MtgDate click.filed_MtgDate input.filed_MtgDate mousemove.filed_MtgDate', function() {
      const start_time = $('#ChangeStart').val(), end_time = $('#ChangeEnd').val()
      const reg = /\d{2}:\d{2}/

      if(reg.test(start_time) && reg.test(end_time)) {
        const dateTo = moment('2022-01-01 ' + start_time + ':00')
        const dateFrom = moment('2022-01-01 ' + end_time + ':00')
        const diff = dateFrom.diff(dateTo, 'minutes')

        if(Math.sign(diff) == -1) {
          alert('[商談時間] 開始日時は終了日時より前にしてください。')
          $('#ChangeStart, #ChangeEnd').val('')
        } else {
          $('#Duration').val(diff)
        }
      }
    })

    // ステータス
    $('#Status').off('change.Status').on('change.Status', function() {
      let this_val = $(this).val()

      that.inputApType = $('#ApType').val()
      $('.ChangedPoints, .filed_MtgDate, .filed_MtgAdd, .filed_MtgType, .filed_duration, .filed_VisitArea, .filed_Device').css('display', 'none')
      $('#ChangeDate, #ChangeStart, #ChangeEnd, #Duration, #MtgType, #MtgAdd').val('')
      $('.filed_changed').css('display', 'flex')

      func.checkOut($('[name="ChangedPoints"]'))
      $('#fieldReasons').css('display', 'block')
      $('.checked_ops').removeClass('required')
      $('#ChangeDate, #ChangeStart, #ChangeEnd, #Duration, #MtgType, #MtgAdd, #Device').removeClass('required')
      $('#MtgAdd').prop('required', false)

      if( !$('.chip').hasClass('required') ) { //前確OK以外の場合
        $('.checkChangedPoints, .chip, [name="neckEle"]').addClass('required')
      }

      func.clearChips()
      func.checkOut($('[name="neckEle"]'))

      switch (this_val) {
        
        case '前不':
          $('.filed_changed').css('display', 'none')
          $('#checkNo').prop('checked', true)

          // 前不のみ表示
          $('[data-neck="前不"]').closest('.c-choices__item').show()
          $('.chip').not('[data-neck="前不"]').closest('.c-choices__item').hide()
          break;

        case 'キャンセル':
          $('.filed_changed').css('display', 'none')
          $('#checkNo').prop('checked', true)

          $('.chip').closest('.c-choices__item').show()
          $('[data-neck="前不"]').closest('.c-choices__item').hide()
          break;

        case 'リスケ':
          $('.filed_changed').css('display', 'flex')
          that.resConfirmAp == '＊' ? $('.Yet').css('display', 'block') : $('.Risuke').css('display', 'block') //前不案件の場合、リスケ(未定)のみ表示 : リスケ全選択肢表示
          $('#ChangedPointsMtgType').closest('.c-choices__item').css('display', 'block')
    
          if(that.inputApType.indexOf('オンライン') !== -1 || that.inputApType.indexOf('来社') !== -1) {
            $('#checkMtgAdd').prop({'disabled':true, 'checked':false})
          }

          $('.chip').closest('.c-choices__item').show()
          $('[data-neck="前不"]').closest('.c-choices__item').hide()
          break;

        case '復活':
          $('.filed_changed').css('display', 'flex')
          $('.Hukkatsu').css('display', 'block')
          $('#fieldMtgAdd, #ChangedPointsMtgType').closest('.c-choices__item').css('display', 'block')
    
          if(that.inputApType.indexOf('オンライン') !== -1 || that.inputApType.indexOf('来社') !== -1) {
            $('#fieldMtgAdd, #ChangedPointsMtgType').css('display', 'none')
          }
          
          $('.chip, [name="neckEle"]').removeClass('required')
          $('#fieldReasons').css('display', 'none')
          break;
        
        case '前確OK':
          $('.chip, .checkChangedPoints, [name="neckEle"]').removeClass('required')
          $('#fieldReasons').css('display', 'none')
          break;

        case '前確不要':
            $('.chip, .checkChangedPoints, [name="neckEle"]').removeClass('required')
            $('#fieldReasons').css('display', 'none')
            break;
      }
    })

    // [案件ID検索結果]
    $(document).off('click.ListEvents').on('click.ListEvents', '[name="ListEvents"]', function() {
      let dataAbout = $(this).data('about')
      let dataRes = $(this).data('res')
      const allOpsMtgType = {'オンライン':'オンライン', '来社':'来社', 'カフェ':'対面', '訪問':'対面'}
      const allOpsStatus = ['リスケ', 'キャンセル', '前不', '復活']

      func.addOption($('.select_status'), allOpsStatus) // 「ステータス」選択肢が初期状態に

      func.addDataOption($('[name="MtgType"]'), allOpsMtgType) // 「商談形式」選択肢が初期状態に

      that.inputComName = dataAbout.company_name
      that.inputMtgGDate = dataAbout.mtg_date
      that.inputCalName = dataAbout.cal_name
      that.inputApTypeName = dataAbout.ap_type_name.replace('🎦', 'オンライン')
      that.resCalId = dataRes.cal_id
      that.resEventId = dataRes.event_id
      that.resMtgType = dataRes.mtg_type
      that.resMtgAdd = dataRes.mtg_add
      that.resHook = dataRes.hook
      that.resSuggestion = dataRes.suggestion
      that.resApType = dataRes.ap_type
      that.resVisitArea = dataRes.visit_area
      that.resConfirmAp = dataRes.confirm_ap

      $('#ComName').val(that.inputComName)
      $('#ApType').val(that.inputApTypeName)
      $('#MtgDate').val(that.inputMtgGDate)
      $('#CalName').val(that.inputCalName)

      const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
      const isIncludeSelfSalesCal = officeData.checkIncludeSelfSalesCal(that.resCalId)
      const isRescanCal = officeData.checkRescanCal(that.resCalId)

      if (isIncludeSaihouCal || (isRescanCal && that.resApType == '再訪')) { //再訪案件
        that.inputSaihou = '(再訪)'
        $('.filed_proxy').css('display', 'none')
      } else if(isIncludeSelfSalesCal) { //自アポ
        $('.filed_proxy').css('display', 'none')
        that.inputSaihou = ''
      } else {
        that.inputSaihou = ''
      }

      $('select').css('display', 'block')
      $('#wrap2,.autoG').slideDown()

      if(
        that.inputCalName.indexOf('リスキャン') !== -1 ||
        (
          that.inputCalName.indexOf('リスキャン') == -1 &&
          that.inputMtgGDate.split('）')[1].split('〜')[0] == '00:00'
        )
      ) {
        $('select#Status option[value=キャンセル], select#Status option[value=リスケ], select#Status option[value=前不], select#Status option[value=前確OK], select#Status option[value=前確不要]').remove()
      } else if(that.resConfirmAp == '＊' && that.inputCalName.indexOf('リスキャン') !== -1) { //前不案件 × リスキャンカレンダー
        $('select#Status option[value=キャンセル], select#Status option[value=前不], select#Status option[value=前確OK], select#Status option[value=前確不要]').remove()
      } else if(that.resConfirmAp == '＊' && that.inputCalName.indexOf('リスキャン') == -1) { //前不案件 × リスキャンカレンダー以外
        $('select#Status option[value=前不], select#Status option[value=前確OK], select#Status option[value=前確不要]').remove()
      } else {
        $('select#Status option[value=復活]').remove()
      }

      if(that.resConfirmAp == '☆' || that.resConfirmAp == '◇') {
        $('select#Status option[value=前不]').remove()
      }

      $('select#MtgType option[data-type=' + that.resMtgType + ']').remove()
    })

    // [変更点：表示非表示]
    $(document).off('click.checkChangedPoints').on('click.checkChangedPoints', '.checkChangedPoints', function() {
      const Subject_Id = $('#SubjectId').val()
      let mtgStartTime_now = '',
      mtgEndTime_now = '',
      mtgDate_now = ""

      $('.filed_MtgDate, .filed_MtgAdd, .filed_MtgType, .filed_duration').css('display', 'none')

      if(Subject_Id.startsWith('sungrove') == false) {
        let format = 'YYYY/MM/DD HH:mm'
        mtgStartTime_now = $('#MtgDate').val().split('）')[1].split('〜')[0]
        mtgEndTime_now = $('#MtgDate').val().split('）')[1].split('〜')[1]
        mtgDate_now = $('#MtgDate').val().split('（')[0].replace(/\//g, '-')
      }

      // 「商談日」「商談時間」挙動
      const resultChecked = func.checkedVal($('[name="ChangedPoints"]'))
      let mtgIsCheckedFlag = 0
      
      if( /商談日/.test(resultChecked) && /商談時間/.test(resultChecked)) {
        mtgIsCheckedFlag = 1
      } else if(/商談日/.test(resultChecked)) {
        mtgIsCheckedFlag = 2
      } else if(/商談時間/.test(resultChecked)) {
        mtgIsCheckedFlag = 3
      }

      $('#ChangeDate, #ChangeStart, #ChangeEnd').addClass('required')
      $('.filed_MtgDate').css('display', 'block')
      switch (mtgIsCheckedFlag) {
        case 0 : // 商談日・商談時間 - 未選択
          $('#ChangeDate, #ChangeStart, #ChangeEnd').removeClass('required')
          $('#ChangeDate, #ChangeStart, #ChangeEnd').val('')
          $('.filed_MtgDate').css('display', 'none')
        break;
        case 1 : // 商談日・商談時間 - 選択
          $('#ChangeDate, #ChangeStart, #ChangeEnd').prop('readonly', false)
          if($('#ChangeDate').val() == mtgDate_now ) $('#ChangeDate').val('')
          if($('#ChangeStart').val() == mtgStartTime_now && $('#ChangeEnd').val() == mtgEndTime_now ) $('#ChangeStart, #ChangeEnd').val('')
        break;
        case 2 : // 商談日 - 選択
          $('#ChangeStart').val(mtgStartTime_now).prop('readonly', true)
          $('#ChangeEnd').val(mtgEndTime_now).prop('readonly', true)
          if(Subject_Id.startsWith('sungrove')) $('#ChangeStart, #ChangeEnd').prop('readonly', false)

          $('#ChangeDate').prop('readonly', false)
          if($('#ChangeDate').val() == mtgDate_now ) $('#ChangeDate').val('')
        break;
        case 3 : // 商談時間 - 選択
          $('#ChangeDate').val(mtgDate_now).prop('readonly', true)
          $('#ChangeStart, #ChangeEnd').val('').prop('readonly', false)
          if(Subject_Id.startsWith('sungrove')) $('#ChangeDate').prop('readonly', false)

          if($('#ChangeStart').val() == mtgStartTime_now && $('#ChangeEnd').val() == mtgEndTime_now ) $('#ChangeStart, #ChangeEnd').val('')
        break;
      }

      if($('#checkMtgType').is(':checked')) {
        $('#MtgType').addClass('required')
        $('.filed_MtgType').css('display', 'block')
      } else {
        let apTypeVal = $('#ApType').val()

        $('#MtgType').val('')
        $('#MtgType, #Device, [name="area"]').removeClass('required')
        $('.filed_Device, .filed_VisitArea').css('display', 'none')
        func.clearSelect($('#Device'))
        func.checkOut($('[name="area"]'))

        if(apTypeVal.indexOf('対面') !== -1 && $(this).val() == '商談形式') { //商談形式を選択=>商談形式外す=>商談住所チェック可能
          $('#checkMtgAdd').prop({'disabled': false, 'checked': false})
        } else if(apTypeVal.indexOf('オンライン') !== -1 && $(this).val() == '商談形式') {//商談形式を選択=>商談形式外す=>商談住所が非活性
          $('#checkMtgAdd').prop({'disabled': true, 'checked': false})
        }
      }

      if($('#checkMtgAdd').is(':checked')) {
        $('#MtgAdd').addClass('required')
        $('#MtgAdd').prop('required', true)
        $('.filed_MtgAdd').css('display', 'block')
        that.resMtgType == '対面' ? $('.field_mtgAddReason').slideDown() : $('.field_mtgAddReason').slideUp()
      } else {
        $('#MtgAdd').val('')
        $('#MtgAdd').prop('required', false)
        $('#MtgAdd').removeClass('required')
        $('.field_mtgAddReason').slideUp()
        func.checkOut($('[name="changedMtgAddReason"]'))
      }
    })

    // [変更点：未定同時選択不可]
    $('#checkYet').off('click.checkYet').on('click.checkYet', function () {
      if($(this).prop('checked')) {
        $('#checkMtgDate, #checkDuration, #checkMtgAdd, #checkMtgType').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    $('#checkMtgDate, #checkDuration, #checkMtgAdd, #checkMtgType').off('click.checkMtgDate').on('click.checkMtgDate', function () {
      if($(this).prop('checked')) {
        $('#checkYet').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    // [変更点：復活なし、同時選択不可]
    $('#checkNo').off('click.checkNo').on('click.checkNo', function () {
      if($(this).prop('checked')) {
        $('#checkMtgDate,#checkDuration,#checkMtgType,#checkMtgAdd').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    $('#checkMtgDate, #checkDuration, #checkMtgType, #checkMtgAdd').off('click.checkNo2').on('click.checkNo2', function () {
      if($(this).prop('checked')) {
        $('#checkNo').prop('checked', false)
        $(this).prop('checked', true)
      }
    })

    // [商談形式：来社]
    $('#MtgType').off('change.MtgType').on('change.MtgType', function() {
      $('.filed_VisitArea').css('display', 'none')
      $('[name="area"]').removeClass('required')
      $('#checkMtgAdd').prop({'disabled': true, 'checked': false})
      $('.field_mtgAddReason').slideUp()
    
      func.checkOut($('[name="area"]'))

      if($(this).val() == '来社') {
        $('.filed_VisitArea').css('display', 'block')
        $('[name="area"]').addClass('required')
        $('#MtgAdd').val('')
      }
    })

    // [商談形式：オンライン]
    $('#MtgType').off('change.MtgType2').on('change.MtgType2', function() {
      $('.filed_Device').css('display', 'none')
      $('#Device,#MtgAdd').removeClass('required')
      $('#MtgAdd').prop('required', false)
      $('#Device').val('')
      $('.filed_MtgAdd').css('display', 'none')
      $('#checkMtgAdd').prop('checked', false)
      $('#checkMtgAdd').prop({'disabled': true, 'checked': false})
      $('.field_mtgAddReason').slideUp()

      if($(this).val() == 'オンライン') {
        $('.filed_Device').css('display', 'block')
        $('#Device').addClass('required')
        $('#MtgAdd').val('')
      } else if($(this).val() == 'カフェ' || $(this).val() == '訪問') {
        $('.filed_MtgAdd,#ChangedPointsMtgType').css('display', 'block')
        $('#MtgAdd').addClass('required')
        $('#MtgAdd').prop('required', true)
        $('#checkMtgAdd').prop({'disabled': false, 'checked': true})
      }
    })

    // [ネック：表示非表示]
    $(document).off('click.neck').on('click.neck', '.neck_li', function() {
      let id_name = $(this).attr('id')
      const neck_id = '#neck_' + id_name

      if($('#neck_' + id_name).hasClass("up")) { //非表示→表示
        $(this).addClass('-semi-active')
        $(neck_id).show(400)
        $(neck_id).removeClass("up")
        $(neck_id).addClass("down")
      } else if($(neck_id).hasClass("down")) { //表示→非表示
        $(neck_id).hide(400)
        $(this).removeClass('-semi-active -active')
        $(neck_id).removeClass("down")
        $(neck_id).addClass("up")
        func.checkOut($(neck_id + ' [name="neckEle"]'))
        $(neck_id).find('.other').val('').hide()
      }

      // [必須項目設定]ループ処理で一つでもdownクラスがあるかどうか
      if(func.checked_neckCate() == 1) {
        $('[name="ResuCanReasonCate"]').addClass('required')
        func.checkOut($('[name="neckEle"]'))
      }
    })

    // [ネック：チェック動作]
    $(document).off('click.neck2').on('click.neck2', '[name="neckEle"]', function() {
    
      const CheckedNeck = $(this)
      const this_class_name = CheckedNeck.attr("class").replace('required','')
      let neck_flag = ""
      
      $('.' + this_class_name).each(function(i, elem) {
        if($(elem).prop('checked')) {
          neck_flag += "1"
        } else {
          neck_flag += "0"
        }
      })

      if(neck_flag.indexOf('1') != -1) { // 中カテで1つでもチェックされている
        CheckedNeck.parent().parent().parent().removeClass("down")

        let id = CheckedNeck.parent().parent().parent().attr('id')
        let splited_id = id.split('_')[1]

        $('#' + splited_id).removeClass('-semi-active').addClass(that.checked_color)
      } else { // チェック外した
        CheckedNeck.parent().parent().parent().addClass("down")

        let id = CheckedNeck.parent().parent().parent().attr('id')
        let splited_id = id.split('_')[1]

        $('#' + splited_id).removeClass(that.checked_color).addClass('-semi-active')
      }

      const neckName = this_class_name.split('_')[1]
      if(CheckedNeck.is(':checked') && CheckedNeck.val() == 'その他') { //中カテその他がチェックされた場合
        $('#sonota_' + neckName).slideDown(400).addClass('required')
      } else if( ! CheckedNeck.is(':checked') && CheckedNeck.val() == 'その他') {
        $('#sonota_' + neckName).slideUp(400).val('').removeClass('required')
      }
    })

    // [必須項目設定]
    $(document).off('change.required input.required click.required mousemove.required').on('change.required input.required click.required mousemove.required', '#form_ap', function() {
      let bg_color = '#ffcdd2'
      let color = '#212121'

      $('#btn').addClass('disabled').removeClass('pulse')
      $('.required').css({
        'background-color': bg_color,
        'color': color
      })
      
      // [形式チェック：商談住所]
      let format_flags = ""

      if($('#MtgAdd').prop('required')) {
        let trigger = $('[name="pref_name"]')
        let label_class = '.error_msg_pref'
        let label_tag = '<label class="error_msg_pref">※ 都道府県名から入力してください</label>'
        let insertbfr = $('[name="pref_name"]')

        format_flags = func.format_check_pref(trigger, label_class, label_tag, insertbfr)
      }

      let resultRequiredArray = func.checkRequired()

      let required_NG = 0
      let check_required = 0

      for(let key in resultRequiredArray) {
        resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
      }

      if(check_required == 1 && required_NG == 0 && format_flags.indexOf('0') == -1) {
        $('#btn').removeClass('disabled').addClass('pulse')
      }
    })

    //[商談時刻制限(遡り防止)]
    $('#ChangeDate').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      $('.c-label.attention').text("変更点")

      if ($('#ChangeDate').val() < formated_today) {
        $('.c-label.attention').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#ChangeDate').val("")
      }
      
    })

    // 投稿開始
    $('#btn').off('click.btn').on('click.btn', function() {

      if($('#btn').hasClass('pulse')) {

        // 必須項目
        that.inputSubjectId = $('#SubjectId').val()
        let flag_id_Notexits = 0
        let msg_NoId = ""
      
        let confirmMtgAdd = "",
        confirmMtgType = "",
        confirmMtgDate = ""
        
        that.inputComName = $('#ComName').val()
        that.inputStatus = $('#Status').val()
        that.inputChangedPoints = func.checkedVal($('[name="ChangedPoints"]'))
        
        if($('#MtgType').val() != null) {
          that.inputMtgType = $('#MtgType').val()
          confirmMtgType = '商談形式：' + that.inputMtgType + '\n'
        } else {
          that.inputMtgType = ""
          confirmMtgType = ""
        }
        
        if($('#MtgAdd').val() != null) {
          that.inputMtgAdd = $('#MtgAdd').val()
          confirmMtgAdd = '商談住所：' + that.inputMtgAdd + '\n'
        } else {
          that.inputMtgAdd = ""
          confirmMtgAdd = ""
        }

        if($('#ChangeDate').val() !== "" || $('#ChangeStart').val() !== "") {
          that.inputChangeDate = $('#ChangeDate').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')
          that.inputChangeStart = $('#ChangeStart').val().replace(/(\d{2}):(\d{2})/, '$1:$2〜')
          that.inputChangeEnd = $('#ChangeEnd').val()
          confirmMtgDate = '商談日時：' + that.inputChangeDate + ' ' + that.inputChangeStart + that.inputChangeEnd + '\n'
        } else {
          confirmMtgDate = ''
        }

        const confirmMsg = '案件名：' + that.inputComName + '\n' +
        'ステータス：' + that.inputStatus + '\n\n' + 
        '変更点：' + that.inputChangedPoints + '\n' +
        confirmMtgDate +
        confirmMtgAdd +
        confirmMtgType

        let checkPostFlg = window.confirm('下記内容でカレンダーを反映してもよろしいでしょうか。\n\n' + confirmMsg)

        if(!checkPostFlg) return false

        if(that.inputSubjectId.startsWith('sungrove')) {
          flag_id_Notexits = 1
          msg_NoId = "\r\n\r\n★★手動対応★★\r\n案件IDがない案件の為、カレンダー自動反映できませんでした。\r\n手動で上記内容のカレンダー反映お願いします。尚、当文は自動出力です。"
        }
      
        that.inputPostId = $('#PostId').val()
        that.inputStatus = $('#Status').val()
        that.inputChangedPoints = func.check($('[name="ChangedPoints"]'))
        that.inputMtgAdd = $('#MtgAdd').val()
        that.inputDuration = $('#Duration').val()
        that.inputReasons = func.check($('[name="Reasons"]')).replace(/\//g, '')
        that.inputRecordURL = $('#RecordURL').val()
        that.inputRemarks = $('#Remarks').val() + msg_NoId
        that.inputComName = $('#ComName').val()
        that.inputApType = $('#ApType').val()
        that.inputApName = $('#ApName').val()
        that.inputCalName = $('#CalName').val()
        that.inputMtgGDate = $('#MtgDate').val()
        that.resHook = that.resHook ? that.resHook.replace('リロ', 'R').replace('ウェル', 'W') : ""
        that.inputStatusId = $('#Status option:selected').data('id')
        const check_fixed = document.getElementById("fixed_flag")

        that.fixed = ""
        if (check_fixed.checked) {
          that.fixed = "（担当固定）"
        }

        // 選択項目
        if($('#ChangeDate').val() !== "" || $('#ChangeStart').val() !== "") {
          that.inputChangeDate = $('#ChangeDate').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')
          that.inputChangeStart = $('#ChangeStart').val().replace(/(\d{2}):(\d{2})/, '$1:$2〜')
          that.inputChangeEnd = $('#ChangeEnd').val()
        }
        
        if($('#MtgType').val() != null) {
          that.inputMtgType = $('#MtgType').val()
        } else {
          that.inputMtgType = ""
        }
      
        if(that.inputDuration !== '') {
          that.inputDuration += '分'
        } else {
          that.inputDuration = ''
        }
      
        if($('#PostId').val() == undefined) {
          that.inputPostId = ''
        } else {
          that.inputPostId = $('#PostId').val()
        }
      
        let WeekChars = [ '日', '月', '火', '水', '木', '金', '土' ]
      
        if(that.inputChangeDate != '') {
          that.inputChangeDay = '（' + WeekChars[new Date(that.inputChangeDate).getDay()]+'）'
        }
      
        let visit_area = '', device_type = ''
      
        if($('[name="area"]:checked').length == 1) {
          that.inputVisitArea = '[来社] '+$('[name="area"]:checked').data('type')+'\r\n'
          visit_area = $('[name="area"]:checked').data('type')
        } else {
          that.inputVisitArea = ''
        }
      
        if($('#Device').val() !== null) {
          that.inputDevice = '[使用デバイス] '+$('#Device').val()+'\r\n'
          device_type = $('#Device').val()
        } else {
          that.inputDevice = ''
        }
        
        //日付チェック
        if(that.inputChangeDate && !that.inputChangeDate.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)) {
          alert("入力された日付が不正です\nxxxx/xx/xx という形式で入力してください\n（曜日は要りません）")
          return false
        }
        
        if(that.inputChangeDate && that.inputChangeDate.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}(（|\()[月火水木金土日](）|\))[0-9]{2}:[0-9]{2}～[0-9]{2}:[0-9]{2}/)) {
          let splited_bfr = that.inputMtgGDate.split('）')[1].split('〜')
          let bfrDuration = func.duration(splited_bfr[0], splited_bfr[1])
          let afterDuration = func.duration($('#ChangeStart').val(), $('#ChangeEnd').val())
      
          if(bfrDuration == afterDuration) {
            that.inputDuration = ''
          }
      
        }

        if(that.inputMtgAdd) that.post_mtgAddd = '*********(企業情報の為、非表示)'
        if($('[name="changedMtgAddReason"]:checked').length == 1) that.inputMtgAddReasons = '[商談住所の変更理由] '+$('[name="changedMtgAddReason"]:checked').val()+'\n'

        // 理由大カテ中カテ
        const neckEle = $('[name="neckEle"]')
        const setCateAry = []
        
        for(let i = 0; i < neckEle.length; i++) {
          if(neckEle[i].checked) {
            //GASデータ送信用関数
            let setCate = neckEle[i].dataset.neck
            let setSubCate = neckEle[i].value

            // 中カテその他の場合
            if(setSubCate == 'その他') {
              const cateName = neckEle[i].id.split('-')[0]
              const sonotaVal = $('#sonota_' + cateName).val()
              setSubCate += '（' + sonotaVal + '）'
            }
      
            if(that.inputReasons == "") {
              that.inputReasons = '[' + setCate + '] '+ setSubCate + '\r\n'
              setCateAry.push(setCate)
              continue
            }
      
            for(let j = 0; j < setCateAry.length; j++) {
              if( setCate == setCateAry[j]) {
                that.inputReasons = that.inputReasons.slice(0, -2); //改行削除
                that.inputReasons += '/ '+ setSubCate + '\r\n'
                break
              }
              
              if(j == (setCateAry.length - 1)) { //jの最後の処理
                that.inputReasons += '[' + setCate + '] '+ setSubCate + '\r\n'
                setCateAry.push(setCate)
                break
              }
            }
          }
          
          if(i == (neckEle.length - 1)) that.inputReasons = that.inputReasons.slice(0, -2) //改行削除
        }
      
        // 理由大カテ小カテ　ID取得
        let inputCateIDAry = []
        let sub_cate_ary = []
        for (let i = 0; i < neckEle.length; i++) {
      
          if( neckEle[i].checked ) {
            inputCateIDAry.push(neckEle[i].id)
            sub_cate_ary.push(neckEle[i].id)
          }
        }

        let inputCateID = inputCateIDAry.join(',')

        // data-id取得
        that.dataIDs.sub_cate_reasons = sub_cate_ary
        that.dataIDs.subject_id = that.inputSubjectId
        that.dataIDs.status = $('[id="Status"] option:selected').data('id')
        that.dataIDs.user_sg_id = that.store.state.userData.id

        if( that.inputChangedPoints ) { //変更点ありの場合
          let changed_ary = []
          $('[name="ChangedPoints"]:checked').each(function(i, ele){
              let ary = {id: '', section1: '', section2: ''}
              ary.id = $(ele).data('id')

              switch(ary.id) {

                case 1 :
                  ary.section1 = that.inputChangeDate
                  break;
                
                case 2 :
                  ary.section1 = that.inputChangeStart.split('〜')[0]
                  ary.section2 = that.inputChangeEnd
                  break; 
                
                case 3 :
                  ary.section1 = $('#MtgType option:selected').data('id')
                  break;
                
                case 4 :
                  ary.section1 = that.inputMtgAdd
                  break;

                case 5 :
                  if( that.inputStatusId == 1 ) {
                    that.inputStatusId = 2 
                    that.dataIDs.status = 2
                  }
                  break;
              }
              changed_ary.push(ary)

          })
          that.dataIDs.changed_points = changed_ary

        }
      
        // talknote投稿
        ;(async function() {

          let user_id = that.store.state.userData.userID   
          let postID = func.getPostID(that.inputSubjectId, that.slackChannel.userID, that.slackChannel.id)
          that.inputMtgGDate = that.inputMtgGDate.replace(/-/g, "/")

          //[投稿画面バージョン]
          let Extension_version = that.store.state.version
      
          let toSpreadsheet = '検索ID：' + that.inputSubjectId+
                        '\r\n' + '【' + that.inputApType + that.inputSaihou + '】' + ' [' + Extension_version + ']' +
                        '\r\n' + '担当AP：' + that.inputApName + that.fixed +
                        '\r\n' + '案件名：' + that.inputComName +
                        '\r\n' + '登録カレンダー：' + that.inputCalName +
                        '\r\n' + 'Gカレ上商談日時：' + that.inputMtgGDate +
                        '\r\n' +
                        '\r\n' + 'ステータス：' + that.inputStatus +
                        '\r\n' + '変更点：' + that.inputChangedPoints +
                        '\r\n' +
                        '\r\n' + '商談日時：' + that.inputChangeDate + that.inputChangeDay + that.inputChangeStart + that.inputChangeEnd +
                        '\r\n' + '確保時間：' + that.inputDuration +
                        '\r\n' + '商談住所：' + that.inputMtgAdd +
                        '\r\n' + '商談形式：' + that.inputMtgType +
                        '\r\n' +
                        '\r\n' + '理由：' + that.inputReasons +
                        '\r\n' + '理由ID：' + inputCateID +
                        '\r\n' + '録音URL：' + that.inputRecordURL +
                        '\r\n' + '備考：' + that.inputVisitArea + that.inputDevice + that.inputMtgAddReasons + that.inputRemarks + 
                        '\r\n' + '-----------------------------' +
                        '\r\n' + 'POST ID：' + postID +
                        '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'
          
          // slack 投稿者にメンション
          const slackMention = that.store.state.userData.office_id != 7 ? `<@${that.slackChannel.userID}>\r\n` : `${that.store.state.userData.displayName}\r\n`
          
          let toSlack = slackMention +
                          '検索ID：' + that.inputSubjectId + 
                          '\r\n' + '【' + that.inputApType + that.inputSaihou + '】' + ' [' + Extension_version + ']' +
                          '\r\n' + '担当AP：' + that.inputApName + that.fixed +
                          '\r\n' + '登録カレンダー：' + that.inputCalName + 
                          '\r\n' + 'Gカレ上商談日時：' + that.inputMtgGDate + 
                          '\r\n' +
                          '\r\n' + 'ステータス：' + that.inputStatus + 
                          '\r\n' + '変更点：' + that.inputChangedPoints + 
                          '\r\n' + 
                          '\r\n' + '商談日時：' + that.inputChangeDate + that.inputChangeDay + that.inputChangeStart + that.inputChangeEnd + 
                          '\r\n' + '確保時間：' + that.inputDuration + 
                          '\r\n' + '商談住所：' + that.post_mtgAddd + 
                          '\r\n' + '商談形式：' + that.inputMtgType + 
                          '\r\n' + 
                          '\r\n' + '録音URL：' + that.inputRecordURL +
                          '\r\n' + '備考：' + that.inputVisitArea + that.inputDevice + that.inputMtgAddReasons + that.inputRemarks +
                          '\r\n' + '-----------------------------' +
                          '\r\n' + 'POST ID：' + postID +
                          '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'
      
          if(that.inputChangedPoints == '未定') {
            that.not_yet = 1
          } else if(that.inputChangedPoints == 'なし') {
            that.nashi = 1
          }
          

          const userData = that.store.state.userData
          if (userData.BN !== "" && userData.BN !== null && userData.BN !== "NULL" && userData.BN !== "None") {
            var fullname = userData.BN + "（" + userData.firstName + "） " + userData.lastName
          } else {
            var fullname = userData.firstName + " " + userData.lastName
          }


          /*********************************************************
           * 投稿開始
           * *******************************************************/

          const data = {
            "channel": that.slackChannel.id, 
            "username": fullname,
            "text":  toSlack, 
            "token": that.slackChannel.token,
            "icon_url": that.slackChannel.iconURL
          }
    
          // slack投稿
          const response = await fetch(that.slackChannel.postURL, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
          })
          .then(function (response) {
            return response.json()
          }).then(function (response) {
    
            if(response.message == "not current version") {
              alert(`tnPostのバージョンが違うようです。\n更新します。`);
              location.reload()
              postResult = false
              return false
            } else {
              
              console.log('slack投稿完了', response)
    
              return response
            }
    
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })



          const requestFunc = new RequestBody.requestBody()
          const groupName = "reScheduleCancel"
          const spreadsheetApi = that.store.state.apis[`spreadsheet${that.demo}`]
          const calUpdateApi = that.store.state.apis[`calUpdate${that.demo}`]
          const DBInsertApi = that.store.state.apis[`dbInsert${that.demo}`]
          const DBUpdateApi = that.store.state.apis[`dbUpdate${that.demo}`]
          const NowTime = requestFunc.nowTime()
          const created_at = requestFunc.nowTime("created_at")
          const tn_user_id = user_id.toString()
          const changearea = $('input[name="area"]:checked').data("type");


          const messageURL = `${that.groupURL}/p${response.ts.split('.').join('')}`        
          
          const insertDataRecordCnt = [{
                                      "投稿日時":{"id": "", "val": NowTime},
                                      "全文": {"id": "", "val": func.textEscape(toSpreadsheet)},
                                      "検索ID":{"id": "", "val": that.inputSubjectId},
                                      "投稿ID":{"id": "", "val": postID},
                                      "案件名":{"id": "", "val": func.textEscape(that.inputComName)},
                                      "登録カレンダー":{"id": "", "val": that.inputCalName},
                                      "商談日時(変更前)":{"id": "", "val": that.inputMtgGDate},
                                      "種別":{"id": "", "val": ""},
                                      "ステータス":{"id": "", "val": that.inputStatus},
                                      "変更点_商談日":{"id": "", "val": that.inputChangeDate},
                                      "変更点_開始時間":{"id": "", "val": $('#ChangeStart').val()},
                                      "変更点_終了時間":{"id": "", "val": that.inputChangeEnd},
                                      "変更点_商談住所":{"id": "", "val": that.inputMtgAdd},
                                      "変更点_形式":{"id": "", "val": that.inputMtgType},
                                      "理由":{"id": "", "val": func.textEscape(that.inputReasons)},
                                      "理由ID":{"id": "", "val": inputCateID},
                                      "録音URL":{"id": "", "val": that.inputRecordURL},
                                      "担当アポ名":{"id": "", "val": "担当AP名"},
                                      "担当アポ名_ユーザーID":{"id": "", "val": "担当アポ名_ユーザーID"},
                                      "投稿者名":{"id": "", "val": fullname},
                                      "投稿者名_ユーザID":{"id": "", "val": tn_user_id},
                                      "確保時間":{"id": "", "val": that.inputDuration},
                                      "変更点":{"id": "", "val": func.textEscape(that.inputChangedPoints)},
                                      "投稿URL":{"id": "", "val": messageURL},
                                      }]

          let sheetData = requestFunc.createBody(groupName, that.isProd, insertDataRecordCnt)

          let calLinkSheetData = JSON.parse(JSON.stringify(sheetData))
          const spread = requestFunc.spreadDesignationRescan(that.isProd)
          calLinkSheetData.spread.id = spread.spreadsheetID
          calLinkSheetData.spread.sheet_name = spread.sheetName

          const insertDataObj = {
                                "status": that.inputStatus,
                                "changePoint" : that.inputChangedPoints,
                                "changeDate" : that.inputChangeDate,
                                "changestart" : $('#ChangeStart').val(),
                                "changelast" : that.inputChangeEnd,
                                "changetype" : that.inputMtgType,
                                "changearea" : changearea,
                                "changeadd" : that.inputMtgAdd,
                                }

          let subjectStatus = requestFunc.statusType(insertDataObj)
          
          let calData = { 
                        "sg_data": { 
                                    "is_prod": that.isProd,
                                    "post_url": "",
                                    "group_id": that.slackChannel.id,
                                    "user_id": userData.id,
                                    "created_at": created_at,
                                    },
                        "subject": {
                                    "subject_id": that.inputSubjectId,
                                    "status": subjectStatus.statusNumber,
                                    "changed_points": subjectStatus.changeArray,
                                    "is_fixed": check_fixed.checked
                                    },
                        "event":   {
                                    "id": that.resEventId, 
                                    "calendar_id": that.resCalId,
                                    },
                        }

          let dbData_insert_rescans = {
            "table_name": "rescans",
            "form": {
              "non_sub_query": {
                "rescan_statuses_id": subjectStatus.statusNumber,
                "created_at": created_at,
                "post_id": postID,
                "users_id": userData.id,
                "subjects_id": that.inputSubjectId
              },
              "sub_query": {
                "users": true,
              }
            },
            "authC": {
              "password": "W!z|a~.x.T~Tzca8CBCm",
              "mail": "system@sungrove.co.jp"
            },
            "support_data": {
              "users_id": userData.id,
              "is_prod": that.isProd,
            }
          }

          const neckArray = inputCateID.split(",")
          const neckArrayNumber = []

          for (let i = 0; i < neckArray.length; i++) {
            const index = neckArray[i].indexOf("-")
            if (index !== -1) {
              const numberStr = neckArray[i].substring(index + 1)
              const number = parseInt(numberStr)
              neckArrayNumber.push(number)
            }
          }

          let dbData_insert_rescan_necks = []
          for (let i = 0; i < neckArray.length ; i++) {
            dbData_insert_rescan_necks[i] = {
              "table_name": "rescan_necks",
              "form": {
                "non_sub_query": {
                  "created_at": created_at
                },
                "sub_query": {
                  "users": false,
                  "neck_rc_details_id": "SELECT id FROM neck_rc_details WHERE id = '" + neckArrayNumber[i] + "'",
                  "rescans_id": "SELECT id FROM rescans WHERE created_at = '" + created_at + "' AND subjects_id = '" + that.inputSubjectId + "' LIMIT 1"
                }
              },
              "support_data": {
                "users_id": userData.id,
                "is_prod": that.isProd,
              }
            }
          }

          let teams_ids = ""
          if (userData.teams_id && userData.teams_id != "NULL" && userData.teams_id != "None") {
            teams_ids = userData.teams_id.split(",")
          } else {
            teams_ids = [""]
          }

          let dbData_rescan_group_teams = []
          for (let i = 0; i < teams_ids.length ; i++) {
            dbData_rescan_group_teams[i] = {
              "table_name": "rescan_group_teams",
              "form": {
                "non_sub_query": {
                  "post_id": postID,
                  "created_at": created_at
                },
                "sub_query": {
                  "users": false,
                }
              },
              "support_data": {
                "users_id": userData.id,
                "is_prod": that.isProd
              }
            }
          }
        
          let dbData_insert = []
          dbData_insert.push(dbData_insert_rescans)
          if (neckArray[0] !== '') {
            for (let i = 0; i < neckArray.length ; i++) {
              dbData_insert.push(dbData_insert_rescan_necks[i])
            }
          }

          for (let i = 0; i < teams_ids.length ; i++) {
            if (userData.teams_id && userData.teams_id != "NULL" && userData.teams_id != "None") {
              dbData_rescan_group_teams[i].form.non_sub_query.teams_id = parseInt(teams_ids[i])
            }
            dbData_insert.push(dbData_rescan_group_teams[i])
          }
          console.log(dbData_rescan_group_teams)

          let dbData_update = [{
            "table_name": "ap_negotiations",
            "non_sub_query": {
              "set": {
                "updated_at": created_at
              },
              "where": {
                "subjects_id": that.inputSubjectId
              }
            },
            "sub_query": {
              "set": {},
              "where": {}
            },
            "support_data": {
              "is_prod": that.isProd
            },
            "authC": {
              "password": "W!z|a~.x.T~Tzca8CBCm",
              "mail": "system@sungrove.co.jp"
            }
          }]
          
          if (that.inputChangeDate !== "") {
            that.inputChangeDate = that.inputChangeDate.replace("/", "-").replace("/", "-")
            dbData_update[0].non_sub_query.set.negotiation_date = that.inputChangeDate
          }

          if ($('#ChangeStart').val() !== "") {
            dbData_update[0].non_sub_query.set.started_at = $('#ChangeStart').val()
          }

          if (that.inputChangeEnd !== "") {
            dbData_update[0].non_sub_query.set.ended_at = that.inputChangeEnd
          }

          if (that.inputDuration !== "") {
            that.inputDuration = parseInt(that.inputDuration.replace("分", ""))
            dbData_update[0].non_sub_query.set.duration = that.inputDuration
          }

          if (that.inputMtgAdd !== "") {
            dbData_update[0].non_sub_query.set.address = that.inputMtgAdd
            const prefectures = that.inputMtgAdd.match(/^(.+?[都道府県])/)
            let prefectureName = ""
            if (prefectures && prefectures.length > 0) {
              prefectureName = prefectures[1]
            }
            if (prefectureName) {
              dbData_update[0].sub_query.set.prefectures_id = 'SELECT id FROM prefectures p WHERE name LIKE "' + prefectureName + '%"'
            }
          }

          if (that.inputMtgType !== "") {
            const changeTypeObject = {"オンライン": 1, "来社": 2, "訪問": 3, "カフェ": 3}
            dbData_update[0].non_sub_query.set.negotiation_types_id = changeTypeObject[that.inputMtgType]
          }

          if (that.inputDevice !== "") {
            dbData_update[0].non_sub_query.set.device = that.inputDevice
          }

          // Loading表示  
          that.vueObj.viewLoader('投稿中')

      
          $('#btn').addClass('disabled').removeClass('pulse')

          const createSpreadRequest = () => {
            try {
              const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, sheetData, that.key)
              return spreadRequest

            } catch (e) {
              console.log('スプレッドシートへの登録に失敗しました')
            }
          }

          const createCalRequest = () => {
            try {
              const calRequest = requestFunc.apiRequestExecution(calUpdateApi, calData, that.key)
              return calRequest

            } catch (e) {
              throw e
            }

          }

          const createDBInsertRequest = () => {
            try {
              const DBInsertRequest = requestFunc.apiRequestExecution(DBInsertApi, dbData_insert, that.key)
              return DBInsertRequest

            } catch (e) {
              console.log('DBへの格納に失敗しました')
            }

          }

          const createDBUpdateRequest = () =>  {
            try {
              const DBUpdateRequest = requestFunc.apiRequestExecution(DBUpdateApi, dbData_update, that.key)
              return DBUpdateRequest

            } catch (e) {
              console.log('DBのupdateに失敗しました')
            }

          }

          const promises = []
          let responseData = ""

          const calRequestFunction = async () => {
            try {
              const calRequest = createCalRequest()
              const response = await calRequest
              responseData = await response.json()
            } catch (e) {
              let message = ""
              if (e.name === 'AbortError') {
                message = requestFunc.createCalTimeOutMessage(messageURL)
              } else {
                message = requestFunc.createCalFailureApplyMessage(messageURL)
              }

              const data = {
                "channel": that.calendarFailureApplySlackChannelID, 
                "username": fullname,
                "text":  "【" + that.groupTitle + "】" + message, 
                "token": that.slackChannel.token,
                "icon_url": that.slackChannel.iconURL
              }

              // slack投稿
              fetch(that.slackChannel.postURL, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
              })

              console.error('カレンダー反映に失敗しました', e)

              let failuredSheetData = JSON.parse(JSON.stringify(sheetData))
              const failuredListSpread = requestFunc.spreadDesignationRescan(that.groupID, "failuredList")
              failuredSheetData.spread.id = failuredListSpread.spreadsheetID
              failuredSheetData.spread.sheet_name = failuredListSpread.sheetName
              failuredSheetData.spread.reference_row = 2
              const insertDatafailuredList = [{
                "日時":{"id": "", "val": NowTime},
                "投稿URL":{"id": "", "val": messageURL},
              }]
              failuredSheetData.insert_data = insertDatafailuredList
              const failuredSpreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, failuredSheetData, that.key)
              await failuredSpreadRequest

              alert('カレンダー登録中にタイムアウトしました。\n別途再投稿指示があった場合は、再度投稿お願いします')
              window.location.href = '/'
            }
          }
          
          const calLinkRequestFunction = async () =>  {
            try {
              const location = responseData.hasOwnProperty("location") ? responseData.location : ""
              const colorId = responseData.hasOwnProperty("colorId") ? responseData.colorId : "0"

              let changeCalFlag = 1
              if (responseData.before_calendar == responseData.after_calendar) {
                responseData.after_calendar = ""
                changeCalFlag = 0
              }

              const startTime = requestFunc.formattedDateTime(responseData.start.dateTime)
              const endTime = requestFunc.formattedDateTime(responseData.end.dateTime)
            
              const insertDataCalLink = [{
                "inserted_at":{"id": "", "val": NowTime},
                "change_cal_flag":{"id": "", "val": changeCalFlag},
                "done_flag":{"id": "", "val": ""},
                "search_id":{"id": "", "val": that.inputSubjectId},
                "post_id":{"id": "", "val": postID},
                "cal_id":{"id": "", "val": responseData.before_calendar},
                "event_id":{"id": "", "val": responseData.event_id},
                "change:cal_id":{"id": "", "val": responseData.after_calendar},
                "change:title":{"id": "", "val": responseData.summary},
                "change:location":{"id": "", "val": location},
                "change:desc":{"id": "", "val": responseData.description},
                "change:start time":{"id": "", "val": startTime},
                "change:end time":{"id": "", "val": endTime},
                "change:color":{"id": "", "val": colorId},
                "change:event_id":{"id": "", "val": responseData.iCalUID},
                "guest_mail":{"id": "", "val": responseData.attendees ? responseData.attendees[0].email : ""},
              }]

              calLinkSheetData.insert_data = insertDataCalLink
              const calLintkSpreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, calLinkSheetData, that.key)
              promises.push(calLintkSpreadRequest)
            } catch (e) {
              console.log('カレンダー連携スプレッドへの格納に失敗しました')
            }
          }

          (async () => {
            const spreadRequest = createSpreadRequest()
            promises.push(spreadRequest)
            await calRequestFunction()
            await calLinkRequestFunction()

            if (subjectStatus.statusNumber >= 1 && subjectStatus.statusNumber <= 4) {
              const DBInsertRequest = createDBInsertRequest()
              promises.push(DBInsertRequest)
            }

            Promise.all(promises)
            .then(async function (response) {
              const spreadResponse = response[0]
              console.log('シート登録完了', spreadResponse)
            })
            .then(async function () {
              if (subjectStatus.statusNumber >= 1 && subjectStatus.statusNumber <= 4) {
                const DBUpdateRequest = createDBUpdateRequest()
                await DBUpdateRequest
                console.log('DB更新完了')
              }
              // 完了画面表示
              that.vueObj.viewLoader()

              // 完了画面表示
              const params = {
                groupTitle: that.groupTitle,
                groupURL: that.groupURL,
                searchURL: messageURL,
                calURL: responseData.htmlLink,
                message: '',
                completeTitle: '投稿完了'
              }
              that.vueObj.viewComplete(params)
              
            })
            .catch((e) => {
              console.error(e.stack, e.message)
              alert('カレンダー登録中にタイムアウトしました。\n別途再投稿指示があった場合は、再度投稿お願いします')
              window.location.href = '/'
            })
          })()

        })()

      }

    })

  }
 
  /************************************
  * リスキャン理由カテデータ取得
  *************************************/
  async getReasonCategory() {
    const $ = this.$
    try {
      const searchApiURL = this.store.state.apis[`dbApiURL${this.demo}`] + 'db/select'

      let dbData_select_necks = {
        "main_data": {
          "tables":["neck_cates", "neck_rc_details"],
          "query": "SELECT nc.id AS nc_id, nc.code AS nc_code, nc.name AS nc_name, nrd.id AS nrd_id, nrd.name AS nrd_name, nrd.neck_cates_id  AS nrd_neck_cates_id FROM neck_cates nc INNER JOIN neck_rc_details nrd ON nc.id = nrd.neck_cates_id "
        },
        "authC": {
          "password": "W!z|a~.x.T~Tzca8CBCm",
          "mail": "system@sungrove.co.jp"
        },
        "support_data": {
          "users_id": this.dataIDs.user_sg_id,
          "is_prod": this.isProd
        }
      }

      this.abortController = new AbortController()
      //リスキャン理由カテデータ取得
      //データ取得
      await fetch(searchApiURL, {
        signal: this.abortController.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Cal-St-Api': this.key
        },
        body: JSON.stringify(dbData_select_necks)
      }).then(response => {
        return response.json()
      }).then(data => {
        //データあった

        if($('#feildNeckDetails').find('div').length == 0) {
          let resData = data
          let mergedDivDiv = $('<ul id="cates" class="c-choices">')
          
          let idList = []
          let idListData = {}
          
          for(let j in resData) {//大カテ追加
            if(!idListData[resData[j].nc_code]) {
              idListData[resData[j].nc_code] = {}
              idListData[resData[j].nc_code].details = []
              idListData[resData[j].nc_code].nc_name = resData[j].nc_name
            }
            idListData[resData[j].nc_code].details.push({
              nrd_name: resData[j].nrd_name,
              nrd_id: resData[j].nrd_id,
            })

            if(idList.indexOf(resData[j].nc_code) > -1) continue

            let li = $('<li class="c-choices__item">')
            let div = $('<div>')

            div.attr({
              value: resData[j].nc_name,
              class: 'neck_li c-toggle__text chip required',
              id:  resData[j].nc_code,
              name: 'ResuCanReasonCate',
              'data-neck': resData[j].nc_name
            })

            div.text(resData[j].nc_name)

            let mergedDivP = $(li).append(div)
            mergedDivDiv = $(mergedDivDiv).append(mergedDivP)
            idList.push(resData[j].nc_code)
          }
     
          (mergedDivDiv).insertAfter($('#nameReason'))

          for(let j in idListData) { // 小追加
            let pDetail = $('<h3 class="c-label down">')
            
            idListData[j].details.filter(detail => {
              let span = $('<span>')
              let input = $('<input>')
              let label = $('<label class="c-choice">')
              let li = $('<li class="c-choices__item">')
              span.text(detail.nrd_name)
              input.attr({
                id: j + '-' + detail.nrd_id,
                type: 'checkbox',
                class: 'input_' + j + ' required',
                value: detail.nrd_name,
                name: 'neckEle',
                'data-neck': idListData[j].nc_name
              })
              li.attr({name: 'detail' + j})
              let detailEle = $(label).append(input, span); //チェックボックス
              $(li).append(detailEle);
              if (detail.nrd_name != "前確不要") { // 前確不要は前不から切り分けたので非表示
                $('#feildNeckDetails').append(li)
              }
            })

            let wrapedDetails = $('[name="detail' + j + '"]').wrapAll('<div class="up c-block" id="neck_' + j + '" style="display:none;">')
            $(wrapedDetails).wrapAll('<ul class="c-choices c-column -vertical neck" id="wrapDetails_' + j + '">')
            pDetail = pDetail.text(idListData[j].nc_name)
            $('#neck_' + j).prepend(pDetail)
            $('#neck_' + j).append('<input style="display:none;" type="text" placeholder="' + idListData[j].nc_name + ' その他はここに入力" class="other" id="sonota_' + j + '" data-neck="' + idListData[j].nc_name + '">')

          }
        }

      }).catch(e => {
        //データ取得失敗
        console.error(e.stack, e.message)
      })
    } catch(e) {
      //データ取得失敗
      console.error(e.stack, e.message)
    }
  }

  checkNegotiated(subjects_id) {
    let dbData_select = {
      "main_data": {
        "tables":["negotiation_reports"],
        "query": `SELECT id FROM negotiation_reports WHERE subjects_id = '${subjects_id}' AND deleted_at IS NULL`
      },
      "authC": {
        "password": "W!z|a~.x.T~Tzca8CBCm",
        "mail": "system@sungrove.co.jp"
      },
      "support_data": {
        "is_prod": this.isProd
      }
    }

    this.abortController = new AbortController()
    const searchApiURL = this.store.state.apis[`dbApiURL${this.demo}`] + 'db/select'
    //データ取得
    fetch(searchApiURL, {
      signal: this.abortController.signal,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Cal-St-Api': this.key
      },
      body: JSON.stringify(dbData_select)
    })
    .then(response => response.json())
    .then(response => {
      if (response.length > 0) {
        window.alert('商談済み案件です！\nリスキャンしても問題ない場合のみ入力を続けてください')
      }
    })
  }
}

import RequestBody from "./requestBody.class"
import OfficeDatas  from "./officeData"

export default {
  reScheduleCancel
}