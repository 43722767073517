
/*
* ぽちボタンクリック後の処理
*/ 

class afrPostItzai {
  constructor(that) {
    this.that = that
    this.formName = "イツザイ"
    const $ = this.that.$
    const store = this.that.store
    
    if(store.state.industryNamesData.length == 0) {
      this.that.vueObj.viewLoader('業界リスト読み込み中')
    }
    
    let i = 0
    const sit = setInterval(() => {
      
      if(store.state.industryNamesData.length > 0) {
        clearInterval(sit)
        this.that.vueObj.viewLoader()

        // データ出力先有無をチェック
        if($('#industry_names')[0]) {
          const industry_records = store.state.industryNamesData
          
          for(let i = 0; i < industry_records.length; i++) {
            let record = industry_records[i]
            let option = $('<option>')

            option.attr({
              id: 'jobsCategoriesID' + record.id,
              value: record.name,
              'data-id': record.id
            }).text(record.name)
            
            $('#industry_names').append(option).show()
          }

        }

      } else {
        i++
        if(i >= 200) {
          clearInterval(sit)
          alert('業界リストを取得できませんでした\nリロードします')
          window.location.reload()
        }
      }
      
    }, 50)


    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    // 値取得：制作前共有
    store.commit('setValue', ['itzai', 'inputShouldMovie', $('[name="movie_yesNo"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputRctJobCate', func.textEscape($('#rctJobCate').val()), ''])
    store.commit('setValue', ['itzai', 'inputModuleNum', func.textEscape($('#moduleNum').val()), ''])
    store.commit('setValue', ['itzai', 'inputCompensation', $('[name="compensation_yesNo"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputOtherOp', func.textEscape($('#otherOp').val()), ''])
    store.commit('setValue', ['itzai', 'inputSharedMatters', func.textEscape($('#sharedMatters').val()), ''])
    store.commit('setValue', ['itzai', 'inputShouldShoot', $('[name="shoot_yesNo"]:checked').val(), ''])
    store.commit('setValue', ['itzai', 'inputContractReason', func.textEscape($('#contractReason').val()), ''])
    store.commit('setValue', ['itzai', 'inputPersnality', func.textEscape($('#persnality').val()), ''])
    store.commit('setValue', ['itzai', 'inputConv', func.textEscape($('#conv').val()), ''])
    store.commit('setValue', ['itzai', 'inputConvItzai', func.textEscape($('#convItzai').val()), ''])
    store.commit('setValue', ['itzai', 'inputConvService', func.textEscape($('#convService').val()), ''])
    store.commit('setValue', ['itzai', 'inputShootDate', func.textEscape($('#shootDate').val()), ''])
    store.commit('setValue', ['itzai', 'inputReasonNotShoot', func.textEscape($("#reasonNotShoot").val()), ''])
    store.commit('setValue', ['itzai', 'inputRctBackground', func.textEscape($('#rctBackground').val()), ''])
    store.commit('setValue', ['itzai', 'inputOtherHope', func.textEscape($('#otherHope').val()), ''])
    store.commit('setValue', ['itzai', 'inputSharedOpTeams', func.textEscape($('#sharedOpTeams').val()), ''])
    store.commit('setValue', ['itzai', 'inputJobOfferCampaign', func.textEscape($('[name="guide_job_offer_campaign"]:checked').val()), ''])

    // v2.0.1
    store.commit('setValue', ['itzai', 'inputContactCEOName', $("#contactCEOName").val(), 'contact_ceo_name'])
    store.commit('setValue', ['itzai', 'inputJobCnt', $("#job_cnt").val(), ''])
    store.commit('setValue', ['itzai', 'inputPackedCnt', $('#packed_cnt').val(), '']) 
    store.commit('setValue', ['itzai', 'inputIndustryName', $('#industry_names').val(), 'industry_name'])
    store.commit('setValue', ['itzai', 'inputIndustryDataId', $('#industry_names option:selected').data("id"), 'industry_data_id'])

  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎イツザイ
動画撮影：${store.state.formData.itzai.inputShouldMovie}
パッケージ数：${store.state.formData.itzai.inputPackedCnt}
サイト制作数：${store.state.formData.itzai.inputJobCnt}
業界：${store.state.formData.itzai.inputIndustryName}
募集職種名：${store.state.formData.itzai.inputRctJobCate}
追加モジュール数：${store.state.formData.itzai.inputModuleNum}
補償オプション：${store.state.formData.itzai.inputCompensation}
その他オプション：${store.state.formData.itzai.inputOtherOp}
お客様共有事項：${store.state.formData.itzai.inputSharedMatters}
撮影有無：${store.state.formData.itzai.inputShouldShoot}
撮影無し理由：${store.state.formData.itzai.inputReasonNotShoot}
契約動機：${store.state.formData.itzai.inputContractReason}
顧客人柄：${store.state.formData.itzai.inputPersnality}
お客様との会話内容：${store.state.formData.itzai.inputConv}
イツザイに載せる際に話した内容：${store.state.formData.itzai.inputConvItzai}
サービスについて伝えた事：${store.state.formData.itzai.inputConvService}
掲載希望日時：${store.state.formData.itzai.inputShootDate}
求人背景：${store.state.formData.itzai.inputRctBackground}
その他顧客希望：${store.state.formData.itzai.inputOtherHope}
運用への共有事項：${store.state.formData.itzai.inputSharedOpTeams}
求人ボックスキャンペーン対応：${store.state.formData.itzai.inputJobOfferCampaign}
`

    const contactValue = document.getElementById("contact_itzai").value
    let contactInformation = ""
    if (contactValue) {
      contactInformation = `連絡先情報備考：${contactValue}`
    }
    
    sendVal = sendVal + contactInformation + '\n'
    store.commit('setTalknoteMessage', ['itzai', sendVal])
  }

  actionInit() {
    const $ = this.that.$
    
    $("#form_ap").on("click", '[name="shoot_yesNo"]', function() {
      
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "shoot_yes":
          $("#fildReasonNotShoot").slideUp()
          $("#reasonNotShoot").removeClass("required")
          $("#reasonNotShoot").val("")
          break;
      
        case "shoot_no":
          $("#fildReasonNotShoot").slideDown()
          $("#reasonNotShoot").addClass("required")
          break;
      }
      
    })
  }

/*  setParams() {
    const $ = this.that.$
    const vueObj = this.that.vueObj
    const props = this.that.props
    const func = this.that.func
    const store = this.that.store
    const getDataApiURL = this.that.getDataApiURL

    let params = {
      //msg: store.state.msg,
      contact_com_name: this.values.inputContactComName,
      contact_name: this.values.inputContactName,
      contact_mail: this.values.inputContactMail,
      contact_tel_num: this.values.inputContactTelNum,
    };

    return params
  } */
}

export default {
  afrPostItzai 
}