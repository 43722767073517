import { createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "g-loading" }
const _hoisted_2 = { class: "g-loading__container" }
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"g-loading__animation\" data-v-60c40580><svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 76 76\" width=\"36\" height=\"36\" class=\"g-loading__animation-icon\" data-v-60c40580><path d=\"M66.39 61.59c-.16.16-.33.31-.5.46a7.31 7.31 0 01-.8.65c-9.18 7.06-26.67 3.84-41-8.08C9.63 42.58 3.36 26 8.83 15.72a7.44 7.44 0 01.44-.78 6.27 6.27 0 01.38-.57 13 13 0 00-1.15 1.22A15 15 0 007 17.78v.06c-.54.74-1 1.5-1.52 2.25a36.67 36.67 0 007.83 47.4 37.13 37.13 0 0047.41-.08 31.82 31.82 0 002.77-2.57h.06a14.67 14.67 0 001.89-1.89 15.46 15.46 0 00.95-1.36z\" data-v-60c40580></path><path d=\"M70.41 54.84c.19-.24.37-.48.54-.73 2.88-4 4.17-10.18 3.55-17a13.91 13.91 0 01-.65 3 11 11 0 01-.85 1.95 10.35 10.35 0 01-1.17 1.71 1.14 1.14 0 00-.15.16l-.23.25c-6.4 6.83-21.24 4.21-33.58-6.06s-17.6-24.5-11.78-31.95l.08-.1.14-.18a10.74 10.74 0 011.49-1.45 9.74 9.74 0 011.76-1.16 13.7 13.7 0 012.86-1.16c-6.83.59-12.77 3-16.18 6.45-.22.23-.43.45-.63.68a11.55 11.55 0 00-1 1.42c-.12.19-.25.41-.37.62C9.1 20.6 15.46 36.4 29.55 48.1s30.68 15.1 39 8.53l.63-.52a11.94 11.94 0 001.21-1.25s.01-.02.02-.02z\" data-v-60c40580></path><path d=\"M72.6 35.35a7.82 7.82 0 00.72-2.43 10.34 10.34 0 00.05-2.24 22.84 22.84 0 00-3.58-9.91 3.9 3.9 0 010 .77 4.07 4.07 0 01-.1.7 3.94 3.94 0 01-.49 1.2c-.09.14-.18.28-.29.42a4.19 4.19 0 01-1.22.94c-3.17 1.55-9.19-.32-14.59-4.8S44.61 9.56 46 6.26a3.87 3.87 0 01.59-1 2.59 2.59 0 01.37-.37A4 4 0 0148 4.21a5.66 5.66 0 01.69-.21c.24 0 .49-.1.75-.13A22.79 22.79 0 0039 2.08a11.47 11.47 0 00-2.22.46 7.82 7.82 0 00-2.28 1.12 5.8 5.8 0 00-1.15 1.06h-.06l-.21.29c-3.89 5.35 1.16 16.41 11.51 25s21.89 11.56 26.65 7.12l.46-.47a.18.18 0 01.05-.07 6 6 0 00.85-1.24z\" data-v-60c40580></path><path d=\"M69 17.4a10.47 10.47 0 01-14.73.53 10.33 10.33 0 01-.5-14.65 10.46 10.46 0 0114.71-.5A10.29 10.29 0 0169 17.4z\" data-v-60c40580></path></svg></div>", 1)),
      _createElementVNode("div", {
        class: "g-loading__text",
        innerHTML: _ctx.loaderText + '...'
      }, null, 8, _hoisted_3)
    ])
  ]))
}