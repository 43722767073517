/**
  アポ報告用フォームコンポーネント
*/
<template>
  <Header></Header>
  <div class="l-wrapper">
    <div class="l-content">

      <Title
      :isAdmin="isAdmin"
      :groupTitle="groupTitle"
      :modeChangeBtnText="modeChangeBtnText"
      :modeChangeBtnLabel="modeChangeBtnLabel"
      :modeStatus="modeStatus"
      :modeChange="modeChange"
      :edit="edit"
      :post="post"
      ></Title>

      <Section>
        <Description>
          お疲れさまです。<br>
          <span v-html="displayName"></span>さん
        </Description>
      </Section>

      <form action="" method="get" id="form_ap" autocomplete="off">

        <Box class="js-notEditable">
          <Section><h1>案件情報</h1></Section>

          <Column id="searchID">
            <Column>
              <label for="name">案件IDで案件検索</label>
              <div class="display_yoko">
                <input type="text" id="SubjectId" placeholder="（例）AP111111_111111" min="22">
              </div>
            </Column>
            <Column>
              <div id="result_api">
                <label>検索コメント：</label>
                <p id="status_comment" class="c-notice">案件IDを入力すると検索開始します。</p>
                <div class="width100 text_center">
                  <span class="wrapper4 timer-loader" id="loading_form"></span>
                </div>
                <div class="display_tate" id="result_titles">
                </div>
              </div>
            </Column>
          </Column>

          <div class="form_wrap">
            <Column>
              <label for="name">案件名</label>
              <div class="display_yoko">
                <input type="text" name="company_name" id="ComName" class="required form_txt" placeholder="サングローブ株式会社" required>
              </div>
            </Column>

            <Column>
              <label for="name">代表者名</label>
              <div class="display_yoko">
                <input type="text" class="form_txt" placeholder="山田太郎" id="CeoName">
              </div>
            </Column>

            <Column>
              <label for="name">メールアドレス</label>
              <ul class="c-variables fild_email_list">
                <li class="c-variables__item fild_email">
                  <input placeholder="example@sungrove.co.jp" class="form_txt emails" type="email" id="CostomerEmail1">
                  <div class="c-variables__item-controls">
                    <IconAdd name="add" value="＋" class="form_add btn-floating btn-small waves-effect waves-light red lighten-3 form_add mg1" data-name="mail1"></IconAdd>
                    <IconRemove  name="del" value="－" class="form_del btn-floating btn-small waves-effect waves-light blue lighten-3 form_del" data-name="mail1"></IconRemove>
                  </div>
                </li>
              </ul>
            </Column>

            <Column class="comInfo">
              <label for="name">担当AP名</label>
              <div class="display_yoko">
                <input type="text" name="" id="apName" class="form_txt" placeholder="" >
              </div>
            </Column>

          </div>
        </Box>

        <div class="form_wrap">

          <Box id="bizInfo" class="form-example">
            <Section><h1>商談情報</h1></Section>

            <Column class="mtg mtgInfo item js-notEditable" style="display:none;">
              <label for="name">種別</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="type" value="新規" id="typeNew">
                    <span class="">新規</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="type" value="再訪" id="typeRevisit">
                    <span class="">再訪</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="mtg mtgInfo item js-notEditable" style="display:none;">
              <label>商談日時</label>
              <Flex class="display_yoko  c-column__item">
                <div class="pd4_r">
                  <input type="date" id="MtgDate" class="required form_txt" min="" placeholder="日付指定" required>
                </div>
                <Flex id="MtgTime" class="display_yoko">
                  <div class="pd4_r">
                    <input type="text" class="time start f_time required form_txt" id="MtgStartTime" placeholder="開始時間" required />
                  </div>
                  <div class="pd4_r">
                    <input type="text" class="time end f_time required form_txt" id="MtgEndTime" placeholder="終了時間" required />
                  </div>
                </Flex>
              </Flex>
            </Column>

            <Column class="width45 mtg mtgInfo item js-editable">
              <label for="name">商談結果</label>
              <div class="c-select">
                <select id="Result" class="required form_select" v-model="editableItems.Result">
                  <option value="" disabled selected>--商談結果 選択--</option>
                  <option value="即決" data-id="1">即決</option>
                  <option value="受注" data-id="2">受注</option>
                  <option value="受注済み決済" data-id="7">受注済み決済</option>
                  <option value="受注済み打ち合わせ" data-id="8">受注済み打ち合わせ</option>
                  <option value="追客A" data-id="3">追客A</option>
                  <option value="追客B" data-id="3">追客B</option>
                  <option value="追客C" data-id="3">追客C</option>
                  <option value="失注" data-id="4">失注</option>
                  <option value="現場リスケ" data-id="5">現場リスケ</option>
                  <option value="現場キャンセル" data-id="6">現場キャンセル</option>
                </select>
              </div>
            </Column>

            <Column class="tuikyaku mtgInfo item js-editable">
              <label for="">回答期日</label>
              <label id="ContactDeadlineCheck"></label>
              <div class="pd4_r">
                <input type="date" id="ContactDeadline" class="form_txt" min="" plcaeholder="日付指定" v-model="editableItems.ContactDeadline">
              </div>
            </Column>

            <Column class="mtg mtgInfo item js-editable" name="f_sgs">
              <label for="name" class="c-label width_check">提案内容</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="cms" name="Suggestion" value="CMS" data-id="1" >
                    <span class="">CMS</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="ec" name="Suggestion" value="EC"  data-id="2">
                    <span class="">EC</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="ituzai" name="Suggestion" value="イツザイ"  data-id="3">
                    <span class="">イツザイ</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="ituzai_lite" name="Suggestion" value="イツザイライト" data-id="8">
                    <span class="">イツザイライト</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="s_sub" name="Suggestion" value="助成金"  data-id="5">
                    <span class="">助成金</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="seo" name="Suggestion" value="SEO"  data-id="6">
                    <span class="">SEO</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="meo" name="Suggestion" value="MEO"  data-id="7">
                    <span class="">MEO</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="easier" name="Suggestion" value="Easier"  data-id="11">
                    <span class="">Easier</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" class="required form_check" id="other" name="Suggestion" value="その他"  data-id="99">
                    <span class="">その他</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="mtg mtgInfo item js-editable" name="f_sgs">
              <label for="name" class="c-label width_check">商談相手</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="MtgPerson" class="required form_check" value="決裁者" data-id="1" >
                    <span class="">決裁者</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="MtgPerson" class="required form_check" value="担当者(社内)" data-id="2" >
                    <span class="">担当者(社内)</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="checkbox" name="MtgPerson" class="required form_check" value="相談者(社外)" data-id="3" >
                    <span class="">相談者(社外)</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column id="gender" class="mtg mtgInfo item js-notEditable">
              <label>商談相手の性別</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="required form_check" name="gender" value="女" id="gender_women" data-id="1">
                    <span class="">女</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="required form_check" name="gender" value="男" data-id="2">
                    <span class="">男</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label class="c-choice">
                    <input type="radio" class="required form_check" name="gender" value="男女" data-id="3">
                    <span class="">男女</span>
                  </label>
                </li>
              </ul>
            </Column>

          </Box>

          <Box id="payInfo" class="form-example">
            <Section><h1>入金</h1></Section>

            <Column class="saled saledInfo item js-editable">
              <label for="">決済進捗</label>
              <div class="c-select">
                <select name="" id="PayStatus" class="form_select" v-model="editableItems.PayStatus">
                  <option value="" disabled selected>--決済進捗 選択--</option>
                  <option data-status="done" data-id="1">決済完了</option>
                  <option data-status="doing" data-id="2">一部決済</option>
                  <option data-status="notyet" data-id="3">未決済</option>
                </select>
              </div>
            </Column>

            <Column class="display_yoko js-editable">
              <div class="saled saledInfo item width100 saledInfo-total">
                <label for="">受注金額</label>
                <Flex class="display_yoko">
                  <input id="SalesPrice" type="text" min="1" class="form_txt" inputmode="numeric" v-model="editableItems.SalesPrice" readonly>
                  <span class="chara_bottom">円</span>
                </Flex>
              </div>
            </Column>
            <Block>
              <Column class="display_yoko js-notEditable">
                <div class="saled saledInfo item width100 saledInfo-paid">
                  <label for="">決済済み金額</label>
                  <Flex class="display_yoko">
                    <input id="Paid" type="text" min="1" class="form_txt" inputmode="numeric" readonly>
                    <span class="chara_bottom">円</span>
                  </Flex>
                </div>
              </Column>
              <Column class="display_yoko js-editable">
                <div class="saled saledInfo item width100 saledInfo-remain" >
                  <label for="">残高</label>
                  <Flex class="display_yoko">
                    <input type="text" min="1" class="form_txt" id="Balance" inputmode="numeric" v-model="editableItems.Balance" readonly>
                    <span class="chara_bottom">円</span>
                  </Flex>
                </div>
              </Column>
              <Column class="saledInfo item js-editable" id="notPaid" >
                <label for="">残額期日</label>
                <label id="BalanceDeadlineCheck"></label>
                <div>
                  <div class="pd4_r">
                    <input type="date" id="BalanceDeadline" class="form_txt" min="" v-model="editableItems.BalanceDeadline">
                  </div>
                </div>
              </Column>
              <Column class="formPayment_option saledInfo">
                <label >支払い方法</label>
                <div class="c-select">
                  <select id="paymentMethod" class="form_select paymentOp">
                    <option value="" disabled selected>--支払い方法 選択--</option>
                    <option data-id = "1">クレジットカード払い</option>
                    <option data-id = "2">銀行振込</option>
                    <option data-id = "3">セゾン発行</option>
                    <option data-id = "4">新規クレジット発行（セゾン以外）</option>
                    <option data-id = "5">ビジネスクレジット</option>
                    <option data-id = "6">クレジットカード振替（次長以上の管理者許可必須）</option>
                    <option data-id = "7">口座振替（次長以上の管理者許可必須）</option>
                  </select>
                </div>
                <Notice>※振替は申込金額の50％以上の決済をした場合のみ相談可。</Notice>
              </Column>
            </Block>

          </Box>

          <Box id="EvaluationReasonInfo" class="form-example">
            <Section><h1>アポ評価</h1></Section>

            <Column class="reasons item" id="wrapEvaluationReason">

              <Column>
                <label for="name">担当営業</label>
                <div class="display_yoko">
                  <input type="text" id="chargeName" class="form_txt">
                </div>
              </Column>

              <Column>
                <label for="">提案商材</label>
                <ul class="c-choices">
                  <li class="c-choices__item">
                    <label class="c-choice">
                      <input type="checkbox" class="form_check" name="product" value="welbox">
                      <span class="">ウェルボックス</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice">
                      <input type="checkbox" class="form_check" name="product" value="indeed">
                      <span class="">イツザイ</span>
                    </label>
                  </li>
                  <li class="c-choices__item">
                    <label class="c-choice">
                      <input type="checkbox" class="form_check" name="product" value="web">
                      <span class="">web直販</span>
                    </label>
                  </li>
                </ul>
              </Column>

              <Column id="start_evaluation">
                <label for="">F評価（現場キャンセルおよび現場リスケ日程不明相当）</label>
                <div class="c-select">
                  <select id="evaluation_F" class="form_select js-editable" v-model="editableItems.evaluation_F">
                    <option value="" disabled selected>--選択してください--</option>
                    <option value="該当">該当</option>
                    <option value="非該当">非該当</option>
                  </select>
                </div>
                <button type="button" class="c-link" @click="is_modal_F = !is_modal_F">F評価-定義詳細確認</button>
              </Column>

              <Column class="column_evaluation_E is_evaluation_F">
                <label for="">E評価（現場リスケ日程確定相当）</label>
                <Column>
                  <div class="c-select">
                    <select id="evaluation_E" class="form_select js-editable removeRequired" v-model="editableItems.evaluation_E">
                      <option value="" disabled selected>--選択してください--</option>
                      <option value="該当">該当</option>
                      <option value="非該当">非該当</option>
                    </select>
                  </div>
                  <button type="button" class="c-link" @click="is_modal_E = !is_modal_E">E評価-定義詳細確認</button>
                </Column>
              </Column>

              <Column class="column_evaluation_D is_evaluation_F is_evaluation_E">
                <label for="">D評価</label>

                <Column>
                  <div class="c-select">
                    <select id="is_evaluation_D" class="form_select js-editable removeRequired">
                      <option value="" disabled selected>--選択してください--</option>
                      <option value="該当">該当</option>
                      <option value="非該当">非該当</option>
                    </select>
                  </div>
                  <button type="button" class="c-link" @click="is_modal_D = !is_modal_D">D評価-定義詳細確認</button>
                </Column>

                <Column class="is_evaluation_D">
                  <ul class="c-choices">
                    <li class="c-choices__item" v-for="(values) in evaluation_D_definition">
                      <label class="c-choice">
                        <input type="checkbox" name="outlet" v-bind:value="values.value" v-bind:id="'outlet-' + values.id" class="removeRequired evaluation_D_checkbox">
                        <span class="required_color int_required_color_color">{{ values.value }}</span>
                      </label>
                    </li>
                  </ul>
                </Column>
              </Column>

              <Column v-for="item in questionItems" :key="item.id" class="column_evaluation_C is_evaluation_F is_evaluation_E" :class="item.classes">
                <label for="" v-html="item.label"></label>
                <div class="c-select">
                  <select :id="item.id" class="evaluation_question_C form_select js-editable removeRequired" v-model="editableItems[item.id]">
                    <option value="" disabled selected>--選択してください--</option>
                    <option value="該当">該当</option>
                    <option value="非該当">非該当</option>
                  </select>
                </div>
              </Column>

              <Column class="reasons item js-notEditable">
                <label for="">評価詳細</label>
                <div><textarea class="form_txt" style="height:120px;" placeholder="アポ評価理由の詳細" type="text" id="Detail"></textarea></div>
              </Column>

              <div class="reasons item js-notEditable" id="wrapFB" style="display: none;">
                <Column>
                  <label for="">他アポインターにも参考にしてほしいこと</label>
                  <div>
                    <textarea class="form_txt" style="height:80px;" type="text" id="FBGood"></textarea>
                  </div>
                </Column>
                <Column>
                  <label for="">改善点</label>
                  <div>
                    <textarea class="form_txt" style="height:80px;" type="text" id="FBMore"></textarea>
                  </div>
                </Column>
              </div>
            </Column>

            <Column class="js-editable">
              <label for="name">紹介キャンペーンの案内実施有無</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label for="guide_campaign_yes" class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="guide_campaign" value="有" id="guide_campaign_yes">
                    <span>有</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label for="guide_campaign_no" class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="guide_campaign" value="無" id="guide_campaign_no">
                    <span>無</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="js-editable">
              <label for="name">助成金の案内の有無</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label for="guide_subsidy_yes" class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="guide_subsidy" value="有" id="guide_subsidy_yes">
                    <span>有</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label for="guide_subsidy_no" class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="guide_subsidy" value="無" id="guide_subsidy_no">
                    <span>無</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="js-editable">
              <label for="name">求人ボックスキャンペーンの案内の有無</label>
              <ul class="c-choices">
                <li class="c-choices__item">
                  <label for="guide_job_offer_campaign_yes" class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="guide_job_offer_campaign" value="有" id="guide_job_offer_campaign_yes">
                    <span>有</span>
                  </label>
                </li>
                <li class="c-choices__item">
                  <label for="guide_job_offer_campaign_no" class="c-choice pd5_rl">
                    <input type="radio" class="required form_check" name="guide_job_offer_campaign" value="無" id="guide_job_offer_campaign_no">
                    <span>無</span>
                  </label>
                </li>
              </ul>
            </Column>

            <Column class="display_yoko js-editable">
              <div class="item width100">
                <label for="">最終提案金額</label>
                <Flex class="display_yoko">
                  <input type="text" min="1" class="form_txt" inputmode="numeric" id="last_suggestion_price">
                  <span class="chara_bottom">円</span>
                </Flex>
              </div>
            </Column>

            <Column>
              <label for="name">パサーの名前</label>
              <div class="display_yoko">
                <input type="text" class="form_txt" placeholder="山田太郎" id="passer_name">
              </div>
            </Column>

          </Box>

          <Box id="mtgInfo" class="form-example js-notEditable">
            <Section><h1>商談内容</h1></Section>
            <Column class="mtgDetail item">
              <label for="">商談詳細</label>
              <div class="form_parts"><textarea class="required form_txt" id="MtgDetail"></textarea></div>
            </Column>
          </Box>

          <Box id="remarkBox" class="form-example js-notEditable">
            <Column class="form_parts">
              <label for="">備考</label>
              <div class="form_parts"><textarea id="Remark" class="form_txt" placeholder="メンションは投稿後のコメントからお願いします。"></textarea></div>
            </Column>
            <Column>
              <label>音源データ</label>
              <div class="form_parts">
                <input type="text" id="record_data" class="record form_txt" min="">
              </div>
            </Column>
          </Box>

          <div class="pd2 text_center" id="filedBtn">
            <Button :tag="'a'" class="waves-effect waves-light btn disabled" id="btn">slackへ送信</Button>
          </div>
        </div>

      </form>

    </div>
  </div>

  <Modal :class="{ '-active': is_modal_F }" :modalTitle="'F評価定義'" :modalName="'is_modal_F'">
    <Column>
      <div>
        ◆定義◆<br>
        商談現場で商談が行えなかった、あるいは商談時間開始しても下記に該当する場合。<br>
        認識齟齬による現場キャンセル、または現場リスケ（日程不明）相当の案件が該当する。<br>
        ※大前提として、ヒアリングから商材提案にまで入っている場合のアポ評価はE以上とする。<br>
        ※30分以上の商談で、アポ評価は基本的にD評価以上とする<br>
        <br>
        ・商談場所に商談相手が来ず商談にならなかった場合。(商談時間から30分を猶予としその後撤退することとする。）<br>
        ・商談開始時間を過ぎてのリスケキャンセル連絡があった場合。<br>
        ・商談開始後問題が発覚し、その対応のみ(ほとんどの割合)で商談が終了した場合。<br>
        ・商談開始後クレーム対応のみで商談ができずに謝罪のみで終了した場合。<br>
        ・リスケキャンセルの連絡を受けているにもかかわらず、アポ獲得者の申告漏れで訪問した場合。(直前のキャンセルはアポインターのキャンセル投稿を指標とする)<br>
        ・オンライン商談にて、先方のPC不良や環境不良などによりオンライン接続ができず商談不成立だった場合。<br>
        ・商談相手が外国人で日本語が一切話せない場合。<br>
      </div>
    </Column>
  </Modal>

  <Modal :class="{ '-active': is_modal_E }" :modalTitle="'E評価定義'" :modalName="'is_modal_E'">
    <Column>
      <div>
        ◆定義◆<br>
        商談現場で商談が行えなかった、あるいは商談時間開始しても下記に該当する場合。<br>
        現場リスケ（日程確定）相当のアポイントが該当<br>
        ※前提として、ヒアリングから商材提案にまで入っている場合のアポ評価はD以上とする。<br>
        ※30分以上の商談で、アポ評価は基本的にD評価以上とする<br>
        <br>
        ・商談開始後30分以内に先方都合にて商談が終了した場合。（フロントで断られており、商談をしていない場合に限る）<br>
        ・オンライン商談の場合、商談相手が運転中や作業中だと確認でいた場合、必ずリスケ対応することとする。<br>
        ・担当と話して代表同席アポにて、代表同席なく同席促した結果、先方より別日打診があった場合。<br>
        ・オンライン商談にて、先方のPC不良や環境不良などによりオンライン接続ができず電話商談となった場合。<br>
      </div>
    </Column>
  </Modal>

  <Modal :class="{ '-active': is_modal_D }" :modalTitle="'D評価定義'" :modalName="'is_modal_D'">
    <Column>
      <div>
        ◆定義◆<br>
        ・概要認識が全くない。（会話になっていない・高圧的かつ怒っている、支離滅裂な発言など商談に支障が出るレベル ）<br>
        ・時間確保が60分以下であった。<br>
        ・費用認識がない。（年間4万や6万の年間費用認識を含む）<br>
        ・事業代表者だが、決済権がなく雇われ社長またはFC加盟店、スポンサーなどに決裁権があった。<br>
        ・集客または採用が不要であると明言された。<br>
        ・事業継続意志怪しい または数年以内に事業閉鎖をすると明言された。<br>
        ・データがないと検討すらできないなどの発言があった。（求職者の個人データをもらわないと話すことはできない、または今あるリストを持ってこないと検討しないなど）<br>
        ・商談場所が明確に決まっていなかった。<br>
        ・お試し契約ができる認識だった。<br>
        ・商談開始時間が明確に決まっていなかった。<br>
        ・商談日程が仮抑えの日程であり、覚えていなかった。<br>
        ・WELBOXのみで契約できる認識であった。<br>
        ・福利厚生のデータを保持している、またはそれに等しい発言があった場合。<br>
        ・WELBOX直提携されていた。<br>
        ・イツザイ保証対象外業種を保証できる認識だった。<br>
        ・代理店経由でのindeed掲載済みであった。<br>
        ・履歴書を持ってくる認識だった。（求職者情報を持ってくる認識。）<br>
        ・日本語の発声及び理解力に懸念を感じ、商談進行に影響があった場合。<br>
        ・高齢者と判断でき理解力に懸念があるまたは耳が遠いなど商談進行に影響があった場合。<br>
        ・強引に日程調整から商談になっており、商談開始時にお叱りを受ける等の商談進行に影響があった場合。<br>
        ・法人又は代表者および経営陣に逮捕・行政処分歴がある場合。<br>
        ・「取締役」以上の役職確認が取れていない。（役員言質のみ・担当者など）<br>
        ・サービス保証がある認識であった。<br>
      </div>
    </Column>
  </Modal>

  <Loading :class="{ '-active': isLoading }" :loaderText="loaderText"></Loading>
  <Complete v-if="isComplete" :groupTitle="groupTitle" :groupURL="groupURL" :searchURL="searchURL" :message="message" :completeTitle="completeTitle"></Complete>
</template>

<script>
  import { defineComponent } from 'vue'
  import moment from "moment"
  import $ from 'jquery'
  import "jquery-ui"
  import sha256 from 'crypto-js/sha256'

  import formFuncClass from "../../assets/js/formFunctions.class.js"
  import apoReportClass from "../../assets/js/apoReport.class.js"
  import formDataStore from "../../assets/js/formDataStore"

  // コンポーネントを読み込む
  import Header from '../organisms/header.vue';
  import Title from '../organisms/title.vue';
  import Loading from '../organisms/loading.vue';
  import Modal from '../organisms/modal.vue';
  import Complete from '../complete.vue';

  import Variables from '../molecules/variables.vue';
  import Choices from '../molecules/choices.vue';
  import Box from '../molecules/box.vue';
  import Section from '../molecules/section.vue';
  import Column from '../molecules/column.vue';
  import Group from '../molecules/group.vue';
  import Flex from '../molecules/flex.vue';
  import Block from '../molecules/block.vue';

  import Description from '../atoms/description.vue';
  import Notice from '../atoms/notice.vue';
  import Label from '../atoms/label.vue';
  import Input from '../atoms/input.vue';
  import Select from '../atoms/select.vue';
  import Textarea from '../atoms/textarea.vue';
  import Button from '../atoms/button.vue';
  import IconAdd from '../atoms/icon-add.vue';
  import IconRemove from '../atoms/icon-remove.vue';

  export default defineComponent({
    name: 'formApoReport',

    components: {
      Header,
      Title,
      Loading,
      Modal,
      Complete,

      Variables,
      Choices,
      Box,
      Section,
      Column,
      Group,
      Flex,
      Block,

      Description,
      Notice,
      Label,
      Input,
      Select,
      Textarea,
      Button,
      IconAdd,
      IconRemove,
    },

    data() {
      return {
        initialized: false,
        isLoading: false,
        isComplete: false,
        isBody: true,
        loaderText: '',
        groupTitle: '',
        searchURL: '',
        message: '',
        completeTitle: '',
        displayName: '',
        is_modal_F: false,
        is_modal_E: false,
        is_modal_D: false,
        column_evaluation_E: false,

        isAdmin: false,
        modeChangeBtnText: '',
        modeChangeBtnLabel: '',
        modeStatus: '',
        modeChange: () => {},
        edit: true,
        post: false,
        editableItems: {},

        questionItems: [
          { id: 'evaluation_question_1', classes: ['evaluation_question_1'], label: '■商談認識　提案内容の概要認識が高く、理解力があると判断できる。'},
          { id: 'decision_1', classes: ['decision_1'], label: '■商談認識　提案内容の概要認識は大まかだがある。'},
          { id: 'evaluation_question_2', classes: ['evaluation_question_2'], label: '■時間確保　集客もしくはイツザイ案件の場合、時間確保が120分以上である。'},
          { id: 'decision_2', classes: ['decision_2'], label: '■時間確保　集客もしくはイツザイ案件の場合、時間確保が90分以上である。'},
          { id: 'evaluation_question_3', classes: ['evaluation_question_3'], label: '■費用認識　費用認識が総額認識である。 ※定価以上の総額認識である。 <br>　契約時の支払い方法案内がクレジットカードまたは一括決済であることを<br>　伝えており、尚且つその認識がある。'},
          { id: 'decision_3', classes: ['decision_3'], label: '■費用認識　月額認識を含み、費用認識に問題がない。'},
          { id: 'decision_4', classes: ['evaluation_question_4', 'decision_4'], label: '■決裁権　法人はまたはみなし法人の代表者（決済権がある）であり<br>　その場で判断ができると言質があること。<br>　相談者がいる場合、必要な相談者の同席が確定していること。'},
          { id: 'evaluation_question_5', classes: ['evaluation_question_5'], label: '■人柄　コミュニケーション取りやすく、人柄に問題ないと判断できる。'},
          { id: 'decision_5', classes: ['decision_5'], label: '■人柄　人柄に問題ないが反応が薄い、または人柄に問題はないが懐疑的である。'},
          { id: 'decision_6', classes: ['evaluation_question_6', 'decision_6'], label: '■商材提案　商材提案に問題がない。<br>　WEB関係を自社で対応可能でも提案可能であれば条件クリアとする。<br>　※既にWEB関係を他社契約していても、<br>　　追加での提案に否定的な意見がなければ条件クリアとする。'},
          { id: 'evaluation_question_7', classes: ['evaluation_question_7'], label: '■集客または採用意欲　集客または採用に関して、非常に意欲的である。<br>　※集客意欲の基準として、積極的に新規顧客を受け入れたいと言質がある。<br>　※採用意欲が高い基準として、直近半年以内に5名以上の<br>　　採用意欲があり、また中長期的にも継続採用意欲があること。<br>　※「ちょうどこれから有料広告等を打ち出そうと検討していた」等の<br>　　タイミングが合致している場合は<br>　　人数に関わらず上記に該当していなくともその限りではない。'},
          { id: 'decision_7', classes: ['decision_7'], label: '■集客または採用意欲　集客または採用に関して、意欲的である。<br>　※集客意欲の基準として、新規顧客を受け入れたいと言質がある。<br>　※採用意欲が高い基準として、<br>　　直近半年以内に2名以上の採用意欲があること。'},
          { id: 'decision_next_7', classes: ['decision_next_7'], label: '■集客または採用意欲　集客、または採用に関して意欲的ではないが<br>　集客または採用をする意向はある。<br>　※集客意欲の基準として、積極的に新規顧客を受け入れているわけではない、<br>　　問い合わせがあれば受け入れる等の言質がある。<br>　※採用意欲C基準として、直近半年以内に1名以上の採用意欲があること。'},
          { id: 'decision_8', classes: ['evaluation_question_8', 'decision_8'], label: '■集客または採用キャパシティ<br>　キャパシティは問題なく受け入れできると発言があった。'},
          { id: 'evaluation_question_9', classes: ['evaluation_question_9'], label: '■保証認識　保証ネックが出ていない。<br>　※集客案件の場合、「集客できるか不安」などの不安からのネックは非該当。'},
          { id: 'decision_9', classes: ['decision_9'], label: '■保証に関する発言があった。<br>　※APが説明の上で理解し払しょくできている'},
          { id: 'evaluation_question_10', classes: ['evaluation_question_10'], label: '■紹介認識　顧客または求職者の紹介認識ではないこと。<br>　※紹介認識であっても否定の上で理解していれば条件クリアとする。'},
          { id: 'decision_10', classes: ['decision_10'], label: '■紹介認識　顧客または求職者の紹介認識ではあるが、<br>　否定の上で紹介斡旋ではないことを理解している。'},
          { id: 'decision_11', classes: ['evaluation_question_11', 'decision_11'], label: '■商談環境<br>　商談環境（PCやカメラおよび音声接続）が整っている状態であること。<br>　※オンラインの場合、PCカメラ付きchromeあり状態で、<br>　　オンライン商談をこれまでにも経験あり。<br>　※対面商談の場合には、スターバックス・ドトール・タリーズ等の<br>　　小規模カフェ設定ではない。'},
          { id: 'welbox', classes: ['welbox'], label: '■ＷＥＬＢＯＸ　ＷＥＬＢＯＸ掲載可能業種である。'},
          { id: 'indeed', classes: ['indeed'], label: '■indeed<br>　indeed掲載がある場合、代理店経由での掲載ではない確認が取れていること。<br>　→自社で無料掲載または有料広告を投下している場合は掲載自体に支障はないとする。'},
          { id: 'last', classes: ['last'], label: '★費用提案後のテスクロまたは終話までに「契約したい」<br>　または「非常に前向き」であると判断できる発言があった場合。'},
        ],

        evaluation_D_definition: [
          {
            id: 1,
            value: '・概要認識が全くない。（会話になっていない・高圧的かつ怒っている、支離滅裂な発言など商談に支障が出るレベル ）',
          },
          {
            id: 2,
            value: '・時間確保が60分以下であった。',
          },
          {
            id: 3,
            value: '・費用認識がない。（年間4万や6万の年間費用認識を含む）',
          },
          {
            id: 4,
            value: '・事業代表者だが、決済権がなく雇われ社長またはFC加盟店、スポンサーなどに決裁権があった。',
          },
          {
            id: 5,
            value: '・集客または採用が不要であると明言された。',
          },
          {
            id: 6,
            value: '・事業継続意志怪しい または数年以内に事業閉鎖をすると明言された。',
          },
          {
            id: 7,
            value: '・データがないと検討すらできないなどの発言があった。（求職者の個人データをもらわないと話すことはできない、または今あるリストを持ってこないと検討しないなど）',
          },
          {
            id: 8,
            value: '・商談場所が明確に決まっていなかった。',
          },
          {
            id: 9,
            value: '・お試し契約ができる認識だった。',
          },
          {
            id: 10,
            value: '・商談開始時間が明確に決まっていなかった。',
          },
          {
            id: 11,
            value: '・商談日程が仮抑えの日程であり、覚えていなかった。',
          },
          {
            id: 12,
            value: '・WELBOXのみで契約できる認識であった。',
          },
          {
            id: 13,
            value: '・福利厚生のデータを保持している、またはそれに等しい発言があった場合。',
          },
          {
            id: 14,
            value: '・WELBOX直提携されていた。',
          },
          {
            id: 15,
            value: '・イツザイ保証対象外業種を保証できる認識だった。',
          },
          {
            id: 16,
            value: '・代理店経由でのindeed掲載済みであった。',
          },
          {
            id: 17,
            value: '・履歴書を持ってくる認識だった。（求職者情報を持ってくる認識。）',
          },
          {
            id: 18,
            value: '・日本語の発声及び理解力に懸念を感じ、商談進行に影響があった場合。',
          },
          {
            id: 19,
            value: '・高齢者と判断でき理解力に懸念があるまたは耳が遠いなど商談進行に影響があった場合。',
          },
          {
            id: 20,
            value: '・強引に日程調整から商談になっており、商談開始時にお叱りを受ける等の商談進行に影響があった場合。',
          },
          {
            id: 21,
            value: '・法人又は代表者および経営陣に逮捕・行政処分歴がある場合。',
          },
          {
            id: 22,
            value: '・「取締役」以上の役職確認が取れていない。（役員言質のみ・担当者など）',
          },
          {
            id: 23,
            value: '・サービス保証がある認識であった。',
          },
        ],
      }
    },

    methods: {
      init() {
        // console.log('フォーム初期化開始')
        this.isComplete = false
        this.isLoading = false

        console.log('フォーム初期化開始')
        this.thisFormParams = this.store.state.groupList.filter((obj) => obj[0].trim() == this.$route.params.type.trim())[0]
        this.groupTitle = this.thisFormParams[0]
        this.displayName = this.store.state.userData.displayName

        document.documentElement.scrollTop = 0

        // 同じコンポーネントに遷移するときの初期化はここまで
        //if(this.initialized) return

        // フォームが本番かdemoか
        this.isProd = this.groupTitle.indexOf('demo_') == -1

        // 投稿先のslackチャンネル情報
        this.slackChannel = {
          id: this.thisFormParams[6],
          userID: this.store.state.userData.slackUserID,
          name: this.thisFormParams[7],
          postURL: this.store.state.apis.slackPOSTURL,
          workSpaceURL: this.store.state.apis.slackWorkSpaceURL,
          token: this.store.state.apis.slackToken,
          iconURL: this.store.state.userData.emoji,
          forwardID01: this.thisFormParams[8],
          forwardID02: this.thisFormParams[9],
          errorPostID: this.store.state.apis.errorPostSlackChannelID
        }


        const func = new formFuncClass.formFunctions(moment, $, sha256, this)
        new apoReportClass.apoReport(this, $, func, moment)

        // 初期化完了フラグ
        this.initialized = true
        console.log('フォーム初期化完了')
      },

      viewLoader(loaderText = '') {
        if(
            (!this.loaderText && !loaderText) ||
            (this.isLoading && loaderText == '')
          ) {
          this.isLoading = false
          this.loaderText = ''
          return 
        }
        this.isLoading = true
        this.loaderText = loaderText
        console.log('ローディング表示')
      },

      viewComplete(param) {
        this.groupTitle = param.groupTitle;
        this.groupURL = param.groupURL;
        this.searchURL = param.searchURL;
        this.message = param.message;
        this.completeTitle = param.completeTitle? param.completeTitle: '投稿完了';
        this.isBody = false;
        this.isComplete = true;
        console.log('完了画面表示')
      },
    },

    mounted() {
      this.store = formDataStore

      if(this.store.state.groupList.length == 0) {
        this.store.watch(
          (state, getters) => getters.groupList,
          (newValue, oldValue) => {
            console.log('ページ固有のデータ取得');
            this.init()
          }
        )
      } else {
        this.init()
      }
    },

    // 同じコンポーネント内で遷移するので以下の記述をした
    /*async beforeRouteUpdate(to, from, next) {
      console.log(to.name, from.name)
      await this.init();
      await next();
    },*/

    setup(_, context) {
      return {}
    }
  });
</script>
