/*
* ぽちボタンクリック後の処理
*/ 

class afrPostCMSLodging {
  constructor(that) {
    this.that = that
    this.formName = "CMS（宿泊型番）"    
    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    //制作前共有
    store.commit('setValue', ['cmsLodging', 'input_cms_lodging_should_movie', $('input[name="cms_lodging_should_movie"]:checked').val(), 'cmsLodgingShouldMovie'])
    store.commit('setValue', ['cmsLodging', 'input_cms_lodging_products_num', $("#cmsLodgingProductsNum").val(), 'cmsLodgingProductsNum'])
    store.commit('setValue', ['cmsLodging', 'input_cms_lodging_shootDate', $("#shootDate_cms_lodging").val(), 'shootDate_cms_lodging'])
    store.commit('setValue', ['cmsLodging', 'input_cms_lodging_version', func.textEscape($("#cmsLodgingVersion").val()), 'cmsLodgingVersion'])
    store.commit('setValue', ['cmsLodging', 'inputSharedMattersCMSLodging', func.textEscape($('#sharedMatters_cms_lodging').val()), ''])
    store.commit('setValue', ['cmsLodging', 'inputShouldShootCMSLodging', $('[name="shoot_yesNo_cms_lodging"]:checked').val(), ''])
    store.commit('setValue', ['cmsLodging', 'inputReasonNotShootCMSLodging', func.textEscape($("#reasonNotShoot_cms_lodging").val()), ''])
    store.commit('setValue', ['cmsLodging', 'inputContractReasonCMSLodging', func.textEscape($('#contractReason_cms_lodging').val()), ''])
    store.commit('setValue', ['cmsLodging', 'inputPersnalityCMSLodging', func.textEscape($('#persnality_cms_lodging').val()), ''])
    store.commit('setValue', ['cmsLodging', 'inputConv', func.textEscape($('#conv_cms_lodging').val()), ''])
    store.commit('setValue', ['cmsLodging', 'inputHopeCMSLodging', func.textEscape($('#hope_cms_lodging').val()), ''])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎CMS（宿泊型番）
動画撮影：${store.state.formData.cmsLodging.input_cms_lodging_should_movie}
サイト制作数：${store.state.formData.cmsLodging.input_cms_lodging_products_num}
掲載希望日時：${store.state.formData.cmsLodging.input_cms_lodging_shootDate}
型番：${store.state.formData.cmsLodging.input_cms_lodging_version}
お客様共有事項：${store.state.formData.cmsLodging.inputSharedMattersCMSLodging}
撮影有無：${store.state.formData.cmsLodging.inputShouldShootCMSLodging}
撮影無し理由：${store.state.formData.cmsLodging.inputReasonNotShootCMSLodging}
契約動機：${store.state.formData.cmsLodging.inputContractReasonCMSLodging}
顧客人柄：${store.state.formData.cmsLodging.inputPersnalityCMSLodging}
お客様との会話内容：${store.state.formData.cmsLodging.inputConv}
顧客希望：${store.state.formData.cmsLodging.inputHopeCMSLodging}
`

    const contactValue = document.getElementById("contact_cms_lodging").value
    let contactInformation = ""
    if (contactValue) {
      contactInformation = `連絡先情報備考：${contactValue}`
    }

    sendVal = sendVal + contactInformation + '\n'
    store.commit('setTalknoteMessage', ['cmsLodging', sendVal])
  }

  actionInit() {
    const $ = this.that.$

    $("#form_ap").on("click", '[name="shoot_yesNo_cms_lodging"]', function() {

      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "shoot_yes_cms_lodging":
          $("#fildReasonNotShoot_cms_lodging").slideUp()
          $("#reasonNotShoot_cms_lodging").removeClass("required")
          $("#reasonNotShoot_cms_lodging").val("")
          break;

        case "shoot_no_cms_lodging":
          $("#fildReasonNotShoot_cms_lodging").slideDown()
          $("#reasonNotShoot_cms_lodging").addClass("required")
          break;
      }

    })
  }
}

export default {
  afrPostCMSLodging
}