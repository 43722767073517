
/*
* ぽちボタンクリック後の処理
*/ 

class afrPostItzaiLite {
  constructor(that) {
    this.that = that
    this.formName = "イツザイ ライト"
    const $ = this.that.$
    const store = this.that.store
    
    if(store.state.industryNamesData.length == 0) {
      this.that.vueObj.viewLoader('業界リスト読み込み中')
    }
    
    let i = 0
    const sit = setInterval(() => {
      
      if(store.state.industryNamesData.length > 0) {
        clearInterval(sit)
        this.that.vueObj.viewLoader()

        // データ出力先有無をチェック
        if($('#industry_names_lite')[0]) {
          const industry_records = store.state.industryNamesData
          
          for(let i = 0; i < industry_records.length; i++) {
            let record = industry_records[i]
            let option = $('<option>')

            option.attr({
              id: 'jobsCategoriesID' + record.id,
              value: record.name,
              'data-id': record.id
            }).text(record.name)
            
            $('#industry_names_lite').append(option).show()
          }

        }

      } else {
        i++
        if(i >= 200) {
          clearInterval(sit)
          alert('業界リストを取得できませんでした\nリロードします')
          window.location.reload()
        }
      }
      
    }, 50)


    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    // 値取得：制作前共有
    store.commit('setValue', ['itzaiLite', 'inputShouldMovie', $('[name="movie_yesNo_lite"]:checked').val(), ''])
    store.commit('setValue', ['itzaiLite', 'inputRctJobCate', func.textEscape($('#rctJobCate_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputModuleNum', func.textEscape($('#moduleNum_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputCompensation', $('[name="compensation_yesNo_lite"]:checked').val(), ''])
    store.commit('setValue', ['itzaiLite', 'inputOtherOp', func.textEscape($('#otherOp_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputSharedMatters', func.textEscape($('#sharedMatters_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputShouldShoot', $('[name="shoot_yesNo_lite"]:checked').val(), ''])
    store.commit('setValue', ['itzaiLite', 'inputContractReason', func.textEscape($('#contractReason_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputPersnality', func.textEscape($('#persnality_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputConv', func.textEscape($('#conv_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputConvItzaiLite', func.textEscape($('#convItzai_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputConvService', func.textEscape($('#convService_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputShootDate', func.textEscape($('#shootDate_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputReasonNotShoot', func.textEscape($("#reasonNotShoot_lite").val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputRctBackground', func.textEscape($('#rctBackground_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputOtherHope', func.textEscape($('#otherHope_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputSharedOpTeams', func.textEscape($('#sharedOpTeams_lite').val()), ''])
    store.commit('setValue', ['itzaiLite', 'inputJobOfferCampaign', func.textEscape($('[name="guide_job_offer_campaign_lite"]:checked').val()), ''])

    // v2.0.1
    store.commit('setValue', ['itzaiLite', 'inputContactCEOName', $("#contactCEOName_lite").val(), 'contact_ceo_name_lite'])
    store.commit('setValue', ['itzaiLite', 'inputJobCnt', $("#job_cnt_lite").val(), ''])
    store.commit('setValue', ['itzaiLite', 'inputPackedCnt', $('#packed_cnt_lite').val(), '']) 
    store.commit('setValue', ['itzaiLite', 'inputIndustryName', $('#industry_names_lite').val(), 'industry_name_lite'])
    store.commit('setValue', ['itzaiLite', 'inputIndustryDataId', $('#industry_names_lite option:selected').data("id"), 'industry_data_id_lite'])

  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎イツザイ ライト
動画撮影：${store.state.formData.itzaiLite.inputShouldMovie}
パッケージ数：${store.state.formData.itzaiLite.inputPackedCnt}
サイト制作数：${store.state.formData.itzaiLite.inputJobCnt}
業界：${store.state.formData.itzaiLite.inputIndustryName}
募集職種名：${store.state.formData.itzaiLite.inputRctJobCate}
追加モジュール数：${store.state.formData.itzaiLite.inputModuleNum}
補償オプション：${store.state.formData.itzaiLite.inputCompensation}
その他オプション：${store.state.formData.itzaiLite.inputOtherOp}
お客様共有事項：${store.state.formData.itzaiLite.inputSharedMatters}
撮影有無：${store.state.formData.itzaiLite.inputShouldShoot}
撮影無し理由：${store.state.formData.itzaiLite.inputReasonNotShoot}
契約動機：${store.state.formData.itzaiLite.inputContractReason}
顧客人柄：${store.state.formData.itzaiLite.inputPersnality}
お客様との会話内容：${store.state.formData.itzaiLite.inputConv}
イツザイに載せる際に話した内容：${store.state.formData.itzaiLite.inputConvItzaiLite}
サービスについて伝えた事：${store.state.formData.itzaiLite.inputConvService}
掲載希望日時：${store.state.formData.itzaiLite.inputShootDate}
求人背景：${store.state.formData.itzaiLite.inputRctBackground}
その他顧客希望：${store.state.formData.itzaiLite.inputOtherHope}
運用への共有事項：${store.state.formData.itzaiLite.inputSharedOpTeams}
求人ボックスキャンペーン対応：${store.state.formData.itzaiLite.inputJobOfferCampaign}
`
    const contactValue = document.getElementById("contact_itzai_lite").value
    let contactInformation = ""
    if (contactValue) {
      contactInformation = `連絡先情報備考：${contactValue}`
    }

    sendVal = sendVal + contactInformation + '\n'
    store.commit('setTalknoteMessage', ['itzaiLite', sendVal])
  }

  actionInit() {
    const $ = this.that.$
    
    $("#form_ap").on("click", '[name="shoot_yesNo_lite"]', function() {
      
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "shoot_yes_lite":
          $("#fildReasonNotShoot_lite").slideUp()
          $("#reasonNotShoot_lite").removeClass("required")
          $("#reasonNotShoot_lite").val("")
          break;
      
        case "shoot_no_lite":
          $("#fildReasonNotShoot_lite").slideDown()
          $("#reasonNotShoot_lite").addClass("required")
          break;
      }
      
    })
  }

}

export default {
  afrPostItzaiLite
}