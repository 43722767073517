/*
* ぽちボタンクリック後の処理
*/ 

class afrPostCMS {
  constructor(that) {
    this.that = that
    this.formName = "CMS"    
    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    //制作前共有
    store.commit('setValue', ['cms', 'input_cms_should_movie', $('input[name="cms_should_movie"]:checked').val(), 'cmsShouldMovie'])
    store.commit('setValue', ['cms', 'input_cms_products_num', $("#cmsProductsNum").val(), 'cmsProductsNum'])
    store.commit('setValue', ['cms', 'input_cms_shootDate', $("#shootDate_cms").val(), 'shootDate_cms'])
    store.commit('setValue', ['cms', 'input_cms_version', func.textEscape($("#cmsVersion").val()), 'cmsVersion'])
    store.commit('setValue', ['cms', 'inputSharedMattersCMS', func.textEscape($('#sharedMatters_cms').val()), ''])
    store.commit('setValue', ['cms', 'inputShouldShootCMS', $('[name="shoot_yesNo_cms"]:checked').val(), ''])
    store.commit('setValue', ['cms', 'inputReasonNotShootCMS', func.textEscape($("#reasonNotShoot_cms").val()), ''])
    store.commit('setValue', ['cms', 'inputContractReasonCMS', func.textEscape($('#contractReason_cms').val()), ''])
    store.commit('setValue', ['cms', 'inputPersnalityCMS', func.textEscape($('#persnality_cms').val()), ''])
    store.commit('setValue', ['cms', 'inputConv', func.textEscape($('#conv_cms').val()), ''])
    store.commit('setValue', ['cms', 'inputHopeCMS', func.textEscape($('#hope_cms').val()), ''])

  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎CMS
動画撮影：${store.state.formData.cms.input_cms_should_movie}
サイト制作数：${store.state.formData.cms.input_cms_products_num}
掲載希望日時：${store.state.formData.cms.input_cms_shootDate}
型番：${store.state.formData.cms.input_cms_version}
お客様共有事項：${store.state.formData.cms.inputSharedMattersCMS}
撮影有無：${store.state.formData.cms.inputShouldShootCMS}
撮影無し理由：${store.state.formData.cms.inputReasonNotShootCMS}
契約動機：${store.state.formData.cms.inputContractReasonCMS}
顧客人柄：${store.state.formData.cms.inputPersnalityCMS}
お客様との会話内容：${store.state.formData.cms.inputConv}
顧客希望：${store.state.formData.cms.inputHopeCMS}
`

    const contactValue = document.getElementById("contact_cms").value
    let contactInformation = ""
    if (contactValue) {
      contactInformation = `連絡先情報備考：${contactValue}`
    }
    sendVal = sendVal + contactInformation + '\n'
    store.commit('setTalknoteMessage', ['cms', sendVal])
  }

  actionInit() {
    const $ = this.that.$

    $("#form_ap").on("click", '[name="shoot_yesNo_cms"]', function() {

      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "shoot_yes_cms":
          $("#fildReasonNotShoot_cms").slideUp()
          $("#reasonNotShoot_cms").removeClass("required")
          $("#reasonNotShoot_cms").val("")
          break;

        case "shoot_no_cms":
          $("#fildReasonNotShoot_cms").slideDown()
          $("#reasonNotShoot_cms").addClass("required")
          break;
      }

    })
  }
}

export default {
  afrPostCMS
}