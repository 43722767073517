/*
* ぽちボタンクリック後の処理
*/ 
class afrPostEC {
  constructor(that) {
    this.that = that
    this.formName = "EC"

    this.that.$('.search_form_wrap').show()
    //this.that.$('.dateUnnecessary').addClass('required')
    // this.that.store.commit('setValue', ['formType','ec'])
    // this.that.store.commit('delValue')

  }

  returnFormName() {
    return this.formName
  }

  getInputData () {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    // 値取得：EC
    store.commit('setValue', ['ec', 'input_ec_version', func.textEscape($("#ecVersion").val()), ''])
    store.commit('setValue', ['ec', 'inputSharedMattersEC', func.textEscape($('#sharedMatters_ec').val()), ''])
    store.commit('setValue', ['ec', 'inputContractReasonEC', func.textEscape($('#contractReason_ec').val()), ''])
    store.commit('setValue', ['ec', 'inputPersnalityEC', func.textEscape($('#persnality_ec').val()), ''])
    store.commit('setValue', ['ec', 'inputConvEC', func.textEscape($('#conv_ec').val()), ''])
    store.commit('setValue', ['ec', 'inputHopeEC', func.textEscape($('#hope_ec').val()), ''])

    store.commit('setValue', ['ec', 'inputProductsNum', func.textEscape($('#productsNum').val()), 'ec_products_num'])
    store.commit('setValue', ['ec', 'inputECSiteProductsNum', func.textEscape($('#ecSiteProductsNum').val()), 'ec_products_num'])
    store.commit('setValue', ['ec', 'input_ec_shootDate', func.textEscape($('#shootDate_ec').val()), 'shootDate_ec'])
    store.commit('setValue', ['ec', 'inputECSholdShoot', $('[name="shoot_yesNo_ec"]:checked').val(), 'ec_shold_shoot'])
    store.commit('setValue', ['ec', 'inputECProductsNum', func.textEscape($('#ecProductsNum').val()), 'ec_products_num'])
    store.commit('setValue', ['ec', 'inputECCutNum', func.textEscape($('#ecCutNum').val()), 'ec_cut_num'])

    const inputEcShootingAdd = $('[name="shootLoca"]:checked').val() !== undefined ? $('[name="shootLoca"]:checked').val() : ''
    store.commit('setValue', ['ec', 'inputECShootingAdd', inputEcShootingAdd, 'ec_shooting_add'])
    
    store.commit('setValue', ['ec', 'inputECPaymentMethod', 
    $('[name="ecPaymentMethod"]:checked').val(), 'ec_payment_method'])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎EC
型番：${store.state.formData.ec.input_ec_version}
お客様共有事項：${store.state.formData.ec.inputSharedMattersEC}
契約動機：${store.state.formData.ec.inputContractReasonEC}
顧客人柄：${store.state.formData.ec.inputPersnalityEC}
お客様との会話内容：${store.state.formData.ec.inputConvEC}
顧客希望：${store.state.formData.ec.inputHopeEC}
商品登録点数：${store.state.formData.ec.inputProductsNum}
サイト制作数：${store.state.formData.ec.inputECSiteProductsNum}
掲載希望日時：${store.state.formData.ec.input_ec_shootDate}
商品撮影：${store.state.formData.ec.inputECSholdShoot}
撮影商品数：${store.state.formData.ec.inputECProductsNum}
撮影カット数：${store.state.formData.ec.inputECCutNum}
撮影場所：${store.state.formData.ec.inputECShootingAdd}
EC導入決済(コンビニ or 定期利用)：${store.state.formData.ec.inputECPaymentMethod}
`

    const contactValue = document.getElementById("contact_ec").value
    let contactInformation = ""
    if (contactValue) {
      contactInformation = `連絡先情報備考：${contactValue}`
    }

    sendVal = sendVal + contactInformation + '\n'
    store.commit('setTalknoteMessage', ['ec', sendVal])
  }
}

export default {
  afrPostEC
}