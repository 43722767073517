class apoReport {
  constructor(vueObj, $, func, moment) {

    try {
      // Vueインスタンスを取得
      this.vueObj = vueObj

      this.store = vueObj.store

      this.isProd = this.vueObj.isProd

      // jQueryインスタンスを取得
      this.$ = $

      // フォーム関連関数を取得
      this.func = func

      // momentインスタンスを取得
      this.moment = moment

      // propsを取得
      //this.props = this.vueObj.getProps()
      this.thisFormParams = vueObj.thisFormParams

      // 投稿先slackチャンネル情報
      this.slackChannel = vueObj.slackChannel
      this.demo = this.isProd ? "" : "Demo"
      this.key = this.store.state.apis[`loginKey${this.demo}`]

      // 案件ID検索とか理由カテゴリ取得するAPI
      this.getDataApiURL = this.store.state.apis.getDataApiURL

      // slack チャンネル URL
      this.groupURL = this.slackChannel.workSpaceURL + this.slackChannel.id 

    
      //案件ID検索するAPI
      this.searchSubjectsIDApiURL = this.store.state.apis.searchSubjectsIDApiURL

      // フォームタイトル
      this.groupTitle = this.thisFormParams[0].replace(/\n/gi,"").replace(/\t/gi,"")

      // talknoteの投稿先アドレス
      // this.talknoteGroupAddress = this.thisFormParams[2].replace(/\n/gi,"").replace(/\t/gi,"")

      // 変数定義
      this.CHECKED_COLOR = "teal lighten-2"
      this.NOW_URL = location.href
      this.inputType = ''
      this.inputComName = ''
      this.inputMtgDate = ''
      this.inputMtgDay = ''
      this.inputMtgEndTime = ''
      this.inputMtgStartTime = ''
      this.inputDuration = ''
      this.inputCeoName = ''
      this.inputMtgPerson = ''
      this.inputGender = ''
      this.inputSuggestion = ''
      this.inputApName = ''
      this.inputEvaluation = ''
      this.inputResult = ''
      this.inputContactDeadline = ''
      this.inputContactDeadlineDay = ''
      this.inputSalesPrice = ''
      this.inputPayStatus = ''
      this.inputPaid = ''
      this.inputBalance = ''
      this.inputBalanceDeadline = ''
      this.inputBalanceDeadlineDay = ''
      this.inputpaymentMethod = ''
      this.inputEvaluationReason = ''
      this.inputDetail = ''
      this.inputMtgDetail = ''
      this.inputFBGood = ''
      this.inputFBMore = ''
      this.inputCostomerEmail = ''
      this.inputSubjectId = ''
      this.inputRemark = ''
      this.inputRemarks = ''
      this.inputResultId = ''
      this.inputSuggestionIds = ''
      this.inputMtgPersonId = ''
      this.inputRecord = ''

      // [残額期日]
      this.today = moment()
      this.today = this.today.format('YYYY-MM-DD')

      this.dataIDs = {
        user_sg_id: '',
        negotiation_result: '',
        suggestion_id: '',
        positions_id: '',
        gender_id: '',
        payment_status_id: '',
        ap_evaluation: ''
      }

      // 初期化
      this.init()

    } catch(e) {
      console.error(e.stack, e.message)

      let text = []

      if(!this.vueObj) {
        text.push('Vueインスタンスが取得できませんでした。')
      }

      if(!this.$) {
        text.push('jQueryが取得できませんでした。')
      }

      if(text.length == 0) {
        alert('予期せぬエラーが発生しました。')
      } else {
        alert(text.join('\n'))
      }

      return false
    }

  }

  init() {
    const $ = this.$;

    $('#BalanceDeadline, #ContactDeadline').attr('min', this.today)

    $('.autoG,.gotAP').css('display', 'none')

    $('.is_evaluation_F').hide()

    this.actionInit()
  }

  actionInit() {
    const that = this
    const $ = this.$
    const func = this.func
    const moment = this.moment
    const officeData = new OfficeDatas()

    /****************************************
     * アクション定義
    *****************************************/

    /* 
      ▼案件IDフォーマット
      AP[グループID]_[投稿ミリ秒]
      TW[グループID]_[投稿ミリ秒]
      OT[グループID]_[投稿ミリ秒]
      SL[グループID][T or O]_[投稿ミリ秒]
      └ 投稿者の所属エリアにより、T(Tokyo)orO(Osaka)で決まる
    */

    // 案件ID検索
    $('#SubjectId').off('input.search').on('input.search', function(e) {
      let _this = $(this)

      const subject_id = _this.val()
      let textCount = 22

      if(subject_id.indexOf('_') == 13) {
        textCount = 26
      }

      let before = _this.data('subject_id')

      if(subject_id.indexOf('sungrove') == 0) {
        textCount = 11
      } else {
        $('#wrap2, .autoG, .gotAP, .form_wrap').slideUp()
      }

      if(subject_id.length < textCount || (before && before != subject_id) || e.originalEvent.inputType.indexOf("delete") == 0) {
        $('#status_comment').text('')
        _this.data('subject_id', '')
        before = ""

        if($('.form_wrap').eq(0).css('display') == 'block') {
          $('.auto').prop('readonly', true);
          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)
        }

        return false
      }

      

      const result = subject_id.match(/(^[A-Z]{2}[0-9A-Z]{11}?_[0-9]{13}$|^[A-Z]{2}[0-9]{6}[A-Z]?_[0-9]{13}$|^sungrove[123])/)
      
      if(!result) {
        if(subject_id.indexOf('sungrove') > -1) {
          $('#SubjectId').val(subject_id)
        } 

        //alert('案件IDを正しく入力してください。')

        //$('#SubjectId').val('')

        return false
      }
      
      // 入力されたら
      if($('#status_comment').text().indexOf('検索中') == -1) {
        $('.events').remove()

        if(subject_id.startsWith('sungrove') == false) {      

          const reg1 = /^[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]|[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]$/
          const reg2 = /[\u0009-\u000d\u001c-\u0020\u11a3-\u11a7\u1680\u180e\u2000-\u200f\u202f\u205f\u2060\u3000\u3164\ufeff\u034f\u2028\u2029\u202a-\u202e\u2061-\u2063\ufeff]/g
          $('#SubjectId').attr('readonly', true)
          $('#MtgStartTime, #MtgEndTime, #MtgDate').attr('readonly', true)

          if(reg1.test(subject_id)) subject_id = subject_id.replace(reg2, "")

          $('#btn').addClass('disabled').removeClass('pulse')
          $('.auto').val('')
          $('#status_comment').text('検索中...🔎 少々お待ちください😣').css('color', '#757575')
          // Loading表示
          $('#loading_form').css('display', 'block')

          // 案件ID検索APIに投げる
          console.log(new Date())

          // 案件ID検索APIに投げるGAS API URL
          //let searchApiURL = that.getDataApiURL + '?type=searchSubjectId&SubjectId=' + subject_id
          let searchApiURL = that.store.state.apis.searchSubjectsIDApiURL + subject_id
          //let searchApiURL = that.searchSubjectsIDApiURL + subject_id

          that.formClearFlag = 1
          _this.trigger('input.clear')
          _this.val(subject_id)

          // Loading表示
          that.vueObj.viewLoader('検索中')
          
          // 案件ID検索開始
          fetch(searchApiURL)
          .then(response => response.json())
          .then(response => {

             // Loading非表示
            that.vueObj.viewLoader()
            let responsePrevious = response.filter(item => item.description && item.description.indexOf("掘り起こし元ID：" + subject_id) !== -1)
            let newID = ""
            if (responsePrevious.length > 0) {
              newID = responsePrevious[0].description.split("案件ID：")[1].split("\n")[0]
              newID = "掘り起こし後のID候補：" + newID
              alert("掘り起こし済みIDです！ IDを確認してください！")
              response = []
            } else {
              response = response.filter(item => item.description && item.description.indexOf("案件ID：" + subject_id) !== -1)
            }
            console.log(response)

            const openAction = () => {
              $('.form_wrap').slideDown()
              $('select').css('display', 'block')
              $('#SubjectId').attr('readonly', false).data('subject_id', subject_id)
              $('#loading_form').css('display', 'none')
              $('#status_comment').text('')
              $('#MtgDate, #MtgStartTime, #MtgEndTime, #ComName').val('')
            }

            let result_CalID = func.checkCalID(response)

            if(result_CalID !== 99) {
              that.del_flag = 1
              that.result_del = response.splice(result_CalID, 1)
              that.del_cal_id = that.result_del[0].cal_id
              that.del_evnet_id = that.result_del[0].event_id
              that.del_desc = that.result_del[0].description
            }

            if(response.length == 1) {
              openAction()

              $('.comInfo, .mtg, .reasons, .mtgDetail').slideDown()
              $('#status_comment').text('対象の案件を見つけました👀')

              let resTitle = response[0].title
              let exchangeEle = ''
              that.inputCalName = response[0].cal_name
              that.resCalId = response[0].cal_id
              that.resEventId = response[0].event_id
              that.resMtgAdd = response[0].location
              that.inputApName = response[0].ap_name
 
              const regSaihou = /\[.*再.*\]/
              const errorText = func.titlePattern(resTitle, regSaihou)
              if (errorText) {
                $('#status_comment').html(errorText).css({'color': 'red', 'font-size': '16px'})
              }

              const isIncludeSaihouCal = officeData.checkIncludeSaihouCal(that.resCalId)
              const isRescanCal = officeData.checkRescanCal(that.resCalId)

              if(resTitle.indexOf('///*') !== -1 || resTitle.indexOf('*///') !== -1) {
                exchangeEle = func.v2Title(response[0])
              } else if (isIncludeSaihouCal || (isRescanCal && regSaihou.test(resTitle))) {
                exchangeEle = func.saihou(response[0])
                that.inputSaihou = '(再訪)'
              } else if(resTitle.indexOf('///') !== -1) {
                exchangeEle = func.v1Title(response[0])
              }
              
              // 項目自動入力
              let date = exchangeEle.mtg_date.split('（')[0].replace(/\//g, '-')
              let start = exchangeEle.mtg_date.split('）')[1].split('〜')[0]
              let end = exchangeEle.mtg_date.split('）')[1].split('〜')[1]
              $('#MtgDate').val(date)
              $('#MtgStartTime').val(start)
              $('#MtgEndTime').val(end)
              $('#ComName').val(exchangeEle.com_name)
              $('#apName').val(exchangeEle.ap_name)
              $('#MtgDate, #ComName').attr('readonly', true)
              $('#MtgStartTime, #MtgEndTime').attr('readonly', false)

              let startIdName = 'MtgStartTime' 
              let endIdName = 'MtgEndTime'
              func.startEndTime(startIdName, endIdName)

              if(exchangeEle.ap_type == '再訪') {
                $('#typeRevisit').prop('checked', true)
                $('#wrapEvaluationReason, #wrapFB').slideUp(500)
              } else {
                $('#typeNew').prop('checked', true)
                $('#wrapEvaluationReason, #wrapFB').slideDown(500)
                $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F, #chargeName, input[name="product"]').addClass('required') 
              }

            } else if(response.length >= 1) {
              openAction()

              $('#status_comment').text('複数のカレンダーが見つかりました。該当カレンダーのタイトルを選択してください。').css('color', '#29b6f6')
              $('#SubjectId').attr('readonly', false)
              func.createSubjectList(response)

            } else {
              $('#SubjectId').attr('readonly', false)
              $('.auto').attr('placeholder', '')
              $('#status_comment').html('もう一度IDを確認して検索してください。<br>' + newID).css('color', '#e91e63')
            }
          })
          .catch((e) => {
            console.error(e.stack, e.message)
          })

          return true

        } else if(subject_id == 'sungrove123') {
          $('select').css('display', 'block')
          $('.item, .form_wrap').slideDown()
          $('#ComName, #MtgDate, #MtgStartTime, #MtgEndTime').attr('readonly', false)
          $('#status_comment').text('案件IDがない案件の為、手動入力モードに切り替えました📝').css('color', '#e91e63')

          let startIdName = 'MtgStartTime' 
          let endIdName = 'MtgEndTime'
          func.startEndTime(startIdName, endIdName)
        }
      } 
    })

    // [案件IDクリア]
    $('#SubjectId').off('input.clear').on('input.clear', function() {
      const subject_id = $('#SubjectId').val()

      if(subject_id == "" || that.formClearFlag == 1) {
        that.formClearFlag = 0
        $('.comInfo, .mtg, .tuikyaku, .saledInfo, .reasons, .mtgDetail').slideUp()
        $('.events').remove()
        $('#status_comment').text('案件IDを入力すると検索開始します').css('color', '#757575')

        func.clearInput($('input[type="text"], input[type="Number"], input[type="url"], .form_txt'))   
        func.checkOut($('.form_check, .required'))
        func.clearSelect($('.form_select'))

        let fild_email = $('.fild_email').clone(true)
        $('.fild_email_list').empty().append(fild_email.eq(0)) 

      }
    })
   
    // [案件ID検索結果]
    $(document).off('click.ListEvents').on('click.ListEvents', '[name="ListEvents"]', function() {
      $('.comInfo, .mtg, .reasons, .mtgDetail').slideDown()
      const dataAbout = $(this).data('about')
      const dataRes = $(this).data('res')

      that.inputComName = dataAbout.company_name
      that.inputMtgGDate = dataAbout.mtg_date
      that.inputApName = dataAbout.ap_name
      that.inputApTypeName = dataAbout.ap_type_name.replace('🎦', 'オンライン')
      that.inputType = dataRes.ap_type

      let date = that.inputMtgGDate.split('（')[0].replace(/\//g, '-')
      let start = that.inputMtgGDate.split('）')[1].split('〜')[0]
      let end = that.inputMtgGDate.split('）')[1].split('〜')[1]
      
      $('#ComName').val(that.inputComName)
      $('#MtgDate').val(date)
      $('#MtgStartTime').val(start)
      $('#MtgEndTime').val(end)
      $('#MtgDate, #ComName').attr('readonly', true)
      $('#MtgStartTime, #MtgEndTime').attr('readonly', false)

      if (that.inputApName == 'undefined') that.inputApName = ''
      $('#apName').val(that.inputApName)

      let startIdName = 'MtgStartTime' 
      let endIdName = 'MtgEndTime'
      func.startEndTime(startIdName, endIdName)

      if(that.inputType == '再訪') {
        $('#typeRevisit').prop({'checked': true, 'disabled': true})
        $('#typeNew').prop({'disabled': true})
        $('#wrapEvaluationReason, #wrapFB').slideUp(500)
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F, #chargeName, input[name="product"]').removeClass('required') 
      } else if(that.inputType !== '再訪') {
        $('#typeNew').prop({'checked': true, 'disabled': true})
        $('#typeRevisit').prop({'disabled': true})
        $('#wrapEvaluationReason, #wrapFB').slideDown(500)
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F, #chargeName, input[name="product"]').addClass('required') 
      }
    })

    // カレンダー
    $('#MtgDate, #ContactDeadline, #BalanceDeadline').off('click.MtgDate').on('click.MtgDate', function() {
      let _this = $(this)
      let date = _this.val()
      let current = date && date.match(/[0-9]{4}\/[0-9]{2}\/[0-9]{2}/)? date: moment().toDate()
      /*
      _this.datepicker("dialog", current, function(date) {
        _this.val(date)
      }, {
        dateFormat : 'yy/mm/dd',
        monthNames: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthNamesShort: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        dayNamesMin: ['日', '月', '火', '水', '木', '金', '土'],
        dayNamesShort: ['日', '月', '火', '水', '木', '金', '土'],
      })*/
    })

    // 増減ボタン
    $('#form_ap').off("click.form_add").on("click.form_add", ".form_add", function() {
      let clone = $(this).closest('.c-variables__item').clone(true)
      clone.find('.-ok').remove()
      clone.insertAfter($(this).closest('.c-variables__item')).children('.emails').val('')
    });

    $(document).off("click.form_del").on("click.form_del", ".form_del", function() {
      let target = $(this).closest('.c-variables__item');
      if (target.parent().children().length > 1) {
        target.remove();
      }
    });

    // [種別]
    $('#form_ap').off('click.apoType').on('click.apoType', '[name="type"]', function() {
      let typeVal = func.checkedVal($('[name="type"]'))
      $('#Detail, #FB').val('')

      if(typeVal == '新規') {
        $('#wrapEvaluationReason, #wrapFB').show()
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F').addClass('required')
      } else if(typeVal !== '新規') {
        $('#wrapEvaluationReason, #wrapFB').hide()
        $('#FB, #Detail, .chip, #FBGood, #FBMore, #evaluation_F').removeClass('required')
      }
    })

    // [提案内容：アポ評価理由追加]
    $('input#ituzai, input#ec, input#cms').off('click.apoReview').on('click.apoReview', function() {
      const ituzai_dom = $('input#ituzai:checked')
      const ec_dom = $('input#ec:checked')
      const cms_dom = $('input#cms:checked')
      $('#saiyouiyoku_ok, #syuukyaku_ok').removeClass('groupA')

      if(ituzai_dom.length == 1) {
        $('#saiyouiyoku_ok').show(500)
        $('#saiyouiyoku_ok').addClass('groupA')
      } else if(ituzai_dom.length != 1) {
        $('#saiyouiyoku_ok').hide(500)
        $('#saiyouiyoku_ok').removeClass(that.CHECKED_COLOR)
      }

      if(ec_dom.length == 1 || cms_dom.length == 1) {
        $('#syuukyaku_ok').show(500)
        $('#syuukyaku_ok').addClass('groupA')
      } else if(ec_dom.length != 1 && cms_dom.length != 1) {
        $('#syuukyaku_ok').hide(500)
        $('#syuukyaku_ok').removeClass(that.CHECKED_COLOR)
      }
    })

    //[商談結果：表示非表示]
    $('#Result').off('change.result').on('change.result', function() {
      const Result_ele = $('#Result')
      $('#ContactDeadline, #ContactDeadlineDay, #RevisitedStartTime, #PayStatus, #SalesPrice, #Paid, #Balance, #BalanceDeadline, #paymentMethod').val('')
      $('.markResult').css('display', 'none')
      $('.markResult').removeClass('markResult')
      $('#ContactDeadline, #PayStatus, #SalesPrice, #Paid, #Balance, #BalanceDeadline, #paymentMethod').removeClass('required')
      $('#notPaid').slideUp(100)

      if(Result_ele.val() == '追客A' || Result_ele.val() == '追客B' || Result_ele.val() == '追客C') {
        $('#payInfo').hide()
        $('.tuikyaku').slideDown()
        $('.tuikyaku').addClass('markResult')
        $('#ContactDeadline').addClass('required')
      } else if (Result_ele.val() == '受注' || Result_ele.val() == '受注済み決済' || Result_ele.val() == '受注済み打ち合わせ' || Result_ele.val() == '即決') {
        $('#payInfo').show()
        $('#PayStatus, #SalesPrice, #Paid, #Balance, #paymentMethod').addClass('required')
        $('.saled').slideDown()
        $('.saled').addClass('markResult')
      } else if (Result_ele.val() == '失注' || Result_ele.val() == '現場リスケ' || Result_ele.val() == '現場キャンセル') {
        $('#payInfo').hide()
      }
    })

    // [決済進捗]
    $('#PayStatus').off('change.PayStatus').on('change.PayStatus', function() {
      const Paystatus_ele = $('#PayStatus')
      $('#SalesPrice, #Paid, #Balance').attr('readonly',true)
      $('#SalesPrice, #Paid, #Balance, #paymentMethod').val('')
      $('#notPaid, #autoCalculation').hide()
      $('#notPaid').val('')
      $('#BalanceDeadline, #paymentMethod').removeClass('required')
      $('.formPayment_option').css('display', 'none')

      if(Paystatus_ele.val() == '決済完了') {
        $('#SalesPrice').attr('readonly', false) // 受注金額有効
        $('#BalanceDeadline').val('') //残高期日初期化
        $('#Balance').val('0')
        $('#paymentMethod').val('')
      } else if(Paystatus_ele.val() == '一部決済') {
        // 受注金額・決済済み金額・残高有効
        $('#SalesPrice, #Paid, #Balance').attr('readonly', false)
        $('#notPaid').show()
        $('#BalanceDeadline').addClass('required')
        $('.formPayment_option').css('display', 'block')
        $('#paymentMethod').addClass('required')
      } else if(Paystatus_ele.val() == '未決済') {
        // 受注金額有効
        $('#SalesPrice, #Balance').attr('readonly', false)
        $('#notPaid').show()
        $('#BalanceDeadline').addClass('required')
        $('#Paid').val('0')
        $('.formPayment_option').css('display', 'block')
        $('#paymentMethod').addClass('required')
      }
    })

    // [受注金額・決済済み金額・残高]
    $('#SalesPrice, #Paid, #Balance').off('input.paid').on('input.paid', function() {
      const regNum = /[0-9]+/g
      let PayStatus_ele_input = $('#PayStatus').val()
      let sales_price = $('#SalesPrice').val() //受注金額
      let paid_price = $('#Paid').val()        //決済済み金額
      let balance_price = $('#Balance').val()  //残高
      sales_price = func.hankakusuji(sales_price)
      paid_price = func.hankakusuji(paid_price)
      balance_price = func.hankakusuji(balance_price)

      if (regNum.test(sales_price)) {
        if(PayStatus_ele_input == '決済完了' && sales_price !== "") {
          sales_price = func.commaStr2Num(sales_price)
          $('#Paid').val(sales_price)
          $('#SalesPrice').val(sales_price)
          $('#Balance').val('0')
        } else if(PayStatus_ele_input == '一部決済') {
          if(sales_price !== "") sales_price = func.commaStr2Num(sales_price)
          if(paid_price !== "") paid_price = func.commaStr2Num(paid_price)
          if(balance_price !== "") balance_price = func.commaStr2Num(balance_price)
  
          $('#Paid').val(paid_price)
          $('#SalesPrice').val(sales_price)
          $('#Balance').val(balance_price)
        } else if(PayStatus_ele_input == '未決済' && sales_price != "") {
          sales_price = func.commaStr2Num(sales_price)
          $('#SalesPrice').val(sales_price)
          $('#Balance').val(sales_price)
          $('#Paid').val('0')
        }
      } else {
        alert ('半角数字で入力お願いします。')
        $('#Paid, #SalesPrice, #Balance').val('')
      }
    })

    $('#last_suggestion_price').off('input.last_suggestion_price').on('input.last_suggestion_price', function() {
      const regNum = /[0-9]+/g
      let last_suggestion_price = $('#last_suggestion_price').val()
      last_suggestion_price = func.hankakusuji(last_suggestion_price)
      last_suggestion_price = func.commaStr2Num(last_suggestion_price)
      $('#last_suggestion_price').val(last_suggestion_price)
      if (regNum.test(last_suggestion_price) || last_suggestion_price == "") {
        console.log("問題なし")
      } else {
        alert ('半角数字で入力お願いします。')
        $('#last_suggestion_price').val('')
      }
    })

    // [アポ評価理由]
    $('.reasons_li').off('click.reasons').on('click.reasons', function() {
      const this_dom = $(this)

      if(this_dom.parent().hasClass(that.CHECKED_COLOR)) {
        this_dom.parent().removeClass(that.CHECKED_COLOR)
      } else {
        this_dom.parent().addClass(that.CHECKED_COLOR)
      }
    })

    //[必須項目判定]
    $('#form_ap').off('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired').on('change.checkRequired input.checkRequired click.checkRequired mousemove.checkRequired', function() {
      let resultRequiredArray = func.checkRequired()
      let required_NG = 0
      let check_required = 0

      for(let key in resultRequiredArray) {
        resultRequiredArray[key] == 0 ? required_NG = 1 : check_required = 1
      }

      if(check_required == 1 && required_NG == 0) {
        $('#btn').removeClass('disabled').addClass('pulse')
      }

    })

    //[顧客情報:非表示]
    $('#noEmail').off('click.noEmail').on('click.noEmail', function() {
      $('#CostomerEmail1, #CostomerEmail2, #CostomerEmail3').val('')
      if($('#noEmail:checked').length == 1) {
        $('.fild_email').slideUp(500)
      } else {
        $('.fild_email').slideDown(500)
      }
    })

    //[日付制限(遡り防止)]
    $('#ContactDeadline, #BalanceDeadline').change(function() {
      let formated_today = moment().format('YYYY-MM-DD')
      $('#ContactDeadlineCheck').text("")
      $('#BalanceDeadlineCheck').text("")

      if ($('#ContactDeadline').val() < formated_today && $('#ContactDeadline').val() != "") {
        $('#ContactDeadlineCheck').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#ContactDeadline').val("")
      }
      
      if ($('#BalanceDeadline').val() < formated_today && $('#BalanceDeadline').val() != "") {
        $('#BalanceDeadlineCheck').text('※ 本日以降の日付を入力してください').css('color', '#e91e63')
        $('#BalanceDeadline').val("")
      }
      
    })

    // [アポ評価条件分岐]
    const checkboxes = document.querySelectorAll('.evaluation_D_checkbox')
    let checkedProduct = []
    $('#start_evaluation').hide()

    // 商材で分岐
    $('input[name="product"]').click(function() {
      $('#start_evaluation').show()
      $('#evaluation_F').addClass('required')
      checkedProduct = $('input[name="product"]:checked').map(function() {
        return $(this).val()
      }).get()

      const index_welbox = checkedProduct.some(value => value === "welbox")
      const index_indeed = checkedProduct.some(value => value === "indeed")
      const element = document.getElementById('decision_11') //商材で分岐する質問の直前の質問

      if (element.value == "該当") {
        $('#last').addClass('required').val("")
        $('.last').show()
        $('#welbox, #indeed').removeClass('required').val("")
        $('.welbox, .indeed').hide()
        
        if (index_welbox) {
          $('#welbox').addClass('required')
          $('.welbox').show()
          $('#last').removeClass('required').val("")
          $('.last').hide()
        }
        if (index_indeed) {
          $('#indeed').addClass('required')
          $('.indeed').show()
          $('#last').removeClass('required').val("")
          $('.last').hide()
        }
      }

    })

    // F評価
    $('#evaluation_F').change(function() {
      if ($(this).val() == '該当') {
        $('.is_evaluation_F').hide()
        $('#evaluation_E').val("")
        $('.removeRequired').removeClass('required')
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
      } else {
        $('.column_evaluation_E').show()
        $('#evaluation_E').addClass('required')
      }
    })

    // E評価
    $('#evaluation_E').change(function() {
      if ($(this).val() == '該当') {
        $('.is_evaluation_E').hide()
        $('#is_evaluation_D').removeClass('required')
        $('.evaluation_D_checkbox').removeClass('required')
        $('.removeRequired').removeClass('required')
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
      } else {
        $('.column_evaluation_D').show()
        $('.is_evaluation_D').show()
        $('#is_evaluation_D').val("")
        $('#is_evaluation_D').addClass('required')
        $('.evaluation_D_checkbox').addClass('required')
      }
    })

    // D評価 該当非該当
    $('#is_evaluation_D').change(function() {
      $('#is_evaluation_D').removeClass('required')
      if ($(this).val() == '該当') {
        $('.column_evaluation_C').hide()
        $('#evaluation_question_1').val("")
        $('.is_evaluation_D').show()
        $('.evaluation_D_checkbox').addClass('required')

      } else {
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
        $('.is_evaluation_D').hide()
        $('.evaluation_question_1').show()
        $('#evaluation_question_1').addClass('required')
      }
    })

    // D評価 チェックボックス
    $('.evaluation_D_checkbox').change(function() {
      const isAnySelected = Array.from(checkboxes).some(checkbox => checkbox.checked)
      if (isAnySelected) {
        $('.column_evaluation_C').hide()
        $('#evaluation_question_1').val("")
        $('.removeRequired').removeClass('required')
        $('#is_evaluation_D').val("該当")
        $('#is_evaluation_D').removeClass('required')
        $('#is_evaluation_D').css('background-color', 'rgb(255, 255, 255)')
      } else {
        $('.evaluation_D_checkbox').addClass('required')
        $('#is_evaluation_D').val("")
        $('#is_evaluation_D').addClass('required')
      }
    })

    // C以上評価
    $('.evaluation_question_C').change(function() {
      const index_welbox = checkedProduct.some(value => value === "welbox")
      const index_indeed = checkedProduct.some(value => value === "indeed")
      that.inputEvaluation = 'C'
      $('.removeRequired').removeClass('required')
      $('.required_color.int_required_color').css('background-color', '')
      const selectedValue = $(this).val()
      const selectId = this.id
      const IDNumber = selectId.match(/\d+/)
      $('#last, #welbox, #indeed').removeClass('required')
      $('.last, .welbox, .indeed').hide()

      // 最終項目の表示切替
      if (selectId == "welbox" || selectId == "indeed") {
        if (index_welbox) { $('.welbox').show() }
        if (index_indeed) { $('.indeed').show() }
        if ($('#welbox').val()) { $('#welbox').addClass('required') }
        if ($('#indeed').val()) { $('#indeed').addClass('required') }
        if (index_welbox && index_indeed) {
          if ($('#welbox').val() == "該当" && $('#indeed').val() == "該当") {
            $('#last').addClass('required').val("")
            $('.last').show()
          }
        } else {
          if (selectedValue === "該当") {
            $('#last').addClass('required').val("")
            $('.last').show()
          }
        }
      }

      // 最後の評価項目による最終ジャッジ
      if (selectId === "last") {
        that.inputEvaluation = "B"
        $('.last').show()
        if (index_welbox) { $('.welbox').show() }
        if (index_indeed) { $('.indeed').show() }
        const is_question_3 = $('#evaluation_question_3').val() == "該当"
        const is_question_10 = $('#evaluation_question_10').val() == "該当"

        if (!is_question_3) { return } // 費用認識が無い場合B評価で確定

        if (selectedValue == "該当") {
          that.inputEvaluation = "S"
        } else if (is_question_10) {
          that.inputEvaluation = "A"
        }

        return
      }

      if (!IDNumber) { return } 

      let selectNumber = parseInt(IDNumber[0], 10)

      // 質問戻って選択した時の為、後の項目を非表示＆リセット
      $('.evaluation_question_C').each(function() {
        const id = this.id
        const matchResult  = id.match(/\d+/)
        if (!matchResult) { return } 
        const currentNumber = parseInt(matchResult[0], 10)
        if (currentNumber >= selectNumber && !selectId.includes("next")) {
          $('#decision_next_' + currentNumber).val("")
          $('.decision_next_' + currentNumber).hide()
        }

        if (currentNumber > selectNumber) {
          $('#evaluation_question_' + currentNumber).val("")
          $('.evaluation_question_' + currentNumber).hide()
          $('#decision_' + currentNumber).val("")
          $('.decision_' + currentNumber).hide()
        }

        if (selectId.includes('evaluation_question') && currentNumber == selectNumber) {
          $('#decision_' + currentNumber).val("")
          $('.decision_' + currentNumber).hide()
        }
      })

      // 各質問回答による次の質問表示切替
      if (selectedValue == "該当") {

        if (selectId == "decision_next_7") { //この質問は該当でも次へ進まない
          return
        }

        if (IDNumber == 11 || selectId == "welbox" || selectId == "indeed") { //選択商材による分岐
          if (index_welbox) {
            $('#welbox').addClass('required').val("")
            $('.welbox').show()
          }
          if (index_indeed) {
            $('#indeed').addClass('required').val("")
            $('.indeed').show()
          }
          if (!index_welbox && !index_indeed) {
            $('#last').addClass('required').val("")
            $('.last').show()
          }
          return
        }

        selectNumber = selectNumber + 1
        $('.evaluation_question_' + selectNumber).show()
        $('#evaluation_question_' + selectNumber).addClass('required')

        if (IDNumber == 3 || IDNumber == 5 || IDNumber == 7 || IDNumber == 10) { // 次の項目がdecisionのid（非該当で即C評価）の場合
          $('#decision_' + selectNumber).addClass('required')
        }

      } else if (selectedValue == "非該当") {

        if (selectId == "decision_next_7") { //この質問は次へ進まない
          that.inputEvaluation = 'D'
          return
        }

        if (selectId.includes('decision')) {
  
          if (selectId == "decision_7") { //この質問はさらに次の分岐へ進む
            $('#decision_next_' + selectNumber).addClass('required')
            $('.decision_next_' + selectNumber).show()
            return
          }

          if (IDNumber == 6) {
            that.inputEvaluation = 'D'
          }

        } else {
          $('#decision_' + selectNumber).addClass('required')
          $('.decision_' + selectNumber).show()
        }

      }
    })

     //送信用メッセ
    document.getElementById("btn").onclick = function exchange() {
      if($('#btn').hasClass('pulse')) {
        let confirmMsg  = "", 
        confirmPayInfo = ""
        that.inputComName = $.trim($('#ComName').val())
        that.inputMtgDate = $('#MtgDate').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')
        that.inputResult = $('#Result').val()
        that.inputpaymentMethod = $('#paymentMethod').val() == null ? '' : $('#paymentMethod').val()
        $('#PayStatus').val() == null ? that.inputPayStatus = '' : that.inputPayStatus = $('#PayStatus').val()

        var selectedValues = $('.evaluation_D_checkbox:checked').map(function() {
          return this.value
        }).get()

        // メアドチェック
        let mailCheckFlag = false
        $.each($('.emails'), (i, elm) => {
          const email = $('.emails').eq(i)
          const val = email.val()
          if(val && !val.match(/.+?@.+?/)) {
            email.val('')
            mailCheckFlag = true
          }
        })

        // 最終的なアポ評価ランクを取得
        const isAnySelected = Array.from(checkboxes).some(checkbox => checkbox.checked)
        if ($('#evaluation_F').val() == '該当') {
          that.inputEvaluation = 'F'
        } else if ($('#evaluation_E').val() == '該当') {
          that.inputEvaluation = 'E'
        } else if (isAnySelected) {
          that.inputEvaluation = 'D'
        }
    
        if($('#SalesPrice').val() != '') {
          that.inputSalesPrice = $('#SalesPrice').val() +"円"
          that.inputPaid = $('#Paid').val()+"円"
          that.inputBalance = $('#Balance').val()+"円"
          confirmPayInfo = "\n◎入金\n" + "決済進捗：" + that.inputSalesPrice + "\n" + "受注金額：" + that.inputSalesPrice + "\n" + "決済済み金額：" + that.inputPaid + "\n" + "残高：" + that.inputBalance + "\n" + "支払い方法：" + that.inputpaymentMethod
        }
        confirmMsg = "案件名：" + that.inputComName + "\n" + "商談結果：" + that.inputResult + "\n" + confirmPayInfo

        let checkPostFlg = window.confirm('下記内容で間違いないですか？必ず確認してください。\n\n'+confirmMsg)
      
        if(checkPostFlg) {
          // [値取得]
          that.inputGender = func.checkedVal($('[name="gender"]'))
          that.inputSuggestion = func.checkedVal($('[name="Suggestion"]'))
          that.inputMtgPerson =  func.checkedVal($('[name="MtgPerson"]'))
          that.inputContactDeadline = $('#ContactDeadline').val()
          that.inputRevisitedStartTime = $('#RevisitedStartTime').val()
          that.inputType = func.checkedVal($('[name="type"]'))
          that.inputDetail = $('#Detail').val()
          that.inputMtgDetail = $('#MtgDetail').val()
          that.inputFBGood = $('#FBGood').val()
          that.inputFBMore = $('#FBMore').val()
          that.inputCampaign = func.checkedVal($('[name="guide_campaign"]'))
          that.inputSubsidy = func.checkedVal($('[name="guide_subsidy"]'))
          that.inputJobOfferCampaign = func.checkedVal($('[name="guide_job_offer_campaign"]'))
          that.inputLastSuggestionPrice = $('#last_suggestion_price').val() == "" ? "" : $('#last_suggestion_price').val() + "円" 
          that.inputPasserName = $('#passer_name').val()
          that.inputCostomerEmail = func.getInputData($('.emails'))
          that.inputMtgStartTime = $('#MtgStartTime').val()
          that.inputMtgEndTime = $('#MtgEndTime').val()
          if(! that.inputSubjectId) that.inputSubjectId = $('#SubjectId').val()

          that.inputType == '新規' ? that.inputEvaluationReason = that.inputEvaluation : that.inputEvaluationReason = ''

          if(that.inputType == '再訪') that.inputEvaluation = ''
    
          // [整形]
          that.inputCeoName = func.textEscape($.trim($('#CeoName').val()))
          that.inputApName = func.textEscape($.trim($('#apName').val()))
          that.inputContactDeadline = $('#ContactDeadline').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')
          that.inputBalanceDeadline = $('#BalanceDeadline').val().replace(/(\d{4})-(\d{2})-(\d{2})/, '$1/$2/$3')

          let WeekChars = [ "日", "月", "火", "水", "木", "金", "土" ]
    
          if(that.inputMtgDate != '') {
            that.inputMtgDay = "（" + WeekChars[new Date(that.inputMtgDate).getDay()]+"）"
          }
  
          if(that.inputContactDeadline != '') {
            that.inputContactDeadlineDay = "（" + WeekChars[new Date(that.inputContactDeadline).getDay()] + "）"
          }
    
          if(that.inputBalanceDeadline != '') {
            that.inputBalanceDeadlineDay = "（" + WeekChars[new Date(that.inputBalanceDeadline).getDay()] + "）"
          }

          that.inputRemark = func.textEscape($('#Remark').val())
          that.inputRecord = $('#record_data').val()

          // [data-id取得]
          let tn_user_id = that.store.state.userData.userID
          that.dataIDs.subject_id = that.inputSubjectId
          that.dataIDs.user_sg_id = that.store.state.userData.id
          that.dataIDs.negotiation_result = $('#Result :selected').data('id')
          that.dataIDs.gender_id = $('[name="gender"]:checked').data('id')
          that.dataIDs.payment_status_id = $('#PayStatus option:selected').data('id')
          that.dataIDs.suggestion_id = func.getDataAttrAry($('[name="Suggestion"]:checked'), 'id')
          that.dataIDs.positions_id = func.getDataAttrAry($('[name="MtgPerson"]:checked'), 'id')
          that.dataIDs.ap_evaluation = that.inputEvaluation

          //[投稿画面バージョン]
          let Extension_version = that.store.state.version
          let group_id = that.slackChannel.id

          // talknote投稿
          ;(async function() {
        
            let tn_user_id = that.store.state.userData.userID

            let postID = func.getPostID(that.inputSubjectId, that.slackChannel.userID, group_id)

            let allText = {}
            allText.part01 = "◎案件情報 " + "[" + Extension_version + "]" +
            "\r\n案件ID：" + that.inputSubjectId +
            "\r\n案件名：" + that.inputComName +
            "\r\n代表者名：" + that.inputCeoName +
            "\r\nメールアドレス：" + that.inputCostomerEmail +
            "\r\n担当AP名：" +  that.inputApName +
            "\r\n" +
            "\r\n◎商談情報" +
            "\r\n種別：" + that.inputType +
            "\r\n商談日時：" + that.inputMtgDate + that.inputMtgDay + that.inputMtgStartTime + "〜" + that.inputMtgEndTime +
            "\r\n商談結果：" + that.inputResult +
            "\r\n回答期日：" + that.inputContactDeadline + that.inputContactDeadlineDay +
            "\r\n" +
            "\r\n提案内容：" + that.inputSuggestion +
            "\r\n商談相手：" + that.inputMtgPerson +
            "\r\n商談相手性別：" + that.inputGender +
            "\r\n" +
            "\r\n◎入金" +
            "\r\n決済進捗：" + that.inputPayStatus +
            "\r\n受注金額：" + that.inputSalesPrice +
            "\r\n決済済み金額：" + that.inputPaid +
            "\r\n残額：" + that.inputBalance +
            "\r\n残額期日：" + that.inputBalanceDeadline + that.inputBalanceDeadlineDay +
            "\r\n支払い方法：" + that.inputpaymentMethod +
            "\r\n" +
            "\r\n◎アポ評価" +
            "\r\n評価結果：" + that.inputEvaluation +
            // "\r\n理由："+inputEvaluationReason+
            "\r\n詳細：" + that.inputDetail +
            "\r\n\r\n" + "・他アポインターにも参考にしてほしいこと"+
            "\r\n" + that.inputFBGood +
            "\r\n" + "・改善点" +
            "\r\n" + that.inputFBMore +
            "\r\n" +
            "\r\n紹介キャンペーンの案内実施有無：" + that.inputCampaign +
            "\r\n助成金の案内の有無：" + that.inputSubsidy +
            "\r\n求人ボックスキャンペーンの案内の有無：" + that.inputJobOfferCampaign +
            "\r\n最終提案金額：" + that.inputLastSuggestionPrice +
            "\r\nパサー名前：" + that.inputPasserName +
            "\r\n"

            allText.part02 = "\r\n◎商談内容" +
            "\r\n" + that.inputMtgDetail +
            "\r\n◎備考" +
            "\r\n" + that.inputRemark +
            "\r\n◎音源データ" +
            "\r\n" + that.inputRecord +
            '\r\n' 

            allText.part03 = '-----------------------------' +
            '\r\n' + 'POST ID：' + postID +
            '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

      
            let toSpreadsheet = allText.part01 + allText.part02 + allText.part03 

            let toForwardPost = allText.part01  + allText.part03 

            // slack 投稿者にメンション
            const slackMention = `<@${that.slackChannel.userID}>\n`

            let toSlack =  slackMention + "◎案件情報 " + "[" + Extension_version + "]" +
            "\r\n案件ID：" + that.inputSubjectId +
            "\r\n担当AP名：" + that.inputApName +
            "\r\n" +
            "\r\n◎商談情報" +
            "\r\n種別：" + that.inputType +
            "\r\n商談日時：" + that.inputMtgDate + that.inputMtgDay + that.inputMtgStartTime + "〜" + that.inputMtgEndTime +
            "\r\n商談結果：" + that.inputResult +
            "\r\n回答期日：" + that.inputContactDeadline + that.inputContactDeadlineDay +
            "\r\n" +
            "\r\n提案内容：" + that.inputSuggestion +
            "\r\n商談相手：" + that.inputMtgPerson +
            "\r\n商談相手性別：" + that.inputGender +
            "\r\n" +
            "\r\n◎入金" +
            "\r\n決済進捗：" + that.inputPayStatus +
            "\r\n受注金額：" + that.inputSalesPrice +
            "\r\n決済済み金額：" + that.inputPaid +
            "\r\n残額：" + that.inputBalance +
            "\r\n残額期日：" + that.inputBalanceDeadline + that.inputBalanceDeadlineDay +
            "\r\n支払い方法：" + that.inputpaymentMethod +
            "\r\n" +
            "\r\n◎アポ評価" +
            "\r\n評価結果：" + that.inputEvaluation +
            // "\r\n理由："+inputEvaluationReason+
            "\r\n詳細：" + that.inputDetail +
            "\r\n" + "・他アポインターにも参考にしてほしいこと" +
            "\r\n" + that.inputFBGood +
            "\r\n" + "・改善点" +
            "\r\n" + that.inputFBMore +
            "\r\n" +
            "\r\n紹介キャンペーンの案内実施有無：" + that.inputCampaign +
            "\r\n助成金の案内の有無：" + that.inputSubsidy +
            "\r\n求人ボックスキャンペーンの案内の有無：" + that.inputJobOfferCampaign +
            "\r\n最終提案金額：" + that.inputLastSuggestionPrice +
            "\r\nパサー名前：" + that.inputPasserName +
            "\r\n" +
            "\r\n◎商談内容" +
            "\r\n" + that.inputMtgDetail +
            "\r\n◎備考" +
            "\r\n" + that.inputRemark +
            '\r\n' + '-----------------------------' +
            '\r\n' + 'POST ID：' + postID +
            '\r\n' + 'こちらのIDは投稿画面からの投稿IDです。'

            const requestFunc = new RequestBody.requestBody()
            const groupName = "apoReport"
            const spreadsheetApi = that.store.state.apis[`spreadsheet${that.demo}`]
            const DBInsertApi = that.store.state.apis[`dbInsert${that.demo}`]
            const kintoneInsertApi = that.store.state.apis[`kintoneInsert${that.demo}`]
            const appID = that.isProd ? 292 : 78 // キントーンのアプリID
            const NowTime = requestFunc.nowTime()
            const created_at = requestFunc.nowTime("created_at") 

            const userData = that.store.state.userData
            if (userData.BN !== "" && userData.BN !== null && userData.BN !== "NULL" && userData.BN !== "None") {
              var fullname = userData.BN + "（" + userData.firstName + "） " + userData.lastName
            } else {
              var fullname = userData.firstName + " " + userData.lastName
            }
            const belongData = requestFunc.setBelongDate(userData)


            /*********************************************************
             * 投稿開始
             * *******************************************************/
            // Loading表示
            let mes = '投稿中'
            if(mailCheckFlag) mes +=  '<br>不正なメールアドレスが入力されていましたが投稿では無視されます'
            that.vueObj.viewLoader(mes)

            // slack投稿
            const data = {
              "channel": that.slackChannel.id, 
              "username": fullname,
              "text":  toSlack, 
              "token": that.slackChannel.token,
              "icon_url": that.slackChannel.iconURL
            }

            const response = await fetch(that.slackChannel.postURL, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
              },
              body: `token=${encodeURIComponent(data.token)}&channel=${encodeURIComponent(data.channel)}&username=${encodeURIComponent(data.username)}&text=${encodeURIComponent(data.text)}&icon_url=${data.icon_url}`
            })
            .then(function (response) {
              return response.json()
            }).then(function (response) {
      
              if(response.message == "not current version") {
                alert(`tnPostのバージョンが違うようです。\n更新します。`);
                location.reload()
                postResult = false
                return false
              } else {
                
                console.log('slack投稿完了', response)
      
                return response
              }
      
            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })         

            const messageURL = `${that.groupURL}/p${response.ts.split('.').join('')}`


            // 転送チャンネルに投稿
            const postParam = {
              slackChannel: that.slackChannel,
              messageURL: messageURL,
              postUser: fullname,
              mention: '',
              text: `${fullname}さんからの投稿です。\n\n` + toForwardPost,
              channelID: that.slackChannel.forwardID01
            }
            new ForwardPost(postParam).post()


            let insertDataRecordCnt = [{
                                      "投稿日時":{"id": "", "val": NowTime},
                                      "全文":{"id": "", "val": func.textEscape(toSpreadsheet)},
                                      "投稿URL":{"id": "", "val": messageURL},
                                      "ユーザーID":{"id": "", "val": tn_user_id},
                                      "投稿者名":{"id": "", "val": fullname},
                                      "所属エリア":{"id": "", "val": belongData.userOffice},
                                      "所属（課/部）":{"id": "", "val": belongData.userSection},
                                      "所属（組）":{"id": "", "val": belongData.FS_UserTeam},
                                      "案件ID":{"id": "", "val": that.inputSubjectId},
                                      "投稿ID":{"id": "", "val": postID},
                                      "案件名":{"id": "", "val": func.textEscape(that.inputComName)},
                                      "代表者名":{"id": "", "val": func.textEscape(that.inputCeoName)},
                                      "メアド":{"id": "", "val": func.textEscape(that.inputCostomerEmail)},
                                      "商談日":{"id": "", "val": that.inputMtgDate},
                                      "開始時間":{"id": "", "val": that.inputMtgStartTime},
                                      "終了時間":{"id": "", "val": that.inputMtgEndTime},
                                      "商談結果":{"id": "", "val": func.textEscape(that.inputResult)},
                                      "追客期日":{"id": "", "val": that.inputContactDeadline},
                                      "提案内容":{"id": "", "val": that.inputSuggestion},
                                      "商談相手":{"id": "", "val": that.inputMtgPerson},
                                      "相手性別":{"id": "", "val": that.inputGender},
                                      "決済進捗":{"id": "", "val": that.inputPayStatus},
                                      "受注金額":{"id": "", "val": that.inputSalesPrice},
                                      "決済済み金額":{"id": "", "val": that.inputPaid},
                                      "残高":{"id": "", "val": that.inputBalance},
                                      "残高期日":{"id": "", "val": that.inputBalanceDeadline},
                                      "支払い方法":{"id": "", "val": that.inputpaymentMethod},
                                      "アポ評価 - 結果":{"id": "", "val": func.textEscape(that.inputEvaluation)},
                                      "アポ評価 - 理由":{"id": "", "val": func.textEscape(that.inputEvaluationReason)},
                                      "アポ評価 - 詳細":{"id": "", "val": func.textEscape(that.inputDetail)},
                                      "FB - Good":{"id": "", "val": func.textEscape(that.inputFBGood)},
                                      "FB - Bad":{"id": "", "val": func.textEscape(that.inputFBMore)},
                                      "商談内容":{"id": "", "val": func.textEscape(that.inputMtgDetail)},
                                      "キャンペーン案内有無":{"id": "", "val": that.inputCampaign},
                                      "助成金の案内有無":{"id": "", "val": that.inputSubsidy},
                                      "求人ボックスキャンペーン案内有無":{"id": "", "val": that.inputJobOfferCampaign},
                                      "最終提案金額":{"id": "", "val": that.inputLastSuggestionPrice},
                                      "パサー名前":{"id": "", "val": that.inputPasserName},
                                      "種別":{"id": "", "val": that.inputType},
                                      "音源":{"id": "", "val": that.inputRecord},
                                      "所属（組）IS":{"id": "", "val": belongData.IS_UserTeam}
                                      }]

            let sheetData = requestFunc.createBody(groupName, that.isProd, insertDataRecordCnt)
            
            const selectResult = document.getElementById("Result")
            const negotiationResultsId = selectResult.options[selectResult.selectedIndex].dataset.id
            const selectGender = document.querySelectorAll('input[type="radio"][name="gender"]')
            let genderId = ''
            selectGender.forEach((radioButton) => {
              if (radioButton.checked) {
                genderId = radioButton.dataset.id;
              }
            })
            const selectMtgPerson = document.querySelectorAll('input[type="checkbox"][name="MtgPerson"]:checked')
            const mtgPersonID = []

            selectMtgPerson.forEach((checkbox) => {
              mtgPersonID.push(checkbox.dataset.id)
            })

            const selectSuggestion = document.querySelectorAll('input[type="checkbox"][name="Suggestion"]:checked')
            const suggestionIds = []
            selectSuggestion.forEach((checkbox) => {
              suggestionIds.push(checkbox.dataset.id)
            })

            const apEvaluationsIds = { 
              "S": 8,
              "A": 1,
              "B": 2,
              "C": 4,
              "D": 5,
              "E": 6,
              "F": 7,
            }

            let dbData_negotiation_reports = {
              "table_name": "negotiation_reports",
              "form": {
                "non_sub_query": {
                  "post_id": postID,
                  "negotiation_results_id": negotiationResultsId,
                  "gender_id": genderId,
                  "created_at": created_at,
                  "users_id": userData.id,
                  "subjects_id": that.inputSubjectId,
                },
                "sub_query": {
                  "users": true,
                  "payment_statuses_id": 'SELECT id FROM payment_statuses WHERE name = "' + that.inputPayStatus + '"',
                }
              },
              "authC": {
                "password": "W!z|a~.x.T~Tzca8CBCm",
                "mail": "system@sungrove.co.jp"
              },
              "support_data": {
                "users_id": userData.id,
                "is_prod": that.isProd
              }
            }

            if (that.inputContactDeadline) {
              dbData_negotiation_reports.form.non_sub_query.contact_deadline_date = that.inputContactDeadline
            }

            if (that.inputEvaluation !== "") {
              dbData_negotiation_reports.form.non_sub_query.ap_evaluations_id = apEvaluationsIds[that.inputEvaluation]
            }

            let dbData_negotiation_positions = []
            for (let i = 0; i < mtgPersonID.length ; i++) {
              dbData_negotiation_positions[i] = {
                "table_name": "negotiation_positions",
                "form": {
                  "non_sub_query": {
                    "client_positions_id": parseInt(mtgPersonID[i]),
                    "created_at": created_at,
                  },
                  "sub_query": {
                    "users": false,
                    "negotiation_reports_id": 'SELECT MAX(id) FROM negotiation_reports'
                  }
                }
              }
            }

            let dbData_negotiation_suggestions = []
            for (let i = 0; i < suggestionIds.length ; i++) {
              dbData_negotiation_suggestions[i] = {
                "table_name": "negotiation_suggestions",
                "form": {
                  "non_sub_query": {
                    "suggestions_id": parseInt(suggestionIds[i]),
                    "created_at": created_at,
                  },
                  "sub_query": {
                    "users": false,
                    "negotiation_reports_id": 'SELECT MAX(id) FROM negotiation_reports'
                  }
                }
              }
            }

            let teams_ids = ""
            if (userData.teams_id && userData.teams_id != "NULL" && userData.teams_id != "None") {
              teams_ids = userData.teams_id.split(",")
            } else {
              teams_ids = [""]
            }

            let dbData_nego_reports_group_teams = []
            for (let i = 0; i < teams_ids.length ; i++) {
              dbData_nego_reports_group_teams[i] = {
                "table_name": "nego_reports_group_teams",
                "form": {
                  "non_sub_query": {
                    "post_id": postID,
                    "created_at": created_at
                  },
                  "sub_query": {
                    "users": false,
                  }
                },
                "support_data": {
                  "users_id": userData.id,
                  "is_prod": that.isProd
                }
              }
            }
            
            let dbData_array = []
            dbData_array.push(dbData_negotiation_reports)

            for (let i = 0; i < dbData_negotiation_positions.length ; i++) {
              dbData_array.push(dbData_negotiation_positions[i])
            }
            
            for (let i = 0; i < dbData_negotiation_suggestions.length ; i++) {
              dbData_array.push(dbData_negotiation_suggestions[i])
            }

            for (let i = 0; i < teams_ids.length ; i++) {
              if (userData.teams_id && userData.teams_id != "NULL" && userData.teams_id != "None") {
                dbData_nego_reports_group_teams[i].form.non_sub_query.teams_id = parseInt(teams_ids[i])
              }
              dbData_array.push(dbData_nego_reports_group_teams[i])
            }

            const productMap = {
              "welbox": "ウェルボックス",
              "indeed": "イツザイ",
              "web": "web直販"
            }

            const products = checkedProduct.reduce((acc, value) => {
              if (productMap[value]) {
                acc.push(productMap[value])
              }
              return acc
            }, [])

            const fieldCode = {
              "案件ID": {"value": that.inputSubjectId},
              "案件名": {"value": func.textEscape(that.inputComName)},
              "担当営業": {"value": $('#chargeName').val()},
              "商材_チェックボックス": {"value": products},
              "F評価": {"value": $('#evaluation_F').val()},
              "E評価": {"value": $('#evaluation_E').val()},
              "D評価要件": {"value": selectedValues},
              "商談認識_理解力": {"value": $('#evaluation_question_1').val()},
              "商談認識_概要認識": {"value": $('#decision_1').val()},
              "時間確保_120": {"value": $('#evaluation_question_2').val()},
              "時間確保_90": {"value": $('#decision_2').val()},
              "費用認識_支払い": {"value": $('#evaluation_question_3').val()},
              "費用認識_月額": {"value": $('#decision_3').val()},
              "決裁権": {"value": $('#decision_4').val()},
              "人柄_コミュ": {"value": $('#evaluation_question_5').val()},
              "人柄_反応": {"value": $('#decision_5').val()},
              "商材提案": {"value": $('#decision_6').val()},
              "集客または採用意欲_高": {"value": $('#evaluation_question_7').val()},
              "集客または採用意欲_中": {"value": $('#decision_7').val()},
              "集客または採用意欲_低": {"value": $('#decision_next_7').val()},
              "集客または採用キャパシティ": {"value": $('#decision_8').val()},
              "保証認識": {"value": $('#evaluation_question_9').val()},
              "保証認識払拭": {"value": $('#decision_9').val()},
              "紹介認識": {"value": $('#evaluation_question_10').val()},
              "紹介認識払拭": {"value": $('#decision_10').val()},
              "商談環境": {"value": $('#decision_11').val()},
              "indeed": {"value": $('#indeed').val()},
              "WELBOX": {"value": $('#welbox').val()},
              "テスクロYES": {"value": $('#last').val()},
            }

            const kintoneData = {
              "app": appID,
              "record": {}
            }

            Object.keys(fieldCode).forEach(key => {
              kintoneData.record[key] = fieldCode[key]
            })

            $('#btn').addClass('disabled').removeClass('pulse')

            //スプレッドシート登録へ送信   
            const promises = []
            const spreadRequest = requestFunc.apiRequestExecution(spreadsheetApi, sheetData, that.key)
            const DBRequest = requestFunc.apiRequestExecution(DBInsertApi, dbData_array, that.key)
            promises.push(spreadRequest)
            promises.push(DBRequest)

            if (that.inputType != "再訪") {
              const kintoneRequest = requestFunc.apiRequestExecution(kintoneInsertApi, kintoneData, that.key)
              promises.push(kintoneRequest)
            }

            Promise.all(promises)
            .then(function (response) {
              // Loading非表示
              that.vueObj.viewLoader()
              console.log('シート登録完了', response)
            })
            .catch((e) => {
              console.error(e.stack, e.message)
            })
        
            // 完了画面表示
            const params = {
              groupTitle: that.groupTitle,
              groupURL: that.groupURL,
              searchURL: messageURL,
              calURL: '',
              message: '',
              completeTitle: '投稿完了'
            }
            that.vueObj.viewComplete(params)

         })()
        }
      }
    }
  }
}

import RequestBody from "./requestBody.class"
import ForwardPost from "../../assets/js/forwardPost.class"
import OfficeDatas  from "./officeData"

export default {
  apoReport
}