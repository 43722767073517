/**
  申し込み用フォーム[イツザイ]コンポーネント
*/
<template>
<div id="formItzai" class="form_group">
  <Section class="form_wrap form_list">
    <div class="rap1_boder" id="wrap3">
      <Section><h1 class="wrap_title pd_7">イツザイ</h1></Section>
      <div class="formSharedComm">
        <Column class="pd3_parts">
          <Section><label class="c-notice">納期はヒアリング日から "2ヶ月" としてください（※契約ではなく、ヒアリング）</label></Section>
          <label>動画撮影</label>
          <p class="c-notice">※有の場合は安井大侑さん・瀬川文彦さんをメンションつけてください。</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="movie_yesNo" id="movie_yes" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="movie_yesNo" id="movie_no" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>パッケージ数</label>
          <input type="text" id="packed_cnt" class="required form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>サイト制作数</label>
          <input type="text" id="job_cnt" class="required form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label >業界</label>
          <div class="c-select">
            <select name="industry_names" id="industry_names" class="required form_select">
              <option value="" disabled selected>--業種 選択--</option>
            </select>
          </div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>募集職種名</label>
          <input type="text" id="rctJobCate" class="required form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>追加モジュール数</label>
          <input type="text" id="moduleNum" class="form_txt" placeholder="半角数字で入力" inputmode="numeric">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>補償オプション</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="compensation_yesNo" id="compensation_yes" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice formSharedComm">
                <input type="radio" class="required" name="compensation_yesNo" id="compensation_no" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>その他オプション</label>
          <input type="text" id="otherOp" class="form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>お客様共通事項</label>
          <input type="text" id="sharedMatters" class="form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>撮影有無</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo" id="shoot_yes" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo" id="shoot_no" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="fildReasonNotShoot" class="pd3_parts formSharedComm -none">
          <label>撮影無し理由</label>
          <input type="text" id="reasonNotShoot">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>契約動機</label>
          <div><textarea id="contractReason" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>顧客人柄</label>
          <input type="text" id="persnality" class="required form_txt">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>お客様との会話内容（世間話を含めて）</label>
          <div><textarea id="conv" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>イツザイに載せる際に話した内容</label>
          <div><textarea id="convItzai" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>サービスについて伝えたこと（修正や撮影）</label>
          <div><textarea id="convService" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>掲載希望日時（撮影期間を伝えた上で）</label>
          <div class="width60"><input type="text" id="shootDate" class="required form_txt"></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>求人背景（他媒体効果など）</label>
          <div class="width60"><input type="text" id="rctBackground" class="required form_txt"></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>その他顧客希望</label>
          <div><textarea id="otherHope" class="form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>運用への共有事項（応募数など）</label>
          <div><textarea id="sharedOpTeams" class="form_txt"></textarea></div>
        </Column>
        <Column id="is_contact_itzai_concurrent" class="pd3_parts formSharedComm">
          <label>連絡先情報備考</label>
          <p class="-attention">共通記入欄と異なっているものがあれば下記記入のこと</p>
          <div><textarea id="contact_itzai" class="form_txt"></textarea></div>
        </Column>
        <Column>
          <label for="name">求人ボックスキャンペーン対応</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label for="guide_job_offer_campaign_yes" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="guide_job_offer_campaign" value="有" id="guide_job_offer_campaign_yes">
                <span>有</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label for="guide_job_offer_campaign_no" class="c-choice pd5_rl">
                <input type="radio" class="required form_check" name="guide_job_offer_campaign" value="無" id="guide_job_offer_campaign_no">
                <span>無</span>
              </label>
            </li>
          </ul>
        </Column>
      </div>
    </div>
  </Section>
</div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationItzai',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
