/**
  申し込み用フォーム[EC]コンポーネント
*/
<template>
<div id="formEC" class="form_group">
  <div class="form_wrap form_list">
    <div class="rap1_boder" id="">
      <Section><h1 class="wrap_title pd_7">EC</h1></Section>
      <Column class="pd3_parts formEC width30">
        <label>型番</label>
        <input type="text" class="form_txt" id="ecVersion">
      </Column>
      <Column class="pd3_parts formEC width30">
        <label>お客様共有事項</label>
        <input type="text" class="form_txt" id="sharedMatters_ec">
      </Column>
      <Column class="pd3_parts formEC">
        <label>契約動機</label>
        <div><textarea id="contractReason_ec" class="required form_txt"></textarea></div>
      </Column>
      <Column class="pd3_parts formEC width30">
        <label>顧客人柄</label>
        <input type="text" id="persnality_ec" class="required form_txt">
      </Column>
      <Column class="pd3_parts formEC">
        <label>お客様との会話内容</label>
        <div><textarea id="conv_ec" class="required form_txt"></textarea></div>
      </Column>
      <Column class="pd3_parts formEC">
        <label>顧客希望</label>
        <div><textarea id="hope_ec" class="form_txt"></textarea></div>
      </Column>
      <Column class="pd3_parts formSharedComm">
        <label>商品登録点数</label>
        <input type="text" class="required form_txt" inputmode="numeric" id="productsNum" value="3">
      </Column>
      <Column class="pd3_parts formEC width15">
        <label>サイト制作数</label>
        <input type="text" inputmode="numeric" class="required form_txt" id="ecSiteProductsNum">
      </Column>
      <Column class="pd3_parts formCMS width30">
        <label>掲載希望日時</label>
        <input type="text" id="shootDate_ec" class="required form_txt">
      </Column>
      <Column class="pd3_parts formECShoot">
        <label>商品撮影</label>
        <Group>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_ec" id="shoot_yes_ec" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_ec" id="shoot_no_ec" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
          <label class="attention">EC申し込みとは別に撮影費用がかかります</label>
        </Group>
      </Column>
      <Column class="pd3_parts formECShoot">
        <label>撮影商品数</label>
        <input type="text" class="form_txt" inputmode="numeric" id="ecProductsNum">
      </Column>
      <Column class="pd3_parts formECShoot">
        <label>撮影カット数</label>
        <input type="text" class="form_txt" id="ecCutNum" inputmode="numeric">
      </Column>
      <Column class="pd3_parts formECShoot">
        <label>撮影場所</label>
        <ul class="c-choices">
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="" name="shootLoca" id="shootLoca_mainOffice" value="郵送にて本社">
              <span class="">郵送にて本社</span>
            </label>
          </li>
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="" name="shootLoca" id="shootLoca_branchOffice" value="支社">
              <span class="">支社</span>
            </label>
          </li>
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="" name="shootLoca" id="shootLoca_shops" value="店舗">
              <span class="">店舗</span>
            </label>
          </li>
        </ul>
      </Column>
      <Column class="pd3_parts formECShoot">
        <label>EC導入決済(コンビニ or 定期利用)</label>
        <ul class="c-choices">
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="ecPaymentMethod" id="ecPaymentMethod_yes" value="有り">
              <span class="">有り</span>
            </label>
          </li>
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="ecPaymentMethod" id="ecPaymentMethod_no" value="無し">
              <span class="">無し</span>
            </label>
          </li>
          <li class="c-choices__item">
            <label class="c-choice">
              <input type="radio" class="required" name="ecPaymentMethod" id="ecPaymentMethod_notyet" value="未確認（ディレクター提案）">
              <span class="">未確認（ディレクター提案）</span>
            </label>
          </li>
        </ul>
      </Column>
      <Column id="is_contact_ec_concurrent" class="pd3_parts formEC">
        <label>連絡先情報備考</label>
        <p class="-attention">共通記入欄と異なっているものがあれば下記記入のこと</p>
        <div><textarea id="contact_ec" class="form_txt"></textarea></div>
      </Column>
    </div>
  </div>
</div>

</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationEC',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
