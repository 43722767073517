/**
  申し込み用フォーム[CMS]コンポーネント
*/
<template>
  <div id="formCMS" class="form_group">
    <Section class="form_wrap form_list">
      <div class="rap1_boder" id="">
        <Section><h1 class="wrap_title pd_7">CMS</h1></Section>
        <Column class="pd3_parts formCMS">
          <label>動画撮影</label>
          <p class="-attention">※有の場合は安井大侑さん・瀬川文彦さんをメンションつけてください。</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="cms_should_movie" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="cms_should_movie" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formCMS width15">
          <label>サイト制作数</label>
          <input type="text" inputmode="numeric" class="required form_txt" id="cmsProductsNum">
        </Column>
        <Column class="pd3_parts formCMS width30">
          <label>掲載希望日時</label>
          <input type="text" id="shootDate_cms" class="required form_txt">
        </Column>
        <Column class="pd3_parts formCMS width30">
          <label>型番</label>
          <input type="text" class="form_txt" id="cmsVersion">
        </Column>
        <Column class="pd3_parts formCMS width30">
          <label>お客様共有事項</label>
          <input type="text" class="form_txt" id="sharedMatters_cms">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>撮影有無</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_cms" id="shoot_yes_cms" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_cms" id="shoot_no_cms" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="fildReasonNotShoot_cms" class="pd3_parts formCMS formSharedComm -none">
          <label>撮影無し理由</label>
          <input type="text" class="form_txt" id="reasonNotShoot_cms">
        </Column>
        <Column class="pd3_parts formCMS">
          <label>契約動機</label>
          <div><textarea id="contractReason_cms" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formCMS width30">
          <label>顧客人柄</label>
          <input type="text" id="persnality_cms" class="required form_txt">
        </Column>
        <Column class="pd3_parts formCMS">
          <label>お客様との会話内容</label>
          <div><textarea id="conv_cms" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formCMS">
          <label>顧客希望</label>
          <div><textarea id="hope_cms" class="form_txt"></textarea></div>
        </Column>
        <Column id="is_contact_cms_concurrent" class="pd3_parts formCMS">
          <label>連絡先情報備考</label>
          <p class="-attention">共通記入欄と異なっているものがあれば下記記入のこと</p>
          <div><textarea id="contact_cms" class="form_txt"></textarea></div>
        </Column>
      </div>
    </Section>
  </div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationCMS',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
