class OfficeDatas {

    constructor() {

        // 通常カレンダー
        this.apCalIDList = {
            "東京": "sungrove.co.jp_uvli783d0c6rj70akera24hvkc@group.calendar.google.com",
            "大阪": "sungrove.co.jp_i4bi184g2d2r4jkf1mmi7k0suo@group.calendar.google.com",
            "名古屋": "sungrove.co.jp_vk3iaio1bbj7v0um4k5cij65fs@group.calendar.google.com",
            "福岡": "c_6b031d116f3caa2bc73b23e9bcf3454c4c079e8288a2dce93c417f9844da413d@group.calendar.google.com",
            "横浜": "c_e77394d42f0a14cf049a7d4fbb74671eef1ae23a7c5ae5b9b61e4e07ae733ecc@group.calendar.google.com",
        }

        // 自アポカレンダー
        this.selfSalesCalIDList = {
            "東京": "c_hs3vot3t5nh2g1370333i28ido@group.calendar.google.com",
            "大阪": "c_v8dhesj9500eem0k5h9o5k6tv4@group.calendar.google.com",
            "名古屋": "c_bkoq97pc70bb7gjblja3mdiuqk@group.calendar.google.com",
            "福岡": "c_995797969199d3c8a9534e608e9d33582384dd3caf398a4f6f0eaa071c185350@group.calendar.google.com",
            "横浜": "c_d7c6ad291a625342974cc627a5554800394cdea960db541f04b2edf272d6feeb@group.calendar.google.com",
        }

        // 再訪カレンダー
        this.saihouCalIDList = {
            "東京": "c_kboclvjjfdvspg5ffj6aqut0o0@group.calendar.google.com",
            "大阪": "c_ikpv1a5sm3ugvjdumslq7gjf6c@group.calendar.google.com",
            "名古屋": "c_rbgto9nqiqt9746qmj99bsvat0@group.calendar.google.com",
            "福岡": "c_e9acd590524711b7eccd4bdc65a453cabfa16561b30a482a4b578b4b47c542a9@group.calendar.google.com",
            "横浜": "c_72c40d2517432fc1c0ef7a8b24091704bafb13d426241fbbbdbc354f78762ea4@group.calendar.google.com",
        }

        // リスキャンカレンダー
        this.rescanCalID = "c_uflhjofuk794pidil3ic94itlg@group.calendar.google.com"

        // 支社IDと支社名の変換用マッピング
        this.officeMapping = {
            1: "東京",
            2: "大阪",
            3: "名古屋",
            4: "福岡",
            5: "東京",  // 秋葉原
            6: "横浜",
        }

    }


    // 通常カレンダーの登録にあるものかどうかをチェック
    checkIncludeApCal (calID) {
        const isIncludeCalID = Object.values(this.apCalIDList).includes(calID)
        return isIncludeCalID
    }

    // 自アポカレンダーの登録にあるものかどうかをチェック
    checkIncludeSelfSalesCal (calID) {
        const isIncludeCalID = Object.values(this.selfSalesCalIDList).includes(calID)
        return isIncludeCalID
    }

    // 再訪カレンダーの登録にあるものかどうかをチェック
    checkIncludeSaihouCal (calID) {
        const isIncludeCalID = Object.values(this.saihouCalIDList).includes(calID)
        return isIncludeCalID
    }

    // リスキャンカレンダーかどうかを判別
    checkRescanCal (calID) {
        return calID === this.rescanCalID
    }

    // 支社名受け取ってカレンダーIDを返す（通常アポ）
    getApCalID (officeName) {
        const calID = this.apCalIDList[officeName]
        return calID
    }

    // 支社名受け取ってカレンダーIDを返す（自アポ）
    getSelfSalesCalID (officeName) {
        const calID = this.selfSalesCalIDList[officeName]
        return calID
    }

    // 支社名受け取ってカレンダーIDを返す（再訪）
    getSaihouCalID (officeName) {
        const calID = this.saihouCalIDList[officeName]
        return calID
    }

    // officeIDから支社名返す
    getUserOfficeName (officeID) {
        let officeName = this.officeMapping[officeID]
        officeName = officeName || ""
        return officeName
    }

}

export default OfficeDatas