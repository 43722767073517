//関数まとめ
class requestFunc {

  //チェックボックス・値・項目画面表示・必須項目・クローンを全てリセット
  allReset(clCount, delCount) {
    $('.alert').hide() 
    const arrayCheckedAll = ["btnReqKind_WithPay", "agent", "btnRequest_Rest", "btnRequest_HoliWork", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "btnTime_AllDay", "btnTime_AM", "btnTime_PM"]
    const arrayValueAll = ["formTypeId", "agentName", "start_date", "last_date", "requestDate", "reason", "changes_id", "cancel_detail_id", "remarks"]
    const arrayHideAll = ["pochiButton", "requestType", "agentName", "requestKinds", "requestStart", "requestDay", "holidayWork", "changesForm", "cancelForm", "remarks", "alert"]
    const arrayRequiredAll = ["btnForm_Request", "agentName", "btnForm_Change","btnForm_Cancel", "btnRequest_Rest", "btnRequest_HoliWork", "btnReqKind_WithPay", "btnReqKind_ComHoliday", "btnReqKind_Holiday", "btnTime_AllDay", "btnTime_AM", "btnTime_PM", "start_date", "requestDate", "reason", "changes_id", "cancel_detail_id"]
    this.resetChecked(arrayCheckedAll)
    this.resetValue(arrayValueAll)
    this.resetHide(arrayHideAll)
    this.resetRemoveRequired(arrayRequiredAll) 
    $("#formTypeId").val("選択")
    let remain = [] 
    remain = this.checkClone(remain, clCount)
    const self = this;

    $.each(remain, function(index, value) {
      const arrayCheckedChangeClone = ["holidayWorkBtn_a" + value, "holidayWorkBtn_b" + value, "holidayWorkBtn_c" + value, "allDay" + value]
      const arrayValueChangeClone = ["workDate" + value, "start_time_id" + value, "last_time_id" + value]
      const arrayRequireChangeClone = ["holidayWorkBtn_a" + value, "holidayWorkBtn_b" + value, "holidayWorkBtn_c" + value, "workDate" + value, "start_time_id" + value]
      self.resetChecked(arrayCheckedChangeClone)
      self.resetValue(arrayValueChangeClone)
      self.resetRemoveRequired(arrayRequireChangeClone)
      const target = $("#form_ap" + value)
      delCount += self.deleteClone(delCount, clCount, target)
    })

    $('.cloneEle' + remain[remain.length - 1]).attr('id', 'form_ap1') 
    $('#form_ap1').removeClass()
    $('#form_ap1' + ' input[type="radio"]').attr('name', 'sellFormat1') 
    $('#form_ap1' + ' label[id="btnLabel_a"]').attr('for', 'holidayWorkBtn_a1') 
    $('#form_ap1' + ' label[id="btnLabel_b"]').attr('for', 'holidayWorkBtn_b1') 
    $('#form_ap1' + ' label[id="btnLabel_c"]').attr('for', 'holidayWorkBtn_c1') 
    $('#form_ap1' + ' input[value="対面"]').attr('id', 'holidayWorkBtn_a1') 
    $('#form_ap1' + ' input[value="オンライン"]').attr('id', 'holidayWorkBtn_b1') 
    $('#form_ap1' + ' input[value="どちらでも可"]').attr('id', 'holidayWorkBtn_c1') 
    $('#form_ap1' + ' input[name="start_time"]').attr('id', 'start_time_id1') 
    $('#form_ap1' + ' input[name="last_time"]').attr('id', 'last_time_id1') 
    $('#form_ap1' + ' input[type="checkbox"]').attr('id', 'allDay1') 
    $('#form_ap1' + ' input[type="date"]').attr('id', 'workDate1') 
  }

  //チェック外し
  resetChecked(array) {

    $.each(array, function(index, value) {
      $('#' + value).removeAttr('checked')
    })

  }

  //値リセット
  resetValue(array) {
      
    $.each(array, function(index, value) {
      $("#" + value).val("")
    })

  }

  //項目画面非表示
  resetHide(array) {

    $.each(array, function(index, value) {
      $('.' + value).hide()
    })

  }

  //必須項目設定削除
  resetRemoveRequired(array) {

    $.each(array, function(index, value) {
      functions.removeRequiredOp(value)
    })

  }

  //クローン削除
  deleteClone(delCount, clCount, target) {

    if (delCount + 1 < clCount) {
      target.remove()
      return 1 
    }

    return 0 

  }

  //クローン数チェック
  checkClone(remain, clCount) {

    for (let i = 1; i <= clCount; i++) {
      const count = $("[name=sellFormat" + i + "]").length

      if (count != 0) {
        remain.push(i) 
      }

    }

    return remain 

  }

  //必須項目チェック関数
  lastCheck(resultRequiredArray) {
    let requiredNG = 0
    let checkRequired = 0

    for (let key in resultRequiredArray) {

      resultRequiredArray[key] == 0 ? requiredNG = 1 : checkRequired = 1
    
      if (checkRequired == 1 && requiredNG == 0) {
        $('#btn').prop('disabled', false)
        $('#btn').removeClass('disabled').addClass('pulse')
      } else if (checkRequired == 0 || requiredNG == 1) {
        $('#btn').prop('disabled', true)
        $('#btn').removeClass('pulse').addClass('disabled')
      }

    }

  }

  //[終日]チェックボックス 押されたら開始・終了時間反映しない
  checkBoxStyle(remainNumber) {
    const arrayCheckBox = ["start_time_id" + remainNumber, "last_time_id" + remainNumber]

    if ($("#allDay" + remainNumber + ":checked").val()) {
      this.resetValue(arrayCheckBox)
      this.resetRemoveRequired(arrayCheckBox)
      $("#" + arrayCheckBox[0]).prop('disabled', true)
      $("#" + arrayCheckBox[1]).prop('disabled', true)
      $("#" + arrayCheckBox[0]).removeAttr('style')

    } else {
      $("#" + arrayCheckBox[0]).prop('disabled', false)
      $("#" + arrayCheckBox[1]).prop('disabled', false)
      $("#" + arrayCheckBox[0]).addClass("required")
    }

  }

  //押されているボタン確認
  inputButton(inputBtn) {

    for (let i = 0; i < inputBtn.length; i++) {
      
        if (inputBtn[i].checked) {
          return inputBtn[i].value 
        }

    }

  }

  //日付の形式を合わせる
  dayAdjustment(today) {
    let yyyy = today.getFullYear() 
    let mm = ("0" + (today.getMonth() + 1)).slice(-2) 
    let dd = ("0" + today.getDate()).slice(-2) 
    let value = yyyy + '-' + mm + '-' + dd  
    return value 
  }

  //休出は土日祝日しか選べないようにする アラームはreturnが1の時に発生
  checkHoliday(remainNumber, csvData) {
    let val = String(remainNumber).match(/[0-9]{4}\-[0-9]{2}\-[0-9]{2}/ )?remainNumber: $('#workDate' + remainNumber).val()
    let date = new Date(val)
    let week = date.getDay()
    let okFlag = false
    const self = this

    $.each(addHolidayList, function(index, value) { //祝日判定追加  記入形式が多少異なっていても修正して合わせる
      let today = new Date(value) 
      let day = self.dayAdjustment(today)
      csvData.push(day) 
    })

    if (val == "") {
      return 0 //日付入ってなければアラームなし
    
    } else if (week == 0 || week == 6) {
      return 0 //土日のためアラームなし

    } else if (week !== 0 && week !== 6) {

      $.each(csvData, function(index, value) {

        if (val == value) {
          okFlag = true
          return false 
        }

      })

      if (okFlag) {
        return 0 //祝日のためアラームなし

      } else {
        $("#workDate" + remainNumber).val("")
        return 1 //土日でも祝日でもない為、日付初期化してアラーム発生
      }

    }

  }

  //商談開始・終了時間の設定
  checkTime($jquery, clCount) {
    const $cloneTimeTag = $jquery.find(".time_picker")
    const $cloneStartTimeTag = $jquery.find(".start_time_timepicker")
    const $cloneLastTimeTag = $jquery.find(".last_time_timepicker")

    $cloneTimeTag.timepicker({
      'timeFormat': 'H:i',
      'minTime': '8:00',
      'maxTime': '21:30',
      'step': 15
    })

    $(document).on("input change", `#start_time_id${clCount}`, function() {

      $cloneLastTimeTag.timepicker({
        'timeFormat': 'H:i',
        'minTime': $cloneStartTimeTag.val(), 
        'maxTime': '21:30',
        'step': 15
      })

    })
    
    $(document).on("input change", `#last_time_id${clCount}`, function() {

      $cloneStartTimeTag.timepicker({
        'timeFormat': 'H:i',
        'minTime': '8:00',
        'maxTime': $cloneLastTimeTag.val(),
        'step': 15
      })

    })

    if ($cloneStartTimeTag.val() !== "" && $cloneLastTimeTag.val() !== "") {
      const timeChecker = functions.duration($cloneStartTimeTag.val(), $cloneLastTimeTag.val())

      if (timeChecker == 0) {
        $cloneStartTimeTag.val = "" 
        $cloneLastTimeTag.val = "" 
      }

    }

  }

  //時間以外の入力規制と時間遡り規制
  checkTimeValue(remainNumber) {
    var regTime = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
    const startTime = $("#start_time_id" + remainNumber).val()
    const lastTime = $("#last_time_id" + remainNumber).val()

    if (startTime.match(regTime) == null) {
      $("#start_time_id" + remainNumber).val("")
    } 

    if (lastTime.match(regTime) == null) {
      $("#last_time_id" + remainNumber).val("")
    } 

    if (lastTime !== "" && startTime > lastTime) {
      window.confirm('[商談時間] 開始日時は終了日時より前にしてください。')
      $("#start_time_id" + remainNumber).val("")
      $("#last_time_id" + remainNumber).val("")
    }
    
  }

  //日付のつなぎを[/]に変換し曜日取得
  getWeek(changeData) {
    let year = parseInt(changeData.substring(0,4)) 
    let month = parseInt(changeData.substring(5,7)) 
    let day = parseInt(changeData.substring(8,10)) 
    let jsMonth = month - 1  
    let dayOfWeekStrJP = [ "日", "月", "火", "水", "木", "金", "土" ]  
    let date = new Date( year, jsMonth , day ) 
    return year + "/" + month + "/" + day + " （" + dayOfWeekStrJP[date.getDay()] + "）" 
  }

  //日付データのつなぎを[/]に変換
  getDate(changeData) {
    let year = parseInt(changeData.substring(0,4)) 
    let month = parseInt(changeData.substring(5,7)) 
    let day = parseInt(changeData.substring(8,10)) 
    return year + "/" + month + "/" + day 
  }

  //曜日を値で取得
  getDateInt(changeData) {
    let year = parseInt(changeData.substring(0,4)) 
    let month = parseInt(changeData.substring(5,7)) 
    let day = parseInt(changeData.substring(8,10)) 
    let jsMonth = month - 1  
    let date = new Date( year, jsMonth , day ) 
    return date.getDay() 
  }

  //申請時間条件分岐
  restRequest(timeKinds) {
    switch (timeKinds) {

      case "終日":
        $('.requestStart').show()
        $('.requestDay').hide()
        let requestDate = $("#requestDate").val()

        if (requestDate !== "") {
          $("#start_date").val(requestDate)
        }

        $("#requestDate").val("")
        functions.removeRequiredOp("requestDate")
        break 

      case "午前休":
      case "午後休":
        $('.requestStart').hide()
        $('.requestDay').show()
        let startDate = $("#start_date").val()

        if (startDate !== "") {
          $("#requestDate").val(startDate)
        }
        
        $("#start_date").val("")
        $("#last_date").val("")
        functions.removeRequiredOp("start_date")
        break 

    }
  }

  handleTimeValidation(startTimeSelector, lastTimeSelector, removeRequireArray) {
    const startTime = $(startTimeSelector).val()
    const lastTime = $(lastTimeSelector).val()
  
    if (startTime || lastTime) {
      this.requestFunc.resetRemoveRequired(removeRequireArray)
      $(startTimeSelector).css('background-color', 'rgb(255, 255, 255)')
      $(lastTimeSelector).css('background-color', 'rgb(255, 255, 255)')
    } else {
      $(startTimeSelector).addClass('required')
      $(lastTimeSelector).addClass('required')
    }
  }

}

const functions = new formFuncClass.formFunctions(moment, $, sha256)
const addHolidayList = ["2024-12-30", "2024-12-31", "2025-01-01", "2025-01-02", "2025-01-03"] //祝日判定手書き追加欄：記入例["2023-01-01", "2023-12-12"]

import $ from 'jquery' 
import moment from "moment" 
import sha256 from 'crypto-js/sha256' 
import formFuncClass  from "../js/formFunctions.class" 

export default {
  requestFunc
}