/*
* ぽちボタンクリック後の処理
*/ 

class afrPostEasier {
  constructor(that) {
    this.that = that
    this.formName = "Easier"    
    this.that.$('.search_form_wrap').show()
    this.actionInit()
  }

  returnFormName() {
    return this.formName
  }

  getInputData() {
    const $ = this.that.$
    const moment = this.that.moment
    moment.locale("ja")
    const store = this.that.store
    const func = this.that.func

    //制作前共有
    store.commit('setValue', ['easier', 'input_easier_should_movie', $('input[name="easier_should_movie"]:checked').val(), 'easierShouldMovie'])
    store.commit('setValue', ['easier', 'input_easier_plan', $('input[name="easier_plan"]:checked').val(), 'easierPlan'])
    store.commit('setValue', ['easier', 'input_Brand_page_production_easier', $('input[name="Brand_page_production_easier"]:checked').val(), 'BrandPageProductionEasier'])
    store.commit('setValue', ['easier', 'input_PR_pages_produced_easier', func.textEscape($('#PR_pages_produced_easier').val()), 'PRPagesProducedEasier'])
    store.commit('setValue', ['easier', 'input_MEO_measures_easier', $('input[name="MEO_measures_easier"]:checked').val(), 'MEOMeasuresEasier'])
    store.commit('setValue', ['easier', 'input_word_number_easier', func.textEscape($('#word_number_easier').val()), 'wordNumberEasier'])
    store.commit('setValue', ['easier', 'input_advertising_substitution_easier', $('input[name="advertising_substitution_easier"]:checked').val(), 'advertisingSubstitutionEasier'])
    store.commit('setValue', ['easier', 'input_paid_advertising_expenses_easier', func.textEscape($('#paid_advertising_expenses_easier').val()), 'paidAdvertisingExpensesEasier'])
    store.commit('setValue', ['easier', 'input_shoot_yesNo_easier', $('input[name="shoot_yesNo_easier"]:checked').val(), 'shootYesNoEasier'])
    store.commit('setValue', ['easier', 'inputShouldShootEasier', $('[name="shoot_yesNo_easier"]:checked').val(), ''])
    store.commit('setValue', ['easier', 'input_contractReason_easier', func.textEscape($('#contractReason_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_persnality_easier', func.textEscape($('#persnality_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_conv_easier', func.textEscape($('#conv_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_appeal_easier', func.textEscape($('#appeal_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_questions_easier', func.textEscape($('#questions_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_date_publication_easier', func.textEscape($('#date_publication_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_background_customers_easier', func.textEscape($('#background_customers_easier').val()), ''])
    store.commit('setValue', ['easier', 'input_sharing_operators_easier', func.textEscape($('#sharing_operators_easier').val()), ''])
  }

  setVal (balanceInfoMsg) {
    const store = this.that.store

    let sendVal = 
`
◎Easier
動画撮影：${store.state.formData.easier.input_easier_should_movie}
プラン（利用期間）：${store.state.formData.easier.input_easier_plan}
ブランドページ制作代行：${store.state.formData.easier.input_Brand_page_production_easier}
PRページ制作代行本数：${store.state.formData.easier.input_PR_pages_produced_easier}
MEO対策：${store.state.formData.easier.input_MEO_measures_easier}
MEO対策ワード数：${store.state.formData.easier.input_word_number_easier}
有料広告運用代用サービス：${store.state.formData.easier.input_advertising_substitution_easier}
有料広告費：${store.state.formData.easier.input_paid_advertising_expenses_easier}
写真撮影：${store.state.formData.easier.input_shoot_yesNo_easier}
契約動機：${store.state.formData.easier.input_contractReason_easier}
顧客人柄：${store.state.formData.easier.input_persnality_easier}
お客様との会話内容：${store.state.formData.easier.input_conv_easier}
ブランド、PRページで訴求したい内容（サービスや商品名）：${store.state.formData.easier.input_appeal_easier}
サービスについて伝えたこと、質問内容：${store.state.formData.easier.input_questions_easier}
広告出稿希望日時：${store.state.formData.easier.input_date_publication_easier}
集客背景：${store.state.formData.easier.input_background_customers_easier}
運用担当者への共有事項（CV数など）：${store.state.formData.easier.input_sharing_operators_easier}
`

    const contactValue = document.getElementById("contact_easier").value
    sendVal = sendVal + '連絡先情報備考：' + contactValue + '\n'
    store.commit('setTalknoteMessage', ['easier', sendVal])
  }

  actionInit() {
    const $ = this.that.$

    $("#form_ap").on("click", '[name="advertising_substitution_easier"]', function() {
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "advertising_substitution_yes_easier":
          $("#paid_advertising_expenses_easier").val('')
          $('#paid_advertising_expenses_easier').prop('disabled', false)
          break

        case "advertising_substitution_no_easier":
          $("#paid_advertising_expenses_easier").val('0')
          $('#paid_advertising_expenses_easier').prop('disabled', true)
          break
      }
    })

    $("#form_ap").on("click", '[name="MEO_measures_easier"]', function() {
      let thisVal = $(this).prop("id")

      switch (thisVal) {
        case "MEO_measures_yes_easier":
          $("#word_number_easier_column").slideDown()
          $("#word_number_easier").addClass("required")
          break

        case "MEO_measures_no_easier":
          $("#word_number_easier_column").slideUp()
          $("#word_number_easier").removeClass("required")
          $("#word_number_easier").val("")
          break
      }
    })
  }
}

export default {
  afrPostEasier
}