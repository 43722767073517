/**
  申し込み用フォーム[Easier]コンポーネント
*/
<template>
  <div id="formEasier" class="form_group">
    <Section class="form_wrap form_list">
      <div class="rap1_boder" id="">
        <Section><h1 class="wrap_title pd_7">Easier</h1></Section>
        <Column class="pd3_parts formEasier">
          <label>動画撮影</label>
          <p class="-attention">※有の場合は安井大侑さん・瀬川文彦さんをメンションつけてください。</p>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_should_movie" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_should_movie" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>プラン（利用期間）</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_plan" value="6ヶ月">
                <span class="">6ヶ月</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_plan" value="12ヶ月">
                <span class="">12ヶ月</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="easier_plan" value="24ヶ月">
                <span class="">24ヶ月</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>ブランドページ制作代行</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="Brand_page_production_easier" id="Brand_page_production_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="Brand_page_production_easier" id="Brand_page_production_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>PRページ制作代行本数</label>
          <p class="-attention">※不要の場合0と記載</p>
          <Flex class="display_yoko">
            <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="PR_pages_produced_easier">
            <span class="chara_bottom">本</span>
          </Flex>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>MEO対策</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="MEO_measures_easier" id="MEO_measures_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="MEO_measures_easier" id="MEO_measures_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column id="word_number_easier_column" class="pd3_parts formEasier">
          <label>MEO対策ワード数</label>
          <p class="-attention">※不要の場合0と記載</p>
          <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="word_number_easier">
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>有料広告運用代用サービス</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="advertising_substitution_easier" id="advertising_substitution_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="advertising_substitution_easier" id="advertising_substitution_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>有料広告費</label>
          <Flex class="display_yoko">
            <input type="number" inputmode="numeric" class="required form_txt" placeholder="半角数字で入力" id="paid_advertising_expenses_easier">
            <span class="chara_bottom">円</span>
          </Flex>
        </Column>
        <Column class="pd3_parts formSharedComm">
          <label>写真撮影</label>
          <ul class="c-choices">
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_easier" id="shoot_yes_easier" value="有り">
                <span class="">有り</span>
              </label>
            </li>
            <li class="c-choices__item">
              <label class="c-choice">
                <input type="radio" class="required" name="shoot_yesNo_easier" id="shoot_no_easier" value="無し">
                <span class="">無し</span>
              </label>
            </li>
          </ul>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>契約動機</label>
          <div><textarea id="contractReason_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier width30">
          <label>顧客人柄</label>
          <input type="text" id="persnality_easier" class="required form_txt">
        </Column>
        <Column class="pd3_parts formEasier">
          <label>お客様との会話内容</label>
          <div><textarea id="conv_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>ブランド、PRページで訴求したい内容（サービスや商品名）</label>
          <div><textarea id="appeal_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>サービスについて伝えたこと、質問内容</label>
          <div><textarea id="questions_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>広告出稿希望日時</label>
          <input type="text" id="date_publication_easier" class="required form_txt">
        </Column>
        <Column class="pd3_parts formEasier">
          <label>集客背景</label>
          <div><textarea id="background_customers_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column class="pd3_parts formEasier">
          <label>運用担当者への共有事項（CV数など）</label>
          <div><textarea id="sharing_operators_easier" class="required form_txt"></textarea></div>
        </Column>
        <Column id="is_contact_easier_concurrent" class="pd3_parts formEasier">
          <label>連絡先情報備考</label>
          <p class="-attention">共通記入欄と異なっているものがあれば下記記入のこと</p>
          <div><textarea id="contact_easier" class="form_txt"></textarea></div>
        </Column>
      </div>
    </Section>
  </div>
</template>


<script>
import { defineComponent } from 'vue'

// コンポーネントを読み込む
import Variables from '../../../molecules/variables.vue';
import Choices from '../../../molecules/choices.vue';
import Box from '../../../molecules/box.vue';
import Section from '../../../molecules/section.vue';
import Column from '../../../molecules/column.vue';
import Group from '../../../molecules/group.vue';
import Flex from '../../../molecules/flex.vue';
import Block from '../../../molecules/block.vue';

import Description from '../../../atoms/description.vue';
import Label from '../../../atoms/label.vue';
import Input from '../../../atoms/input.vue';
import Select from '../../../atoms/select.vue';
import Textarea from '../../../atoms/textarea.vue';
import Button from '../../../atoms/button.vue';
import IconAdd from '../../../atoms/icon-add.vue';
import IconRemove from '../../../atoms/icon-remove.vue';

export default defineComponent({
  name: 'formApplicationEasier',

  components: {
    Variables,
    Choices,
    Box,
    Section,
    Column,
    Group,
    Flex,
    Block,

    Description,
    Label,
    Input,
    Select,
    Textarea,
    Button,
    IconAdd,
    IconRemove,
  },

  methods: {
  },

  mounted() {
  },

  setup(_, context) {
    return {}
  }

})
</script>
